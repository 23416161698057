.loader-container-deposits{
    transform: scale(.6);
    width: 100%;
}
.loader-container-deposits .loader-spinner-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.deposits-alerts-details .container-deposits{
    list-style: none;
    width: 95%;
}
.deposits-alerts-details .container-deposits li{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.deposits-alerts-details .container-deposits li div p:first-child{
    color: var(--text-gray);
    font-size: 1.4rem;
    margin-top: 2rem;
}
.deposits-alerts-details .container-deposits li div p:nth-child(2){
    font-size: 1.4rem;
}
.deposits-alerts-details .table-wrapper a{
    color: var(--orange-color);
    font-size: 1.2rem;
}
.deposits-alerts-details .badge-container-status p{
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 1.6rem;
}
.deposits-alerts-details .header-section{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.deposits-alerts-details .header-section div:first-child p{
    font-size: 1.4rem;
}
.deposits-alerts-details h3 > span {
    color: var(--orange-color);
}