.all-accounts-data {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: max-content max-content auto ;
    background-color: var(--white-color);
    row-gap: 2rem;
}

.all-accounts-data thead tr{
    background-color: var(--bg-dark-color);
    color: var(--white-color);
}

.all-accounts-data .table-container-v2 .pv2-bott .react-bootstrap-table-pagination{
    display: flex;
    justify-content: space-between;
}
.all-accounts-data .table-container-v2 .paginator-v2 input[type=number]{
    background-color: transparent;
}

.all-accounts-data .loader-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.all-accounts-data .react-bootstrap-table-pagination{
    background:transparent;
}
.input-drag-add-form-container{
    width: 90%;
}
.input-drag-add-form-container > div{
    padding: 10px;
    padding-bottom: 0;
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.input-drag-add-form-container input{
    font-size: 14px;
    background-color: rgb(241, 241, 241);
    margin-bottom: 4px;
    width: 100%;
    padding: 5px;
    border-radius: 2px;
}

.input-drag-add-form-container span{
    margin-left: 15px;
    color: var(--red-color);
    font-size: 14px;
}

.add-drag-field-button{
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23333' stroke-width='1' stroke-dasharray='10%2c 6' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border-radius: 15px;
    margin-top: 1rem;
    height: 44px;
}
.add-drag-field-button img{
    margin-right: 3px;
    filter: invert(1);
}
.drag-drop-container{
    margin: 8px;
}
.drag-drop-container .title-general-form{
    margin-top: 1rem;
}
.title-general-form{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.drag-drop-container .container-button{
    display: block;
}

.all-accounts-data .custom-button-container{
    display: flex;
    justify-content: flex-end;
}

.static-form-add-container{
    display: grid;
    grid-template-columns: repeat(2,calc(50% - 10px));
    gap: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
    
}

.input-drag-add-form-container .select-search__input{
    background-color: transparent;
}
.container-column{
    width: 45%;
}
.container-column > div{
    margin-bottom: 10px;
}
.container-submit-cancel-buttons{
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
}
.td-bank-entity{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.td-bank-entity p{
    font-size: 14px;
}
.td-bank-entity img{
    overflow: hidden;
}
.all-accounts-data .buttons-section label{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: .7rem;
    font-size: 1.4rem;
}
.w-200{
    width: 150px!important;
}

.input-file-preview-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}
.input-file-preview-container .logo-preview-img{
    width: 150px;
    object-fit: cover;
    margin-right: 15px;
}
.input-file-preview-container > div{
    width: 100%;
}
.banexcoin-accounts-page .header-section .profile-image{
    border-radius: 0;
}
.banexcoin-accounts-page .header-section p{
    font-size: 16px!important;
}
.color-status-Activo{
    background-color: var(--green-color)!important;
    padding: 5px 10px;
    border-radius: 10px;
    color:var(--white-color);
}
.color-status-Desactivado{
    background-color: var(--text-gray)!important;
    padding: 5px 10px;
    border-radius: 10px;
}
.link-documentation-add-bnx_account{
    font-size: 2rem;
    color: var(--orange-color);
    text-decoration: underline!important;
    margin-left: 1rem;
}
.link-documentation-add-bnx_account:hover{
    color: var(--orange-color);
}
.width-200{
    width: calc(200% + 20px)
}
.tooltip-info-bnx-accounts{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.tooltip-info-bnx-accounts > img:nth-child(2){
    display: none;
}
.tooltip-info-bnx-accounts:hover > img:nth-child(2){
    display: block;
    position: absolute;
    border-radius: 5px;
    bottom: 0px;
    left: 25px;
    text-align: center;
    z-index: 9999;
}
.container-loader-bnx-account{
    min-height: 300px;
    position: relative;
}
.container-loader-bnx-account .loader-spinner-container{
    text-align: center;
}
@media (max-width:425px) {
    .all-accounts-data {
        row-gap: 1rem;
    }
}

@media (max-width:425px) {
    .all-accounts-data {
        row-gap: 1rem;
    }
}

.select-search.is-disabled{
    opacity: 1!important;
}