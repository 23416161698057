
.add-deposit-limit-custom-modal-container{
    width: 500px;
    margin: 2rem auto;
}
.add-deposit-limit-custom-modal-container .add-modal-inputs{
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.add-deposit-limit-custom-modal-container .container-submit-cancel-buttons{
    gap: 1rem;
    margin-top: 1rem;
} 
.add-deposit-limit-custom-modal-container .add-limit-button{
    width: 124px;
    height: 36px;
}