.banex-form-filter-content input.form-control{
  height: 42px;
  font-size: 13px;
}

.banex-form-filter-content button.btn{
    height: 42px;
    width: 49%;
    margin-top: 20px;
    font-size: 1em;
    margin-right: 1%;
}

.banex-form-filter-content select.form-control {
  height: 42px !important;
  font-size: 1em;
}

.accountBalance{
  text-align: center;
  line-height: 10px;
  margin-top: 1em;
}

.accountBalance p{
  font-size: 1.2em;
}

.accountBalance p.info{
  font-size: 1em;
}

.accountBalance p.info-important{
  font-size: 1em;
  font-weight: bold;
}

.react-bootstrap-table .table tbody tr:nth-child(2n - 1):hover, .react-bootstrap-table .table tbody tr:nth-child(2n):hover {
  background: #f9efe6;
  cursor: pointer;
}

.react-confirm-alert .custom-ui {
  background: #f3f3f3;
  padding: 2em;
  border-radius: 1em;
  width: 600px;
}

.react-confirm-alert .custom-ui h1{
  font-size: 1.4em;
  text-align: center;
}

.react-confirm-alert .custom-ui .form-group.actions-buttons {
  text-align: right;
}

.react-confirm-alert .custom-ui .form-group.actions-buttons .btn{
  margin-left: 1em;
}

#form-errors alert {
  color: #d40404;
}

.block-banks {
  margin-bottom: 2em;
}

.banex-form-filter-content button.btn-primary {
  padding: 5px !important;
}

.banex-form-filter-content button.btn {
  height: 42px;
  width: 48%;
  margin-top: 20px;
  font-size: 1em;
  margin-right: 1%;
  margin-left: 1px !important;
}

.react-bootstrap-table button.btn.btn-danger {
  height: 10px;
  margin-left: 0;
  padding: 10px;
  line-height: 1px;
  font-weight: normal;
  border-radius: 1px;
}