.select-search {
    position: relative;
    font-family: 'Rubik', sans-serif;
    box-sizing: border-box;
    top: -13px;
}
.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}
.select-search__value {
    position: relative;
    z-index: 1;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 10px;
    width: 11px;
    height: 11px;
}
.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 30px 0 0;
    background: inherit;
    border: none;
    border-radius: 3px;
    outline: none;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}
.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}
.select-search__input:not([readonly]):focus {
    cursor: initial;
}
.select-search__select {
    background: rgb(168, 168, 199, 0.14);
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    z-index: 9999;
    position: fixed;
    top: 0;
}
.select-search__options {
    list-style: none;
}
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}
.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #e6e6ea;
    border: none;
    outline: none;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}
.select-search--multiple .select-search__option {
    height: 48px;
}
.select-search__option.is-selected {
    background: #A8A8C7;
}
.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: #A8A8C7;
}

.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}
.select-search.is-disabled {
    opacity: 0.5;
}
.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}
.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}
.select-search--multiple .select-search__input {
    cursor: initial;
}
.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}
.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}
.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 40px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}
.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}
.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}

@media screen and (max-width: 425px){
    .select-search:not(.select-search--multiple) .select-search__select {
        max-height: 120px;
    }
    .select-search__option,
    .select-search__not-found {
        font-size: 12px;
    }
}