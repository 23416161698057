.blacklist-content-resume a:first-child{
    font-size: 1.6rem;
    color: var(--text-gray);
}
.blacklist-content-resume .link-goto-files-container{
    gap: .5rem;
    justify-content: space-between;
    border-radius: 8px;
    padding: 1rem 1.2rem;
    width: 200px;
    margin: 0 0 0 auto;
}
.blacklist-content-resume > div:nth-child(2){
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding-top: 3rem;
}
.blacklist-content-resume .loader-spinner-container{
    text-align: center;
}
.blacklist-card-resume{
    background: var(--input-color);
    width: 300px;
    height: 200px;
    padding: 2rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
.blacklist-card-resume > div:first-child{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: .2;
    margin-bottom: 1rem;
}
.blacklist-card-resume > div:first-child img{
    cursor: pointer;
}
.blacklist-card-resume h2{
    font-size: 2.5rem;
    margin: 0;
}
.blacklist-card-resume p{
    font-size: 1.8rem;
    color: var(--text-gray);
    flex: .6;
}
.blacklist-card-buttons{
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    flex: .2;
}