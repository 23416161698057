#progress-bar {
    background-color: var(--white2-color);
    border-radius: 18px;
    padding: 1px;
}

#progress-bar>div {
    background-color: var(--green-color);
    height: 12px;
    border-radius: 18px;
}

.box-limits-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
}
.box-limits-details p{
    font-size: 12px;
}
.box-limits-details p span{
    background-color: var(--white2-color);
    padding: 2px 5px;
    border-radius: 10px;
    cursor: default;
}

.box-limits h2 {
    font-size: 16px !important;
    padding: 0 !important;
}

.box-limits-container {
    height: 100%;
    padding: 1.25rem;
}

.box-limits-container>div {
    width: 100%;
}

.box-limits-container .content-limits {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}