.container-header-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.container-header-page .title-section{
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    border-left: 3px solid #5D3DFF;
}
.container-header-page .title-section.sub-header{
    padding-left: 0;
    border-left: none;
    align-items: flex-end;
}
.container-header-page .btn-filters.header-option-active{
    background-color: #5D3DFF;
    border: 1px #5D3DFF solid;
}
.container-header-page .options-section .btn-filters:first-child img{
    transform: scale(1.3);
}
.container-header-page .btn-filters img{
    width: 20px;
}
.container-header-page .title-section .title-section-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 2px;
    font-size: 14px;
    color: #A8A8C7;
}
.container-header-page .title-section .title-section-left h2, p{
    margin: 0;
}
.container-header-page .title-section .title-section-left h2{
    font-size: 20px;
    color: #000;
    font-weight: 500;
}
.container-header-page .title-section .options-section{
    display: flex;
    column-gap: 18px;
}
.container-header-page .title-section .options-section .btn-filters{
    border-radius: 18px;
    border: 1px solid #A8A8C7;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    cursor: pointer;
    box-sizing: content-box;
}

@media screen and (max-width:425px){
    .container-header-page .btn-filters{
        height: 40px!important;
        width: 40px!important;
        border-radius: 15px!important;
    }
    .container-header-page .title-section .options-section{
        column-gap: 12px;
    }
    .container-header-page .title-section .title-section-left h2{
        font-size: 16px;
    }
}