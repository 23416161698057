.all-deposit-data {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: max-content max-content auto;
    background-color: #fff;
    row-gap: 2rem;
}

.all-deposit-data span.badge-circle-success.badge {
    background: var(--green-color);
    border-radius: 1rem;
    height: 0.5rem;
    width: 0.5rem;
    display: block;
    margin: auto;
    padding: 0.8rem;
}

.all-deposit-data span.badge-circle-warning.badge {
    background: var(--orange-color);
    border-radius: 1rem;
    height: 0.5rem;
    width: 0.5rem;
    display: block;
    margin: auto;
    padding: 0.8rem;
}

@media (max-width:425px) {
    .all-deposit-data {
        row-gap: 1rem;
    }
}