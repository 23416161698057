
.beta-content-container {
  width: 100%;
  height: 100%;
}
.container-error {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  flex-direction: column;
}
.container-error img{
  width: 400px;
  max-width: 80%;
}
.container-error > h2 {
  display: block;
  font-size: 2.6rem;
  font-weight: 600;
  margin-bottom: 3rem;
}
.container-error p{
  cursor: pointer;
  font-size: 2rem;
  text-decoration: underline!important;
  color: var(--orange-color);
}

.admin-v2 ::-webkit-scrollbar {
  width: 8px;
}


.admin-v2 ::-webkit-scrollbar {
  height: 8px;
  background-color: transparent;
}

.admin-v2 ::-webkit-scrollbar-thumb {
  background-color: var(--bg-scrollbar);
  border-radius: 20px;
}

.admin-v2 ::-webkit-scrollbar-track {
  border-radius: 20px;  
}

.containerNoKYC {
  display: grid;
  grid-template-rows: repeat(3, max-content);
  width: 100%;
  justify-items: center;
  align-content: center;
  row-gap: 10px;
}

.admin-v2 .standalone-layout__wrapper.v2container-box{
  background-color: var(--bg-dark-color);
}

.admin-v2 .two-factor-auth-form__container .two-factor-auth-form__header {

  background-color: var(--twoFa-color);
}
.admin-v2 .two-factor-auth-form__container .two-factor-auth-form__header .two-factor-auth-form__header-text{
  color: var(--orange-color);
}

.admin-v2 .two-factor-auth-form__container .two-factor-auth-form__information-text{
  color: var(--white-color)!important;
}

.admin-v2 .two-factor-auth-form__container .two-factor-auth-form__input{
  border-color: var(--black2-color) !important;
  background: var(--black2-color);
  color: var(--white-color);
}
.admin-v2 .standalone-form__container.two-factor-auth-form__container{
  background-color: var(--twoFa-color);
  box-shadow: 15px 13px 17px -11px var(--black-color-shadow);

}

.admin-v2 .table-container-v2 .minimizer.no-Selection > label{
  margin: 0;
}

.all-container-data-admin-v2 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: max-content auto;
  background-color: var(--white-color);
  row-gap: 2rem;
}

@media (max-width:425px) {
  .all-container-data-admin-v2 {
      row-gap: 1rem;
  }
}

.slot-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.option-ticket-tx-hash{
  width: 100%;
}
.option-ticket-tx-hash {
  width: 47%;
}

.option-ticket-tx-hash>p {
  font-size: 14px;
  color: var(--text-gray);
}

.option-ticket-tx-hash>p>b {
  font-weight: 400;
}

.option-ticket-tx-hash p:nth-child(2) {
  font-size: 16px;
  color: var(--black2-color);
}

.layout-details{
    width: 100%;
    display: grid;
    grid-template-rows: max-content auto;
    height: 100%;
    overflow: auto;
}
.bank-account-detail-main .option{
  width: 48%;
}
.bank-account-detail-main .option p{
  font-size: 1.6rem;
}
.bank-account-detail-main .option b{
  color: var(--text-gray);
  font-size: 1.4rem;
  font-weight: 400;
}

.container-tabs{
  background-color: var(--white-color);
  display: grid;
  grid-template-rows: max-content auto;
  height: 100%;
  overflow: auto;
}
.tab-content{
  height: 100%;
  overflow: auto;
}

.content-tab{
  display: grid;
  grid-template-rows: auto max-content;
  height: 100%;
}
.content-tab .container-loading{
  display: flex;
  text-align: center;
}

.buttons-container{
  padding: .7rem 1rem;
}
.buttons-container, .buttons-left{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-dark-color);
  flex-wrap: wrap;
  column-gap: 1rem;
}
.buttons-container, .buttons-right-content{
  display: flex;
  column-gap: 1rem;
}

.content-tab-container{
  overflow: auto;
}

.buttons-container button:disabled{
  cursor: default;
  opacity: .5;
}

.beta-content-container  .badge.status-Enviado_a_ACUANT,
.beta-content-container  .badge.status-Recibido_por_ACUANT{
  background-color: var(--gray-color);
}
.beta-content-container  .badge.status-Pendiente,
.beta-content-container  .badge.status-Nuevo_registro_pendiente,
.beta-content-container  .badge.status-Revisión_solicitada,
.beta-content-container  .badge.status-Revisión_en_proceso,
.beta-content-container  .badge.status-PENDING,
.beta-content-container  .badge.status-Anulado_por_Nota_de_crédito{
  background-color: var(--orange-color);
}
.beta-content-container  .badge.status-Rechazado,
.beta-content-container  .badge.status-Rechazada,
.beta-content-container  .badge.status-Información_incorrecta,
.beta-content-container  .badge.status-Rechazado_por_FA,
.beta-content-container  .badge.status-lock,
.beta-content-container  .badge.status-DONE_WITH_OBSERVATIONS{
  background-color: var(--red-color);
}
.beta-content-container  .badge.status-Aprobado,
.beta-content-container  .badge.status-Aceptada,
.beta-content-container  .badge.status-Resuelto,
.beta-content-container  .badge.status-DONE,
.beta-content-container  .badge.status-Enviado_a_FA,
.beta-content-container  .badge.status-unlock,
.beta-content-container  .badge.status-Reclamado{
  background-color: var(--green-color);
}
.beta-content-container  
.badge.status-Modificar_información,
.beta-content-container  .badge.status-Información_editada, 
.beta-content-container  .badge.status-En_revisión 
{
  background-color: var(--purple-color);
}
.ticket-status {
  color: var(--orange-color) !important;
}
.ticket-status-New {
  color:  var(--blue-color) !important;
}
.ticket-status-Rejected,.ticket-status-Failed,.ticket-status-UserCancelled {
  color: var(--red-color) !important;
}
.ticket-status-FullyProcessed {
  color: var(--green-color) !important;
}

.form-control.is-invalid{
  border-color:var(--red-color)!important;
}
.select-search__option {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
  min-height: 36px;
  height: auto !important;
  padding: .5rem 1.6rem!important;
}

.all-page-data {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: max-content auto;
  background-color: var(--white-color);
  row-gap: 2rem;
}

@media (max-width:425px) {
  .all-page-data {
      row-gap: 1rem;
  }
}

.custom-ui-alert-admin-v2{
  background-color: var(--white-color);
  padding: 2rem 4rem;
  border-radius: 5px;
}
.custom-ui-alert-admin-v2 button{
border: 1px solid var(--black2-color);
padding: 5px 20px;
margin: 5px 10px;
font-size: 1.3rem;
border-radius: 5px;
}
.custom-ui-alert-admin-v2 button:nth-child(3){
  background-color: var(--black2-color);
  color: var(--white-color);
  margin-top: 1.5rem;
}
.custom-ui-alert-admin-v2 p{
  font-size:1.6rem;
}
.custom-ui-alert-admin-v2 button:disabled{
  opacity: .5;
  cursor: default;
}

.comments-customers .dropdown img{
 transform: rotate(90deg);
 width: 20px;
}
.comments-customers .dropdown-menu{
 background: var(--white-color)!important;
 transform: translate(-70px, 51px);
}
.comments-customers .dropdown-menu > a{
    color: var(--black-color);
    font-size: 1.6rem;
}
.comments-customers .comment-info{
  display: flex;
  justify-content: space-between;
}
.comments-customers .comment-info p span{
  font-size: 1.4rem;
  margin-left: 1rem;
  font-weight: bold;
  color: var(--text-gray);
}
.comments-customers p small{
  color: var(--text-gray);
  margin-left: 1.5rem;
}
.comments-customers ul{
  list-style: none;
  padding-left: 0;
}
.comments-customers ul li{
  padding: .5rem;
}
.comments-container{
  padding: 2rem;
}
.comments-container h2{
  font-size: 1.8rem;
  font-weight: 500;
  padding: 1rem 0;
  width: 100%;
  text-align: left;
}
.comments-customers .comment-message{
  margin-left: 1rem;
  font-size: 1.4rem;
}

.comments textarea {
  border-radius: 15px;
  background-color: var(--input-color);
  border: none;
  padding: 1rem;
}
.comments button:focus{
  outline: none!important;
  -webkit-tap-highlight-color: rgba(0,0,0,0); 
  outline-color: transparent;
}
.comment-files-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.comment-files-list{
  margin-left: 2rem;
}
.comment-files-list ul{
  display: flex;
  flex-direction: column;
}
.comment-files-list summary{
  font-size: 1.2rem;
}
.comment-item-file{
  font-size: 1.4rem;
  color: var(--orange-color);
  font-weight: 500;
  transition: 300ms all;
  margin-left: 1rem;
  word-break: break-word;
}
.comment-item-file:hover{
  color: var(--orange-color);
  opacity: .5;
}
.comments ul li:nth-child(2n+1){
  background-color: rgb(168, 168, 199, 0.08);
  border-radius: 5px;
  margin: 1rem 0;
}
.flex{
  display: flex;
  align-items: center;
}
.no-editable-text{
  word-break: break-all;
}
.upload-files-container{
  justify-content: center;
  margin: 1rem auto;
  padding: .3rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='1' stroke-dasharray='10%2c 6' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");
  cursor: pointer;
}
.comment-input-file-dynamic{
  margin: 2rem 0;
}
.button-align-right button{
  margin: 1rem 0 0 auto;
}
.btn-corporate-reject{
  width: 106px;
  height: 36px;
}
.btn-corporate-accept{
  width: 80px;
  height: 36px;
}
.btn-corporate-incorrectInfo{
  width: 191px;
  height: 36px;
}
.btn-corporate-reniec{
  width: 140px;
  height: 36px;
}
.btn-corporate-idverif{
  width: 193px;
  height: 36px;
}
.item-coincidence{
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  padding-left: 2rem;
}
.item-coincidence .item-information span:first-child{
  color: var(--text-gray);
}
.show-mode-details p:nth-child(2) {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
  padding-top: 1.5rem;
}
.show-mode-details span{
  cursor: pointer;
}
.table-wrapper p {
  font-size: 1.4rem !important;
}
.details-option p:first-child{
  font-size: 1.3rem;
  color: var(--text-gray);
}
.details-option p:nth-child(2){
  font-size: 1.6rem;
  margin-bottom: .5rem;
}
.gap-1{
  gap: 1rem;
}
.color-status-activado,
.color-status-resolved{
  background-color:var(--green-color) !important;
  color: var(--white-color);
}
.color-status-desactivado,
.color-status-failed{
  background-color:var(--red-color) !important;
  color: var(--white-color);
}
.color-status-pending{
  background-color:var(--orange-color) !important;
  color: var(--white-color);
}
.color-status-reviewing{
  background-color:var(--purple-color) !important;
  color: var(--white-color);
}

.modal-general-v2 {
  max-width: 450px !important;
  margin: 2rem auto;
}

.modal-general-v2 .body-content-modal {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.modal-general-v2 .body-content-modal>div:last-child {
  display: flex;
  justify-content: right;
  column-gap: 1rem;
}
.short-column-name{
  width: 100%;
  max-width: 130px;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
  margin: auto;
}
.category-detail-section,
.category-detail-section >div:last-child{
  display: grid;
  grid-template-rows: max-content auto;
}
.category-detail-section >div:not(:last-child),
.category-detail-section >div:last-child .container-header-page{
  padding: 0 1.25rem 1.25rem;
}
.content-actions {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
}
.content-actions svg{
  font-size: 1.5rem;
  cursor: pointer;
}
.content-actions svg:first-child{
  color: var(--orange-color);
}
.content-actions svg:last-child{
  color: var(--red-color);
}
.dashboardPage {
  padding: 1.5rem;
  padding-top: 5.5rem;
}
.dashboardPage .dashboard-container-main{
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 2rem));
  gap: 2rem;
  overflow: auto;
}
.dashboardPage .dashboard-container-main .space-data-table{
  display: grid;
  gap: 1.5rem;
  grid-template-rows: max-content auto;
  min-height: 150px;
}
.dashboardPage .dashboard-container-main .table-container-v2 table thead th{
  color: var(--white-color);
  font-weight: 400;
}
.trash-icon{
  color: var(--red-color);
  cursor: pointer;
}
.loader-initial-v2{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loader-initial-v2 .lds-ring{
  margin-top: 0 !important;
}
.form-title{
  font-size: 1.4rem !important;
  margin-bottom: 1rem !important;
  font-weight: 400 !important;
  color: var(--text-gray);
}
.form-text{
  font-size: 1.6rem !important;
  font-weight: 400 !important;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
  line-height: initial;
  margin-bottom: 1rem !important;
}

@media screen and (max-width: 768px) {
  .dashboardPage .dashboard-container-main{
    grid-template-columns: auto; grid-template-rows: auto max-content;
  }
}