.resizable-columns-main {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}
.resizable-columns-main .resizable-first-child {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    padding: 15px;
    padding-top: 55px;
}
.resizable-columns-main .resizable-first-child.open-second-child {
    padding-right: 0;
}
.resizable-columns-main .resizable-second-child {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -14px 4px 33px 0px rgba(168, 168, 199, 0.19);
    background-color: #F5F5F9;
    border-left: 10px solid var(--bg-dark-color);
}
.resizable-columns-main .resizable-second-child::before{
    content: "";
    width: 6px;
    height: 60px;
    background: var(--bg-scrollbar);
    position: absolute;
    left: -8px;
    border-radius:20px;
}
.right-resizable + div > div{
    left: -10px!important;
}