.header-section-global-config {
    padding: 1.5rem;
}

.header-section-global-config h2 {
    font-size: 2rem;
    font-weight: 400;
    text-align: left;
}

.header-section-global-config .info {
    display: grid;
    grid-template-columns: auto max-content;
}

.header-section-global-config .info .option {
    display: flex;
    align-items: center;
    justify-items: flex-start;
    font-size: 1.6rem;
    margin-top: .3rem;
}

.header-section-global-config .info .option p:first-child {
    color: var(--text-gray);
    margin-right: .5rem;
}

.body-section-global-config {
    display: grid;
    grid-template-rows: max-content max-content auto;
}

.body-section-global-config .pv2-bott {
    padding: 1rem;
}

.body-section-global-config .pv2-bott>div {
    background-color: var(--white2-color);
}

.global-config-data {
    display: grid;
    grid-template-rows: max-content auto;
    overflow: auto;
    padding-right: 1.5rem;
}

.global-config-data.withRightSection {
    margin-right: 1.5rem;
}

.global-config-data .content-config-data {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
}
.global-config-data .content-config-data > section:first-child {
    width: 56vw;
    min-width: 600px;
}
.global-config-data .content-config-data > section:last-child {
    min-width: 600px;
}

.global-config-data .section-list-data {
    height: calc(100vh - 138px) !important;
}

.global-config-data .section-list-data {
    display: grid;
    grid-template-rows: max-content auto;
    height: 100%;
    row-gap: 1.5rem;
}
.authorized-users-table-container > div:first-child {
    min-height: 300px;
}
.authorized-level-details-item{
    padding: .2rem 1.5rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    font-size: 1.6rem;
}
.authorized-level-details-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.authorized-level-details-item span{
    font-weight: 300;
    text-decoration: line-through;
    opacity: .5;
    cursor: default;
}
.authorized-level-details-item span.enabled{
    font-weight: bold;
    text-decoration: none;
    opacity: 1;
}
.authorized-level-details-item span.editable{
    font-weight: 400;
    text-decoration: none;
    opacity: 1;
}
