.short-column-name.acuant-column{
    max-width: 200px!important;
}
.min-width-filter-input-acuant-accountid{
    min-width: 230px!important;
}
.min-width-filter-input-acuant-excludeid{
    min-width: 220px!important;
}
.max-width-filter-input-acuant-file{
    max-width: 220px!important;
}