.bank-account-detail-main {
    height: 100%;
    display: grid;
    grid-template-rows: auto max-content;
    overflow: auto;
}
.bank-account-detail-main  .bank-account-body-container{
    overflow-y: auto;
}
.bank-account-detail-main .body-section{
    padding: 1.25rem; 
}
.bank-account-detail-main .body-section h2{
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    margin-top: 2rem;
}
.bank-account-detail-main .header-section {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 1.25rem;
}

.bank-account-detail-main .header-section h2 {
    font-size: 18px;
    font-weight: 400;
}

.bank-account-detail-main .header-section h2:first-child {
    font-weight: 600;
}

.bank-account-detail-main .header-section p {
    color: var(--text-gray);
    font-size: 14px;
    margin-top: 3px;
}
.bank-account-detail-main  .bank-account-footer-container {
    background-color: var(--bg-dark-color);
    padding: 0.7rem 0;
    height: 60px;
}