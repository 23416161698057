.user-details-main {
  height: 100%;
  display: grid;
  grid-template-rows: auto max-content;
  overflow: auto;
}
.container-tabs .option {
  width: 48%;
  margin-bottom: 1.5rem;
}
.container-tabs .option p {
  font-size: 1.6rem;
}
.container-tabs .option b {
  color: var(--text-gray);
  font-size: 1.4rem;
  font-weight: 400;
}
.user-permits-body .content-tab-container {
  padding: 1.25rem;
}
.user-permits-body .content-tab-container .check-detail{
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  padding-top: .5rem;
}
.user-permits-body .content-tab-container .check-detail >div{
  display: grid;
  grid-template-columns: 15px auto;
  column-gap: 1rem;
  align-items: center;
}
.header-section > h2 {
  font-size: 1.8rem;
}
.subtitle-first-tab{
    font-size: 2rem;
}
.free-sms-button{
    width: 12rem;
    height: 3.6rem;
}
.user-permits-body.permits-tab h2{
  margin-top: 2rem;
}
.user-permits-body.permits-tab .content-level-title.with-img h2{
  margin: 0 1rem 0 0;
}
.update-permits-button{
  width: 12rem;
  height: 3.6rem;
}