.cryptowallet-deposit__title {
  color: var(--font__color-primary);
  padding-bottom: 1.5rem;
}

.cryptowallet-deposit__qr-code {
  margin-right: 3rem;
}

.cryptowallet-deposit__description {
  padding-bottom: 1.5rem;
  line-height: 1.5rem;
}

.cryptowallet-deposit__address {
  margin: 0.75rem 0 2rem 0;
  padding: 0.75rem;
  width: 100%;
  min-height: 3rem;
  border: 1px solid var(--border__color-secondary);
  color: var(--link__color);
  font-size: 1.2rem;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  color: var(--font__color-secondary);
  background: var(--tertiary__bg-color);
}

.cryptowallet-deposit__address:hover {
  background: var(--secondary__bg-color);
}

.cryptowallet-deposit__copy-icon {
  position: absolute;
  right: 1.2rem;
  margin-top: -0.1rem;
  opacity: 0.8;
}

.cryptowallet-deposit__frame {
  width: 100%;
  margin-top: 0.5rem;
  padding: 2.5rem;
  background: var(--primary__bg-color);
  display: flex;
}

.cryptowallet-deposit__wrapper {
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color);
}
