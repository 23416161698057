.profiles-details{
    display: grid;
    grid-template-rows: max-content auto;
    overflow: hidden;
}
.profiles-details > div:first-child{
    height: calc(95vh - 60px);
}
.profiles-details > div:nth-child(2){
    max-height: 60px;
    height: 85vh;
}
.profiles-details .profiles-permits-row{
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    padding: .3rem;
}
.profiles-permits-row-h3{
    margin: 2rem 0;
}
.profiles-details > div:first-child{
    height: calc(95vh - 60px);
}
.profiles-details > div:nth-child(2){
    max-height: 60px;
}
.profiles-details .profiles-permits-row *:first-child{
    flex: .5;
    text-align: left;
}
.profiles-details .profiles-permits-row *:nth-child(2){
    flex: .3;
}
.profiles-details .profiles-permits-row *:nth-child(3){
    flex: .2;
}
.profiles-details .profiles-permits-row.title{
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.4rem;
}
.profiles-permits-list-modules{
    margin-bottom: 1rem;
}
.profiles-permits-list-modules > div:nth-child(2n+1){
    background-color: var(--white-color);
}
.profiles-details-list{
    max-height: calc(100vh - 160px);
    overflow: auto;
    padding: 1.75rem;
}
.empty-text{
    font-size: 2rem;
    margin: 2rem;
    font-weight: bold;
}
.ticket-detail-main .header-section p.link-profile-redirect{
    font-size: 1.6rem;
    color: var(--orange-color)!important;
    text-decoration: underline!important;
    margin-left: 1rem;
    cursor: pointer;
}