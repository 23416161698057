.v-beta.sidebar-v2 {
    width: 250px;
    height: 100%;
    padding: 1.5rem 0;
    background-color: #1A1A2F;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    z-index: 980;
    transition: left 0.2s ease-in-out;
    overflow: hidden;
    width: 250px;
}
.v-beta.sidebar-v2.closed {
    left: -250px;
}
.v-beta.sidebar-v2.opened {
    left: 0;
}

.beta-v2{
    background: rgba(255, 255, 255, 0.13);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}
.beta-v2.close-menu {
    position: fixed;
    left: 9px;
    top: 50%;
    bottom: 50%;
    height: 80px;
    width: 25px;
    border-radius: 0 1rem 1rem 0;
    z-index: 999;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms all;
}
.beta-v2.close-menu img{
    width: 24px;
    height: 24px;
    z-index: 1;
    transition: all 0.3s;
}
.beta-v2.close-menu.active{
    left: 0;
}
.beta-v2.close-menu.active img {
    transform: rotate(180deg);
}
.beta-v2.close-menu::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    background-color: rgb(75 75 75 / 36%);
    transition: all 0.3s;

}

.v-beta.sidebar-v2 .logo-menu {
    display: block;
    filter: invert(100%);
    width: 30px;
    position: absolute;
    left: 15px;
    cursor: pointer;
}
.v-beta.sidebar-v2 .logo-v2-container {
    padding: 3px 3rem 2rem 5rem;
}
.v-beta.sidebar-v2 .logo-v2-container > img {
    width: 165px;
    min-width: auto;
    margin: 0 auto;
    display: block;
}
.v-beta.sidebar-v2 .logo-v2-container > h3 {
    color: #A8A8C7;
    text-align: right;
    font-size: 1.3rem;
    font-weight: 500;
}
.v-beta.sidebar-v2 > .accordion {
    overflow-y: auto;
    height: 100%;
    margin-right: 10px;
}

.v-beta.sidebar-v2 .beta-item-v2.card {
    background-color: transparent;
    padding-left: 1rem;
}
.v-beta.sidebar-v2 .beta-item-v2.card .card-header {
    border-radius: 1rem;
    width: 96%;
}
.v-beta.sidebar-v2 .beta-item-v2.card .card-header:hover, .v-beta.sidebar-v2 .beta-item-v2.card .card-header.active {
    background-color: #5D3DFF;
}
.v-beta.sidebar-v2 .beta-item-v2.card .card-header > .btn-link {
    color: #FFFFFF;
    font-size: 1.3rem;
    display: block;
    width: 100%;
    text-align: left;
    display: grid;
    grid-template-columns: 24px auto 24px;
    column-gap: 10px;
    align-items: center;
}
.v-beta.sidebar-v2 .beta-item-v2.card .card-header > .btn-link:hover, .v-beta.sidebar-v2 .beta-item-v2.card .card-header > .btn-link:focus  {
    text-decoration: none;
    box-shadow: none;
}
.v-beta.sidebar-v2 .beta-item-v2.card .collapse > .card-body, .v-beta.sidebar-v2 .beta-item-v2.card .collapsing > .card-body {
    color: white;
    padding: 0.25rem 1.25rem 0.25rem 3.25rem;
}
.v-beta.sidebar-v2 .beta-item-v2.card .collapse > .card-body > .nav-link, .v-beta.sidebar-v2 .beta-item-v2.card .collapsing > .card-body > .nav-link  {
    color: white;
    font-size: 1.2rem;
    font-weight: 300;
    padding: 0.7rem 1rem;
}
.v-beta.sidebar-v2 .beta-item-v2.card .collapse > .card-body > .nav-link:hover,
.v-beta.sidebar-v2 .beta-item-v2.card .collapsing > .card-body > .nav-link:hover,
.v-beta.sidebar-v2 .beta-item-v2.card .collapse > .card-body > .nav-link.active,
.v-beta.sidebar-v2 .beta-item-v2.card .collapsing > .card-body > .nav-link.active {
    text-decoration: none;
    color: #5D3DFF;
}
.v-beta.sidebar-v2 .user-info {
    padding: 2rem 1rem 1rem 3rem;
    color: white;
    font-weight: 500;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
}

.v-beta.sidebar-v2 .user-info > div {
    cursor: pointer;
}
.v-beta.sidebar-v2 .user-info > * {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.4rem;
    width: 50%;
}
.v-beta.sidebar-v2 .user-info > * .avatar {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 4rem;
    background: #3bbd7c;
    margin-right: 0.5rem;
}
.v-beta.sidebar-v2 .user-info > *.switch-version {
    width: 50%;
    text-align: right;
    -ms-flex-pack: end;
    justify-content: flex-end;
    zoom: 2;
}
.v-beta.sidebar-v2 .user-info > * .cont-opt-menu {
    position: relative;
    height: 35px;
    width: 35px;
    margin-right: 10px;
}
.v-beta.sidebar-v2 .user-info > * .cont-opt-menu .chill-cont{
    position: absolute;
    bottom: -100vh;
    background: #27273D;
    border-radius: 24px;
    padding: 16px;
    width: 180px;
    transform: scaleY(0);
    transform-origin: center bottom;
    transition: transform .3s;
}
.v-beta.sidebar-v2 .user-info > * .cont-opt-menu .chill-cont.active{
    bottom: 51px;
    transform: scale(1);
}
.v-beta.sidebar-v2 .user-info > * .cont-opt-menu .chill-cont .fchill{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
.v-beta.sidebar-v2 .user-info > * .cont-opt-menu .chill-cont .fchill .item-menu{
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 1.3rem;
    font-weight: 400;
    cursor: pointer;
}
.v-beta.sidebar-v2 .content-container{
    margin-left: 0!important;
    width: 100%!important;
}


.container-admin-v2{
    width: 100%;
    height: 100%;
}
.container-admin-v2 .icon-menu-hamburger {
    position: absolute;
    width: 30px;
    top: 15px;
    left: 15px;
    cursor: pointer;
    z-index: 1;
}

@media screen and (max-width: 425px){
    .v-beta.sidebar-v2 .logo-v2-container img {
        width: 165px;
        min-width: auto;
    }
}