#minimun-section {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
}

#minimun-section .content-level-grid {
    display: flex;
    flex-wrap: wrap;
}

#minimun-section .content-level-grid>div {
    width: 200px !important;
}
.levels-batches-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
}
.levels-batches-container > span{
    border-radius: 2rem;
    padding: 0.2rem 1.2rem;
    background-color: var(--white-color);
    box-shadow: var(--webkit-shadow);
}