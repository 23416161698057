.user-deposits-main {
    height: 100%;
    display: grid;
    grid-template-rows: auto max-content;
    overflow: auto;
}

.user-deposits-main .details-body-us {
    height: 100%;
    overflow: auto;
}

.user-deposits-main .details-body-us .container-tbl-dep {
    overflow-x: auto;
    margin: 1.25rem;
    position: relative;
}

.user-deposits-main .details-body-us a {
    color: var(--orange-color);
    font-size: 16px;
    display: block;
    text-decoration: underline !important;
}

.user-deposits-main .details-body-us img {
    cursor: pointer;
}

.user-deposits-main .details-body-us table .dep-success {
    color: var(--green-color);
    font-weight: 500;
}

.user-deposits-main .details-body-us table .dep-error {
    color: var(--red-color);
    font-weight: 500;
}

.user-deposits-main .details-body-us th,
.user-deposits-main .details-body-us td,
.user-deposits-main .details-body-us td>p {
    font-size: 14px;
}

.user-deposits-main .details-body-us th {
    width: 170px !important;
}

.user-deposits-main .details-body-us .rd-btn {
    width: 30px !important;
    min-width: 30px !important;
}

.user-deposits-main .deposit-buttons-container {
    background-color: var(--bg-dark-color);
    padding: 0.7rem 0;
    height: 60px;
}

.user-deposits-main .message-alert {
    padding: 1.25rem;
    padding-bottom: 0;
    position: relative;
}

.user-deposits-main .message-alert >div:first-child {
    margin: 0;
}

.user-deposits-main .message-alert h3 {
    font-size: 14px;
}

.user-deposits-main .message-alert span {
    width: 20px;
    height: 20px;
    background: var(--black-color);
    border-radius: 50%;
    color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 1px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
}