.deposit-detail-main {
    height: 100%;
    display: grid;
    grid-template-rows: max-content auto;
    overflow: auto;
}

.deposit-detail-main .header-section {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 1.25rem;
}

.deposit-detail-main .header-section h2 {
    font-size: 18px;
    font-weight: 400;
}

.deposit-detail-main .header-section h2:first-child {
    font-weight: 600;
}

.deposit-detail-main .header-section p {
    color: var(--text-gray);
    font-size: 14px;
    margin-top: 3px;
}

.deposit-detail-main .header-section .status-success-dep {
    color: var(--green-color);
}

.deposit-detail-main .header-section .status-warning-dep {
    color: var(--orange-color);
}

.deposit-detail-main .deposit-body-container {
    background-color: var(--white-color);
    display: grid;
    grid-template-rows: max-content auto;
    overflow: auto;
}

.deposit-detail-main .deposit-body-container .tab-content {
    height: 100%;
    overflow: auto;
}

.deposit-detail-main .deposit-body-container .cont-tab {
    height: 100%;
}
.deposit-detail-main .box-limits-container h2 {
    font-size:22px;
    font-weight: 500;
    padding: 10px 0;
    width: 100%;
    text-align: left;
}
.deposit-detail-main .option {
    width: 48%;
    margin-bottom: 1.5rem;
  }
  .deposit-detail-main .option p {
    font-size: 1.6rem;
  }
  .deposit-detail-main .option b {
    color: var(--text-gray);
    font-size: 1.4rem;
    font-weight: 400;
  }