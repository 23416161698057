.comments-dep-main {
    height: 100%;
}

.comments-dep-main h3 {
    padding: 2rem 1.25rem 0.75rem;
    font-size: 22px;
}

.comments-dep-main ul {
    padding: 0 1.25rem 0.75rem 3rem;
}

.comments-dep-main ul li {
    font-size: 14px;
}

.comments-dep-main form {
    padding: 0.75rem 1.25rem;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.comments-dep-main form textarea {
    background-color: var(--input-color);
    border-radius: 8px;
    padding: 1rem;
    font-size: 14px;
}

.comments-dep-main form .container-btn {
    display: flex;
    justify-content: right;
}

.comments-dep-main form .container-btn button {
    color: white;
    font-size: 14px;
    border: 1px solid var(--purple-color);
    background-color: var(--purple-color);
    padding: 1rem 1.5rem;
    width: max-content;
    border-radius: 8px;
}