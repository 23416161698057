.custom-modal-confirm-container{
    max-width: 500px!important;
    margin: 0 auto;
    margin-top: 2rem;
}

.custom-modal-confirm-container p{
    font-size: 16px;
}

.buttons-confirm-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-top: 2rem;
}
.buttons-confirm-container button{
    width: 42px;
    height: 38px;
}