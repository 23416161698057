.App.container.open .content-app-marketing {
  margin-left: 239px !important;
  width: calc(100% - 239px) !important;
  transition: width 0.5s;
}

.container-table .table thead th {
  background-color: var(--bg-dark-color);
  color: var(--white-color);
  font-weight: 400;
  white-space: nowrap;
  height: 5rem;
  width: 120px;
  min-width: 120px;
}

td {
  border: none;
  font-size: 13px;
}

.table thead th {
  text-align: inherit;
}

.table thead {
  background: var(--app-m-bgc);
}

.is-relative {
  position: relative !important;
}

.p-1 {
  font-size: 14px;
}

.table .badge {
  font-size: 11px;
}

.container-header {
  margin: 41px 7px 41px 7px;
  font-size: 20px;
  font-weight: 600;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-tittle {
  font-size: 20px;
}

svg.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: var(--svg-icon-app);
  cursor: pointer;
}

svg.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root:hover {
  color: var(--svg-icon-app-hover);
}

.tags .tag:not(:last-child) {
  margin-right: 0.5rem;
}

.tags:last-child {
  margin-bottom: -0.5rem;
}

.tag.private, .tag.scheduled {
  color: var(--tag-private-cl);
  background: var(--tag-private-bg);
  border: 1px solid var(--tag-private-bd);
  box-shadow: 1px 1px 0 var(--tag-private-bd);
}

.tag.public, .tag.scheduled {
  color: var(--tag-public-cl);
  background: var(--tag-public-bg);
  border: 1px solid var(--tag-public-bd);
  box-shadow: 1px 1px 0 var(--tag-public-bd);
}

.tags .tag {
  margin-bottom: 0.5rem;
}

.tag:not(body) {
  font-size: .85em;
  border: 1px solid var(--tag-body-bd);
  box-shadow: 1px 1px 0 var(--tag-body-bd);
  color: var(--tag-body-cl);
}

.tag:not(body) {
  align-items: center;
  background-color: var(--app-m-bgc);
  border-radius: 4px;
  color: var(--tag-no-body);
  display: inline-flex;
  font-size: 1.10rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
}

.tags .tags-levels {
  height: 100%;
  min-height: 100% !important;
  display: grid;
  grid-template-columns: repeat(3, max-content);
}

.table tbody td, .main table thead th {
  padding: 15px 10px;
  border-color: var(--table-bd-app);
  overflow: inherit;
  width: auto;
}

.table td:not([align]), table th:not([align]) {
  text-align: inherit;
}

.table td, .table th {
  border: 1px solid var(--tag-body-bd);
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}

.modal-header {
  padding: 20px;
}

.modal-title {
  font-size: 18px;
}

.label-check {
  margin: 15px 15px 25px 2px;
}

.check-levels {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6px !important;
}

.level-nat {
  font-weight: 600;
  font-size: 14px;
}

.form-check-inline {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.level-corp {
  margin-top: 12px;
  font-weight: 600;
  font-size: 14px;
}

.submit-btn {
  font-weight: 600;
  margin-left: 0rem;
  padding: 12px;
  border-radius: 0.5rem;
  background: var(--svg-icon-app-hover) !important;
  margin-right: 10px;
  width: 70px;
  height: 40px;
}

.submit-btn:hover {
  background: var(--svg-icon-app-hover) !important;
}

.cancel-btn {
  font-weight: 600;
  margin-left: 0rem;
  padding: 12px;
  border-radius: 0.5rem;
  width: 70px;
  height: 40px;
}

.levels {
  font-weight: 400 !important;
}

.form-check-input:focus {
  border-color: var(--inpt-check-focus);
  outline: 0;
  box-shadow: var(--inpt-check-focus-sd);
}

.form-check-input:checked {
  background-color: var(--inpt-check-checked);
  border-color: var(--inpt-check-checked);
  box-shadow: var(--inpt-check-checked-sd);
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}

.tag-error {
  margin-left: 10px;
  font-weight: 500;
  color: var(--red-color)
}

.app-pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 18px;
}

.page-item {
  font-size: 1em;
  justify-content: center;
  margin: 0.25rem;
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-align: center;
}

.page-link {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top;

}

.page-item .page-link {
  height: 35px;
}

.page-item.disabled .page-link {
  background-color: var(--tag-body-bd);
  border-color: var(--tag-body-bd);
  box-shadow: none;
  color: var(--tag-body-cl);
  opacity: .5;
}

.page-item.active .page-link {
  z-index: 3;
  background-color: var(--svg-icon-app-hover);
  border-color: var(--svg-icon-app-hover);
  color: var(--white-color);
  pointer-events: none;
  cursor: not-allowed;

}

.pagination-lg .page-link {
  border-color: var(--tag-body-bd);
  color: var(--pagin-pg-link);
  min-width: 2.5em;
}

.pagination-lg .page-link:hover {
  border-color: var(--pagin-pg-link-hover);
  color: var(--pagin-pg-link);
  background-color: transparent;
}

.css-12jo7m5.select__multi-value__label {
  padding-top: 10px;
  height: 20px;
  color: var(--tag-public-cl);
   background: var(--tag-public-bg);
}

 .select__multi-value__remove {
  color: var(--tag-public-cl);
  background: var(--tag-public-bg);
} 

.select__multi-value{
  border: 1px solid var(--tag-public-bd);
  box-shadow: 1px 1px 0 var(--tag-public-bd);
}

.select__multi-value__remove {
  background-color: var(--gray2-color) !important;
  content: "" !important;
  left: 9px !important;
  position: relative !important;
  top: 10px !important;
  transform: translateX(-50%) translateY(-50%) rotate(91deg) !important;
  transform-origin: center center !important;
  border-radius: 50% !important;
  color: var(--white-color) !important;
  width: 20px !important;
  height: 20px !important;
}

.css-1rhbuit-multiValue{
  background-color: var(--tag-public-b);
}
.css-yk16xz-control:focus {
  border-color: var(--form-contr-cl)!important;
  box-shadow: 0 0 0 1px var(--form-contr-cl)!important;
}

.css-yk16xz-control:active {
  border-color: var(--form-contr-cl)!important;
  box-shadow: 0 0 0 1pxvar(--form-contr-cl)!important;
}
.css-1pahdxg-control{
  border-color: var(--form-contr-cl)!important;
  box-shadow: 0 0 0 1px var(--form-contr-cl)!important;
}
.css-1pahdxg-control:focus{
  border-color: var(--form-contr-cl)!important;
  box-shadow: 0 0 0 1px var(--form-contr-cl)!important;
}
.overwrite_switch{
  margin-bottom: 1rem!important;
}

input[type="checkbox"].switch_1{
	font-size: 30px;
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	width: 1.6em;
	height: .8em;
	background: var(--inpt-switch-off);
	border-radius: 3em;
	position: relative;
	cursor: pointer;
	outline: none;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
  }
  
  input[type="checkbox"].switch_1:checked{
	background: var(--svg-icon-app-hover);
  }
  
  input[type="checkbox"].switch_1:after{
	position: absolute;
	content: "";
	width: 1em;
	height: 1em;
	border-radius: 50%;
	background: var(--white-color);
	-webkit-box-shadow: var(--webkit-shadow);
	        box-shadow: var(--webkit-shadow);
	-webkit-transform: scale(.7);
	        transform: scale(.7);      
  top: -3px;
	left: -3px;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
  }
  
  input[type="checkbox"].switch_1:checked:after{
	left: calc(100% - 1em);
  }
	
.text-list{
  text-transform: uppercase;
}

.form-group p {
  margin-bottom: 1.5rem;
  font-weight: 600;
  font-size: 14px;
}
.invalid.label-check.levels {
  color: var(--red-color);
}

.no-permit{  
 cursor:not-allowed;
}

.no-permit input{  
  pointer-events: none;
 }
 
 .modal-title span{
  display: flex;
 }

 .modal-title span p {
  padding-left: .5rem;
  padding-right: .5rem;
  color: var(--modal-title);
  font-weight: 600;
}

.check-group {
  display: flex;
}

.check-none {
  display: none;
}
.noshow-lottie{
  display: none;
}


.show-lottie {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    opacity: .5;
    background: var(--black-color);
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.lottie-animated {
  top: 35%;
  left: 0%;
  position: relative;
}

.lottie-title {
  display: block;
  z-index: 9999;
  color: var(--lottie-title);
  text-align: center;
}