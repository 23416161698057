.drag-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.drag-item img{
    margin-right: 15px;
}

[data-rbd-drag-handle-context-id="0"]{
    height: 60px;
    display: flex;
    align-items: center;
}