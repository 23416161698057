.input-element{
    color: #A8A8C7;
    background: var(--input-color);
    height: 44px;
    display: grid;
    grid-auto-flow: column;
    column-gap: 8px;
    border-radius: 15px;
    margin: 0;
    min-width: 150px;
    width: 100%;
    grid-auto-columns: auto;
}
.input-element.first{
    grid-auto-columns: max-content auto;
}
.input-element.second{
    grid-auto-columns: auto max-content;
}
.input-element .input-element-icon{
    margin-top: 3px;
}
.input-element label {
    margin: 0;
    width: 100%;
    overflow: hidden;
    padding: .8rem;
}
.input-element label input{
    font-size: 14px;
    padding: 0;
    height: 19px;
    position: relative;
    top: 6px;
    background: inherit;
    width: 100%;
}
.input-element .label-text p{
    position: relative;
    font-size: 14px;
    transition: all 0.25s;
    top: -15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block !important;
}
.input-element .label-text  .input-not-date:focus {
    top: 15px;
}
.input-element .label-text .input-not-date:valid{
    top: 15px;
    right: -10px;
}
.input-element .label-text .input-not-date:focus + p{
    top: -23px; 
    font-size: 1.3rem!important;
}
.input-element .label-text .input-not-date:valid + p {
    top: -23px;
    font-size: 1.3rem!important;
}
.input-element .label-date {
    display: flex;
    flex-direction: column-reverse;
}
.input-element .label-date input {
    position: relative;
    top: 4px;
}
.input-element .label-date p {
    font-size: 13px;
    position: relative;
    top: 5px;
}

.input-element .input-select p{
    font-size: 1.3rem!important;
    position: relative;
    top: -4px;
    width: 90%;
}
.input-element .input-async-select >div >div{
    width: 100%; 
}
.input-element .content-icon{
    margin-top: 3px;
}
.input-element .content-icon img{
    cursor: pointer;
}
.input-element .input-select{
    padding: .8rem;
    min-width: 235px;
}
.input-element .input-select p{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block !important;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px #F3F3F7 inset;
}

.input-element input[type="date"]::-webkit-calendar-picker-indicator{
    cursor: pointer;
    background-image: url('../../../images/new_icons/calendar.svg');
}
.input-element input[type="date"]:valid{
    color: black;
}

.label-text.input-date p{
    font-size: 1.3rem !important;
    top: -23px;
}
.label-text.input-date input{
    top: 14px;
}
.label-text.input-date input{
    color: #A8A8C7;
}
.error-input-style{
    border: 1px solid var(--red-color);
}
.input-file-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    overflow: hidden;
}
.input-file-label p {
    font-size: 1.4rem;
    margin-top: 5px;
    width: 90%;
    overflow: hidden;
}
@media (max-width:425px) {
    .input-element, .input-select{
        width: 100%;
    }
}
.select-search:not(.is-disabled) .select-search__input{
    color: black;
}
.input-element-textarea{
    height: 100px;
}
.input-element textarea{
    margin-top: -0.5rem;
    width: 100%;
    resize: none;
    height: 100%;
    background-color: transparent;
    font-size: 1.4rem;
}
.input-element-icon{
    padding: .5rem;
}