.container-page-data {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: max-content auto;
    row-gap: 2rem;
}

@media (max-width:425px) {
    .container-page-data {
        row-gap: 1rem;
    }
}