.login-v2{
    background-color: #1A1A2F;
    background-image: url('../../../images/bglogin.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}
.login-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    height: 100vh;
    max-width: 1200px;
    position: relative;
}
.login-container .diamond,.login-container .diamond2{
    width: 500px;
    position: absolute;
    z-index: 0;
}
.login-container .diamond2{
    right: -2%;
    bottom: 50%;
}
.login-container .diamond{
    top: 48%;
    right: 21%;
}
.form-container{
    background-color: #27273D;
    padding: 50px 25px;
    border-radius: 32px;
    width: 500px;
    z-index: 999;
    
}
.form-container > form{
    z-index: 999;
    width: 100%;

}
.form-container > form > h3{
    color: #A8A8C7;
    font-size: 24px;
}
.form-container-options{
    margin: 30px 0 0 0;
    text-align: center;
}
.form-container-options > a{
    color: #FD8E0A;
    font-size: 12px;
}
.form-container-options span:nth-child(2){
    color: #A8A8C7;
    margin: 10px;
}
.form-container .input-element{
    margin: 50px 0 ;
}
.form-container .input-element input{
    color: white;
}

.form-container button{
    width: 100%;
    margin: 0;
    height: 50px;
}

.circle1,.circle2,.circle3{
    background: linear-gradient(99deg,#FF8E0A,#FF703A);
    border-radius: 100%;
    position: absolute;
    box-shadow: 2px 2px 52px 0px #FF703A;
    -webkit-box-shadow: 2px 2px 52px 0px #FF703A;
    -moz-box-shadow: 2px 2px 52px 0px #FF703A;
}

.circle1{
    width: 9px;
    height: 9px;
    left: 50%;
    top: 42%;
}
.circle2{
    width: 25px;
    height: 25px;
    top: 200px;
    left: 200px;
}
.circle3{
    width: 77px;
    height: 77px;
    bottom: 50px;
    left: 50px;
}

.tag-transaparent{
    color: transparent;
}

.logo-admin-login{
    position: absolute;
    top: 60px;
    left: 60px;
    width: 200px;
}


.login-container input:-webkit-autofill,
.login-container input:-webkit-autofill:hover,
.login-container input:-webkit-autofill:focus{
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #393950 inset;
}
#message-error-login{
    color: var(--red-color);
    font-size: 1.4rem;
    text-align: center;
    margin: -20px 0 20px 0;
}