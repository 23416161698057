.btn-format{
height: 3rem;
border-radius: 1rem;
font-size: 11px;
font-weight: 600;
}
.buttons-sendgrid {
float: right;
}

.list-sengrid.table{
    margin-top: 2rem;

}
.content-sendgrid{
    background: white;
    margin: 1rem 7rem 0 4rem;
    border-radius: 2rem;
}

.row-sendgrid.container {
    margin: 2rem 0 0 0rem;
}

.btn-right {
    float: right;
    margin-bottom: 1rem;
    margin-right: 3rem;
}

.btn-table {
    align-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}