.right-resizable{
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-rows: max-content auto;
    overflow: auto;
    background-color: #f5f5f9;
}

.right-resizable .header-options{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 15px 0 15px;
    margin: 0;
    height: 50px;
}
.right-resizable .header-options > img {
    width: 40px;
    height: 40px;
    margin-left: 0.5rem;
}
.right-resizable .header-options > img[alt*="more"] {
    width: 40px;
    height: 40px;
    padding: 10px;
}
.right-resizable .header-options > img:hover {
    background-color: #e0e1e3;
    border-radius: 15px;
    cursor: pointer;
}

.right-resizable .header-options .dropdown-toggle{
 border: 1px solid transparent!important;   
 background: transparent!important;
}
.right-resizable .header-options .dropdown-menu{
 background: white!important;
 transform: translate(-70px, 51px);
}
.right-resizable .header-options .dropdown-menu > a{
    color: black;
    font-size: 16px;
}
@media screen and (max-width: 425px) {
    .right-resizable{
        height: 100%;
    }
    .right-resizable .header-options{
        padding: 10px;
        height: 30px;
    }
}
