
.custom-modal-container{
    width: 800px;
    margin: 2rem auto;
}
.custom-modal-incorrect-fiels .modal-dialog{
    width: 500px;
    margin: 2rem auto;
}
.custom-modal-crop .modal-dialog{
    width: 700px;
    margin: 2rem auto;
}