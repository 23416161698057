.aml-alerts-details {
  height: 100%;
  display: grid;
  grid-template-rows: max-content auto;
  overflow: auto;
}
.onboarding-buttons-approve-reject {
  width: 180px;
  height: 36px;
}

.aml-alerts-details textarea {
  padding: 1rem;
}
.aml-alerts-actions-content {
  display: flex;
  margin: 1.25rem;
  flex-direction: column;
  gap: 1rem;
}
.aml-alerts-details button {
  margin: 0;
}
.aml-alerts-actions-button {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
  padding: 1.25rem;
}
.aml-alerts-actions-content .subtitle {
  font-size: 2rem;
  margin: 0;
}
.input-element-aml-alert {
  width: 100%;
  max-width: 300px;
  margin-bottom: 1rem;
}
.aml-alerts-details .option {
  width: 47%;
  margin-bottom: 1.5rem;
}
.aml-alerts-details .option p{
  word-break: break-all;
}
.aml-alerts-details .option p:nth-child(2) {
  font-size: 1.6rem;
}
.aml-alerts-details .option p:first-child {
  color: var(--text-gray);
  font-size: 1.4rem;
  font-weight: 400;
}
.aml-alerts-details ul li{
  font-size: 1.6rem;
}

.aml-alerts-details .header-section{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.aml-alerts-details .header-section div:first-child p{
  font-size: 1.4rem;
}
.aml-alerts-details .badge-container-status p{
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 1.6rem;
}