.App,
#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

html {
  background: var(--secondary__bg-color);
}

.loading-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.65);
  z-index: 9999;
}

.loading-overlay.noshow{
  display: none;
}

.loading-wheel {
  position: absolute;
  top: 48%;
  left: 48%;
}

.container-td p {
  text-align: left;
  font-size: 1em;
}