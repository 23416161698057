.table-container-v2{
  display: grid;
  grid-template-rows: auto max-content;
  row-gap: 2rem;
  overflow: auto;
}
.table-container-v2 .table-wrapper{
  display: grid;
  grid-template-rows: max-content auto;
  height: 100%;
  overflow: auto;
}
.table-container-v2 .table-wrapper table{
  table-layout: auto;
  margin: 0;
  width: auto;
  height: max-content;
}
.table-container-v2 .table-wrapper table thead,
.beta-content-container .table-bordered thead{
  position: sticky;
  top: -.1px;
  background-color: var(--bg-dark-color);
  z-index: 2;
}
.table-container-v2 .table-wrapper table tfoot,
.beta-content-container .table-bordered tfoot{
  position: sticky;
  bottom: -1px;
  background: var(--white-color);
  z-index: 1;
  outline: 1px solid var(--black-color);
}
.table-wrapper table tbody th, .table-wrapper table tbody td{
  min-width: 150px;
}
table tbody tr.select {
  color: var(--black-color);
  background-color: var(--cell-selected);
}

.beta-content-container .table-bordered{
    text-align: center;
}
.beta-content-container .table-container-v2.Open{
   width: calc(100%-240px);
   transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    margin-left: 240px;
}
.fixed-first-column tr{
  -webkit-transition:  none;
  transition:  none;
}
.beta-content-container .table thead th {
    color: var(--white-color);
    font-weight: 400;
    white-space:nowrap;
    height: 5rem;
    width: 120px;
    min-width: 120px;
    vertical-align: middle;
    font-size: 14px;
}
.beta-content-container .table tfoot th{
    font-weight: 600;
    white-space:nowrap;
    height: 5rem;
    width: 120px;
    min-width: 120px;
    vertical-align: middle;
    font-size: 14px;

}
.beta-content-container  .badge {
    color: var(--white-color);
    padding: 10px;
    border-radius:20px;
    font-weight: 400;
    font-size: 14px;
}
.beta-content-container  .badge.badge-tooltip {
    padding: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
}

.minimizer-width-column{
  min-width: 15px;
  width: 15px;
}

.beta-content-container  input, small, tr{
    white-space:nowrap;
}
.beta-content-container .react-bootstrap-table-pagination{
  display: flex;
  justify-content: space-between;
}

.beta-content-container .react-bootstrap-table-pagination-list{
  display: contents;
}
.beta-content-container .react-bootstrap-table-pagination{
  background-color: var(--white-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-gray);
  font-weight: 300;
}
.react-bootstrap-table-pagination-limit>b{
  padding-right: 10px;
  color: var(--black-color);
}
.beta-content-container .filter-items{
    background-color: var(--text-gray);
    border-radius: 50%;
    text-align: center;
}
.beta-content-container .input-radio{
    background-color: var(--text-gray);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin: auto;
}
.input-check-select-hy:checked + .input-radio{
  background-color: var(--orange-color);
}

.beta-content-container .input-radio input[type=radio] {
  display: none;
}

.beta-content-container .input-radio input[type=radio]:checked + .input-radio {
  background-color: var(--black-color);
}

.table-wrapper table tbody td{
    font-size: 14px;
    border-right-color:transparent;
    min-width: 120px;
    text-align: center!important;
}
.table-wrapper table tbody td p{
  text-align: center;
}
.table-wrapper table .no-selection{
  cursor: default;
}
.table-wrapper table thead th,.right-resizable table thead th{
    border-right-color: transparent;
    font-size: 14px;
    text-align: center;
}
.table-container-v2 .paginator-v2{
  display: contents;
}
.table-wrapper table thead th{
  border-right-color: transparent;
  font-size: 14px;
}
.table-container-v2 .table-bordered td, .table-bordered th{
  border: none;
}

.table-container-v2 .paginator-v2 .items{
  align-items: center;
}
 
.table-container-v2 .paginator-v2 .items .page-link-v2{
  margin-left: 6px;
  border-radius: 6px;
}

.table-container-v2 .paginator-v2 .items .page-item.disabled .page-link > img {
  filter: invert(50%) sepia(15%) saturate(271%) hue-rotate(166deg) brightness(128%) contrast(85%);
}
 
.table-container-v2 .paginator-v2 ul> li:nth-child(1)> a {
  border: none;
  color: var(--bg-dark-color)!important;
}
.table-container-v2 .paginator-v2 ul> li:nth-child(2)> a {
  border: none;
}

.table-container-v2 .paginator-v2 ul> li:nth-last-child(2)> a {
  border: none;
  color: var(--bg-dark-color)!important;
}
.table-container-v2 .paginator-v2 ul> li:nth-last-child(1)> a {
  border: none;
  color: var(--bg-dark-color)!important;
}
.table-container-v2 .paginator-v2 .items .page-item>a {
  color: var(--text-gray);
}
 .table-container-v2 .paginator-v2 .items .page-item.active>a {
    color: var(--white-color)!important;
}

.table-container-v2 .paginator-v2 .items .page-item.disabled .page-link {
  color: var(--text-gray)!important;
}
.table-container-v2 .paginator-v2 input[type=number]::-webkit-inner-spin-button, 
.table-container-v2 .paginator-v2 input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.table-container-v2 .paginator-v2 input[type=number] { -moz-appearance:textfield; }

.table-container-v2 .react-bootstrap-table-pagination .active >a{
  background-color: var(--purple-color);
  border-color: var(--purple-color);
}

.table-container-v2  .minimizer{
  width: 15px!important;
  min-width: 15px!important;
}
.table-container-v2  .minimizer2{
  width: 20px!important;
  min-width: 20px!important;
}
.table-container-v2  .minimizer3{
  width: 100px!important;
  min-width: 100px!important;
}
.paginator-v2,.paginator-v2 a {
  font-size: 16px;
}

.text-ellipsis {
  width: 150px !important;
  min-width: 150px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: auto;
}

.admin-v2 .table-container-v2 .react-bootstrap-table-pagination-total,.admin-v2 .table-container-v2 .paginator-v2,.admin-v2 .table-container-v2 .paginator-v2 a{
  font-size: 1.6rem;
}
.admin-v2 .table-container-v2 .paginator-v2 input{
  width: 60px;
  height: 30px;
  border-radius: 5px!important;
  font-weight: bold;

}
.react-bootstrap-table-pagination-limit>b{
  display: inline;
}
.table-container-v2 .table-v2{
  position: relative;
  overflow: auto;
}
.table-container-v2 .pv2-bott .react-bootstrap-table-pagination{
  padding: 0;
  margin: 0 !important;
}
.input-check-select-acuant:checked + .input-radio{
  background-color: var(--orange-color);
}
.empty-table p{
  position: absolute;
  inset: auto 0;
  font-size: 1.6rem!important;
}
.input-check-select-bank-transaction:checked + .input-radio{
  background-color: var(--orange-color);
}
.table-wrapper table tbody td .container-description{
  font-size: 1.4rem;
}
.fixed-first-column tr>th:first-child,.fixed-first-column tr>td:first-child{
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}
.fixed-first-column .table-hover tbody tr:hover td:first-child{
  background-color: #ECECEC;
}
.fixed-first-column thead th:first-child{
  background-color: var(--bg-dark-color);
}
@media screen and (max-width:425px){
  .table-container-v2{
    row-gap: 1rem;
  }
  .beta-content-container .badge {
    padding: 5px;
  }
  .react-bootstrap-table-pagination-limit>b{
    font-size: 14px;
  }
  .table-container-v2 .react-bootstrap-table-pagination-list.paginator-v2{
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(.8);
  }
  .admin-v2 .table-container-v2 .paginator-v2 input{
    margin: 4px 0;
    font-size: 12px;
  }
  .table-container-v2 .pv2-bott .react-bootstrap-table-pagination{
    display: grid;
    grid-template-columns: 93px 212px;
  }
  .table-container-v2 .pv2-bott .react-bootstrap-table-pagination div{
    padding: 0;
  }
  .table-container-v2 .pv2-bott .react-bootstrap-table-pagination div:first-child{
    grid-area: 1 / 1 / 2 / 2;
  }
  .table-container-v2 .pv2-bott .react-bootstrap-table-pagination div:first-child span{
    font-size: 1.4rem;
  }
  .table-container-v2 .pv2-bott .react-bootstrap-table-pagination div:nth-child(2){
    grid-area: 2 / 1 / 3 / 3;
  }
  .table-container-v2 .pv2-bott .react-bootstrap-table-pagination div:last-child{
    grid-area: 1 / 2 / 2 / 3;
  }
}