.right-resizable-user-info{
    width: 100%;
    display: grid;
    grid-template-rows: max-content auto;
    height: 100%;
    overflow: auto;
}
.right-resizable-user-info .header-section .info > div{
    width: 48%;
}
.header-section{
    display: grid;
    grid-template-columns: max-content auto;
    padding: 1.5rem;
    gap: 1.5rem;
}
.header-section .content-image{
    position: relative;
}
.header-section .content-image .options-form{
    display: none;
}
.header-section .content-image .options-edit{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    background: var(--white-color);
}
.header-section .profile-image{
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius:18px;
    transition: opacity 300ms;
}
.header-section .profile-image:hover{
    cursor: pointer;
    opacity: 0.5;
}

.header-section .text-right > h2{
    font-size: 2rem;
    font-weight: 400;
    text-align: left;
}
.header-section .text-right .info{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 2rem;
}
.header-section .text-right .info >div:nth-child(2) p:first-child{
    color: #A8A8C7;
}
.header-section .text-right .option{
    display: flex;
    align-items: center;
    justify-items: flex-start;
    font-size: 1.6rem;
    margin-top: .3rem;
}

.header-section .text-right .option p:first-child,.validation-phone p:first-child{
    color: #A8A8C7;
    margin-right: .5rem;
}
.option-state{
    border-radius:18px;
    background: #5DD267;
    padding: .5rem 1rem;
    color:white;
    margin-top: .5rem;
}


.accordion-card{
    border:  none;
    border-bottom: 1px solid #F5F5F9!important;
    overflow: initial !important;
}

.accordion-card .card-header{
    background-color: transparent;
    border: none;
}
.accordion-card .card-header button{
    font-size: 2rem;
    font-weight: 500;
    padding: 1rem 0;
    width: 100%;
    text-align: left;
}
.accordion-card .card-header img{
    margin-left: .5rem;
}
.right-resizable-user-info .title-accordion{
    font-size: 2rem;
    font-weight: 500;
    padding: 1.75rem 0 0 1.25rem;

}
.item-kyc-info{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.75rem 0 0;
    font-size: 1.4rem;
}
.item-kyc-info p{
    font-weight: 500;
    font-size: 1.4rem !important;
}

.item-kyc-info .right-options{
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-kyc-info .right-options img{
margin-left: 1.8rem;
}

.general-info{
    display: grid;
    grid-template-columns: calc(100% - 220px) 200px;
    column-gap: 2rem
}

.general-info .information-container{
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2,calc(50% - 10px));
}
.general-info div p:first-child {
    color: #A8A8C7;
    font-size: 1.4rem;
}
.right-resizable-user-info p{
    font-size: 1.6rem;
}
.general-info .images-container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}
.general-info .images-container img{
    width: 200px;
    cursor: pointer;
    transition: 450ms all;
}
.general-info .images-container img:hover{
    opacity: .5;
}
.general-info .images-container p{
    font-size: 1.4rem;
    color: #A8A8C7;
}
.general-info .email-container,
.general-info .ip-container{
    margin: 2rem 0;
}

.default-status{
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #A8A8C7;
}

.identity-validation > h3 {
    font-size: 1.6rem;
    font-weight: 500;
}

.validation-reniec-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    margin: 1.5rem 0 3rem 0;
}
.validation-reniec-container button{
    margin-right:4rem;
    border-radius: 18px;
    padding:.8rem;
    transition: 450ms all;
}
.validation-reniec-container button:hover{
    background: #A8A8C7;
}
.validation-reniec-container button p{
    font-size: 1.4rem;
}
.validation-reniec-biofacial{
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 50% auto;
}
.validation-phone .info-validation{
    padding-right: .5rem;
}
.validation-phone-container{
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 50% auto;
}
.validation-phone{
    display: flex;
    align-items: flex-start;
}
.validation-phone .color-status-Pendiente,
.validation-phone .color-status-Revisión_manual{
    background-color: inherit !important;
    color: var(--orange-color);
}
.validation-phone .color-status-Aprobado,
.validation-phone .color-status-Verificado{
    background-color: inherit!important;
    color: var(--green-color);
}
.validation-phone .color-status-Rechazado,
.validation-phone .color-status-Información_incorrecta{
    background-color: inherit !important;
    color: var(--red-color);
}
.color-status-Pendiente{
    background-color: var(--orange-color);
}
.color-status-Aprobado, .color-status-Verificado{
    background-color: var(--green-color);
}
.color-status-Rechazado{
    background-color: var(--red-color);
}

.first-tab-options .accordion-card button{
    width: 100%;
    text-align: left;
}

.first-tab-options{
    max-height: calc(100% - 29px);
}

.content-level{
    padding:1rem;
}
body.admin-v2 .App.container .content-level .content-level-title.with-img, .content-level-file{
    margin-bottom: 2rem!important;
}
.content-level-file a{
    color: #FF8E0A;
    margin-bottom: 0!important;
    margin-top: 1rem;
}
.content-level-file h3{
    font-size: 1.6rem;
    margin: 0 .5rem 0 0;
}
.content-level-title.with-img{
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
}
.content-level-title h2{
    font-size: 1.8rem;
    margin: 0 1rem 0 0;
}
.content-level-grid{
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 10px));
    gap: 2rem;
}
.item-content-info  p:first-child{
    font-size: 1.3rem;
    color: #A8A8C7;
}
.item-content-info p:nth-child(2){
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    color: var(--black-color);
    margin: .5rem 0;
}
.item-content-info p:nth-child(2) a{
    font-size: 1.6rem;
    color: #FF7A00;
    display: flex;
    column-gap: .4rem;
    width: max-content;
}
.register-data-tab{
    padding: 1.25rem 1.75rem;
    overflow: auto;
}
.register-data-tab >div:last-child{
    width: 50%;
}

.user-blocked-grid{
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 10px));
    gap: 2rem;
    padding: 1.25rem 1.75rem;
    overflow: auto;
    grid-auto-rows: max-content;
}

.right-resizable-user-info .tabs-container.nav.nav-tabs a:focus{
    background-color: #fff;
    border-radius: 18px 18px 0 0;
}


.right-resizable .dropdown-toggle{
    border: 1px solid transparent!important;   
    background: transparent!important;
    font-size: 1.4rem;
    width: auto!important;
    font-weight: 400!important;
}
.right-resizable .buttons-container .dropdown{
    padding: 1rem;
    background: var(--purple-color)!important;
    border-radius: 8px;
    padding: .6rem 1rem;
    position: initial !important;
}
.right-resizable .buttons-container .dropdown button{
    box-shadow: none;
}
.right-resizable .buttons-container .dropdown-menu{
    background:transparent;
    transform: translate(-10px, -50px)!important;
}
.right-resizable .dropdown-menu > a{
    color: var(--black-color);
    font-size: 1.6rem;
}
.dropdown-toggle::after{
    display:none;
}
@media screen and (max-width: 1200px) {
    .right-resizable{
        position: fixed;
        right: 0;
        left: 0;
        z-index: 3;
    }
    .pagination.react-bootstrap-table-page-btns-ul.items{
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 768px) {
    .loader-resizable {
        position: fixed;
        right: 0;
        left: 0;
        background: #f5f5f9;
        z-index: 3;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .right-resizable{
        position: fixed;
        right: 0;
        left: 0;
        z-index: 3;
    }
    .buttons-container{
        padding-right: 1.4rem;
    }
     .general-info{
         display: block;
     }
     .general-info .images-container{
         display: flex;
         align-items: center;
         margin-top: 2rem;
         flex-wrap: wrap;
     }
     .general-info .images-container > div:first-child{
        width: 50%;
     }

}

.tab-content table{
    min-width: 900px!important;
    width: 100% !important;
}
.migrations-button{
    width: 243px;
    height: 36px;
}

.item-content-info .input-element p:nth-child(2){
    color: var(--text-gray)!important;
}
@media screen and (max-width: 425px) {
    .header-section{
        padding: 1rem;
    }
    .header-section .text-right > h2{
        font-size: 1.8rem;
        margin-bottom: 0;
    }
    .header-section .text-right .info{
        flex-wrap: wrap;
    }
    .right-resizable-user-info p{
        font-size: 1.4rem;
    }
    .right-resizable-user-info .container-tabs nav{
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        overflow: auto hidden;
    }
    .right-resizable{
        width: auto !important;
    }
    .accordion-card .card-header button{
        font-size: 1.8rem;
        padding: .5rem 0;
    }
    .right-resizable-user-info .title-accordion{
        font-size: 1.8rem;
    }
    .content-level-title h2{
        font-size: 1.6rem;
    }
    .general-info .information-container{
        grid-template-columns: auto;
    }
    .general-info .images-container div{
       width: 100% !important;
    }
    .general-info .images-container div img{
       width: 100%;
       padding: .8rem 0 2rem;
       pointer-events: none;
    }
    .general-info .images-container div img:hover{
       opacity: 1;
    }
    .validation-phone-container{
        grid-template-columns: auto;
        row-gap: 2rem;
    }
    .validation-reniec-container{
        row-gap: 2rem;
    }
    .item-kyc-info{
        padding: 0;
    }
    .content-level-grid{
        grid-template-columns: 100%;
    }
    .container-tabs{
        overflow: initial;
    }
    .container-tabs .tab-content{
        overflow-y: hidden;
    }
    .header-section .profile-image{
        pointer-events: none;
    }
    .header-section .profile-image:hover{
        pointer-events: none;
        opacity: 1;
    }
    .user-blocked-grid{
        grid-template-columns: auto; 
    }
    .register-data-tab{
        max-width: auto;
    }
    .register-data-tab >div:last-child{
        width: auto;
    }
}

.row-data-sector {
    display: flex;
    gap: 1rem;
}
.row-data-sector > .device-section {
    border-radius: 11px;
    border: 1px solid #c2c2c2;
    padding: 2rem;
    border-left: 3px solid #ff8e0e;
    box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 13%);
}
.row-data-sector > .device-section > * {
    width: 100%;
}
.prices-sector {
    list-style: none;
    padding: 0.5rem 0 0 0;
}
.prices-sector > li {
    font-size: 2rem;
}
.all-page-data.hscroll {
    overflow: auto;
}
.validation-biometric-container{
    display: grid;
    grid-template-columns: repeat(2, 50%);
    margin-bottom: 3rem;
}
.validation-biometric-container .validation-phone{
    margin-top: .8rem;
}