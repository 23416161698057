.custom-button{
    font-weight: 400!important;
    font-size: 1.4rem;
    border-radius: 8px;
    color:var(--white-color);
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: auto;
    transition: 300ms all;
    position: relative;
}

.button-green{
    background-color: #5DD267;
}
.button-red{
    background-color: #F22020;
}
.button-blue {
    background-color: #002a8c;
}
.button-purple{
    background-color: #5D3DFF;
}
.button-gray{
    background-color:#A8A8C7;
}
.button-orange{
    background-color: #FF8E0A;
}
.button-black{
    background-color: #343a40;
}
.button-black-v2{
    background-color: #37374E;
}
.button-outline{
    background-color: transparent;
    color: var(--black-color);
    border: 1px solid var(--black-color);
}

.button-orange-gradient{
    background: linear-gradient(99deg,#FF8E0A,#FF703A);
}

.custom-button:disabled{
    opacity: .5;
    cursor:default;
}
.custom-button:hover{
    opacity: .8;
}
.loaderBtn{
    position: absolute;
    z-index: 1;
    width: 100%;
}