.images-zoom-container{
    width: auto;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    min-width: 400px;
    max-height: 70vh;
    overflow: auto;
}
.images-zoom-container .icon-close{
    display: flex;
    justify-content: flex-end;
}

.images-zoom-container .images-list{
    width: 350px;
}

.images-zoom-container > div:nth-child(2){
    margin: auto;
}