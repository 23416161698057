
.switch-toggle-bnx-account-label {
    cursor: pointer;
	width: 50px;
	height: 25px;
    background: var(--bg-dark-color);
	display: block;
	border-radius: 100px;
	position: relative;
	margin: auto;
	font-size: 1.4rem;
	text-align: center;
}
.switch-toggle-bnx-account-label p{
	text-align: center;
}

.switch-toggle-bnx-account-label:after {
	content: '';
	position: absolute;
	top: 4px;
	left: 5px;
	width: 16px;
	height: 16px;
	background: var(--text-gray);
	border-radius: 90px;
	transition: 0.3s;
}

.switch-toggle-bnx-account-input:checked + .switch-toggle-bnx-account-label {
	background: var(--bg-dark-color);
}

.switch-toggle-bnx-account-input:checked + .switch-toggle-bnx-account-label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
    background-color: var(--white-color);
}

.switch-toggle-bnx-account-label:active:after {
	width: 6px;
}

.switch-toggle-bnx-account-label{
    display: flex;
    align-items: center;
}
.switch-toggle-bnx-account-label p{
    width: 25px;
    color: white;
	height: 19px;
}
.switch-toggle-bnx-account-label p:nth-child(2){
    color: var(--text-gray);

}