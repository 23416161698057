.manual-deposit {
}

.manual-deposit__title {
  color: var(--font__color-primary);
  padding-bottom: 1rem;
  margin: 0 3rem;
  font-size: 1.2rem;
}

.manual-deposit__step-item {
  padding-bottom: 0.5rem;
  color: var(--font__color-secondary);
  font-size: 1.2rem;
  margin: 1rem 3rem;
}
div.manual-deposit__container > div:nth-child(3){
  padding-bottom: 2rem;
}

.manual-deposit__step {
  color: var(--font__color-secondary);
  padding-right: 1rem;
}

.manual-deposit__frame {
  padding: 2rem 1rem 0;
  border-top: 1px solid var(--border__color);
}

.manual-deposit__btn {
  width: 20rem;
  margin: 1rem 0;
}

.manual-deposit__loading {
  transition: none;
}

.manual-deposit__container {
  padding-top: 2.2rem;
  overflow-y: auto;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 30rem;
}

.manual-deposit__container-with-footer {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.manual-deposit__footer {
  padding: 0 2.2rem;
  display: flex;
  align-items: center;
  min-height: 10.6rem;
  height: 10.6rem;
  border-top: 1px solid var(--border__color);
}

.manual-deposit__input-box {
  display: flex;
  flex-direction: column;
  width: 40rem;
  height: 3rem;
}

.manual-deposit__input,
.manual-deposit__select {
  width: 100%;
  background: var(--secondary__bg-color);
}

.manual-deposit__select-label,
.manual-deposit__label {
  width: 15rem;
  margin: 0;
  justify-content: flex-start;
}

.manual-deposit__input-wrapper {
  padding-bottom: 2rem;
}

.manual-deposit__select-wrapper,
.manual-deposit__input-wrapper {
  padding-bottom: 2.6rem;
}

.manual-deposit__select,
.manual-deposit__input {
  height: 4.4rem;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  min-width: 20rem;
}

.manual-deposit__input-box {
  height: 4.4rem;
}

.manual-deposit__close__btn {
  margin-top: 1rem;
}

.manual-deposit__form-body {
  width: 100%;
  min-height: 30rem;
  padding: 2.2rem;
}

.manual-deposit__select-wrapper,
.manual-deposit__input-wrapper {
  margin: 1rem 2rem;
  text-align: left;
}

.manual-deposit__select-label,
.manual-deposit__label {
  margin: 0 0 1.2rem;
  font-size: 1.4rem;
}

.manual-deposit__input-box {
  display: block;
  width: 100%;
}

.manual-deposit__success {
  display: flex;
  height: 10rem;
  align-items: center;
  justify-content: center;
}
.manual-deposit__error {
}

.manual-deposit__btn {
  width: calc(100% - 2rem);
  margin: 1rem;
  height: 5rem;
  border-radius: 0.3rem;
  font-size: 1.4rem;
}

.manual-deposit__template-body,
.manual-deposit__template-footer {
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color-secondary);
}

.manual-deposit__spinner-container {
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color-secondary);
  position: initial;
  margin-left: 0;
}

.manual-deposit__spinner-circle {
  margin: 0;
}

.manual-deposit__label-in-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.2rem;
  width: 5rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
}

.manual-deposit__with-label {
  padding-left: 6rem;
}

.manual-deposit__bank-info-label {
  padding: 2rem 3rem .5rem;
  color: var(--font__color-secondary);
  font-size: 1.4rem;
}

.manual-deposit__bank-info {
  border: 1px solid var(--border__color);
  padding: 1.5rem 2.3rem;
  margin: 3.2rem 3.2rem 3rem;
}

.manual-deposit__lwt-container {
  justify-content: unset;
}

.manual-deposit__lwt-label {
  min-width: 15rem;
}

.manual-deposit__info {
  padding-top: 0.5rem;
}


/* MEDIA QUERIES */

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .manual-deposit__lwt-label {
    min-width: 10rem;
  }
}
