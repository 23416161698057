.container-reniec-info {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5rem;
    padding-top: 0;
}

.container-reniec-info .option p:first-child {
    color: var(--text-gray);
}

.container-reniec-info .separator-info {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - .5rem));
    column-gap: 1rem;

}

.container-reniec-info .profile-image {
    height: 150px;
    transition: opacity 300ms;
}

.container-reniec-info .profile-image:hover {
    cursor: pointer;
    opacity: 0.5;
}

.reniec-type {
    width: 330px;
    margin-bottom: 2rem;
}