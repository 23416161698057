.modal-create-assets {
    max-width: 400px !important;
    margin: 2rem auto;
}

.modal-create-assets .content-assets-inputs {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.modal-create-assets .content-assets-inputs .content-switch-assets {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    color: var(--text-gray);
    column-gap: 2rem;
    padding: 0.5rem 0;
}

.modal-create-assets .content-assets-inputs .container-submit-cancel-buttons button:last-child {
    width: 118px;
}

.minimum-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.minimum-container div {
    font-size: 1.4rem;
}