.header-section-legal {
    padding: 1.5rem;
}

.header-section-legal h2 {
    font-size: 2rem;
    font-weight: 400;
    text-align: left;
}

.header-section-legal .info {
    display: grid;
    grid-template-columns: auto max-content;
}

.header-section-legal .info .option {
    display: flex;
    align-items: center;
    justify-items: flex-start;
    font-size: 1.6rem;
    margin-top: .3rem;
}

.header-section-legal .info .option p:first-child {
    color: var(--text-gray);
    margin-right: .5rem;
}

.modal-legal {
    min-height: 200px !important;
}

.modal-legal h2 {
    font-size: 1.8rem;
}

.modal-legal ul li {
    margin-left: 2rem;
}

.modal-legal ul li,
.modal-legal form p {
    font-size: 1.2rem;
}

.modal-legal form {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.modal-legal form .content-list-modal {
    height: auto;
    max-height: 150px;
    overflow: auto;
}
.modal-legal form .content-list-modal.au10tix li{
    list-style: none;
    margin: 0;
}
.modal-legal form .content-list-modal.au10tix li label{
    display: flex;
    align-items: center;
    column-gap: 1rem;
    width: max-content;
    font-size: 1.2rem;
}

.modal-legal form textarea {
    margin-top: 1rem;
    width: 100%;
    resize: none;
    background-color: var(--input-color);
    padding: 0.8rem;
    border-radius: 1.5rem;
    font-size: 1.4rem;
}

.modal-legal form .content-butt-modal {
    display: flex;
    justify-content: right;
}

.modal-legal form .content-butt-modal button {
    margin: 0 !important;
}
.modal-legal form .content-butt-modal button:first-child {
    margin-right: 1rem !important;
    width: 170px;
}

.general-info-container {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
}
.content-shareholder-btn{ 
    display: flex;
    padding-top: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
}
.name-shareholder {
    padding-bottom: 1rem;
    font-weight: 400;
    margin-top: -1.2rem;
    color: var(--text-gray);
}
.shareholder-section {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
}