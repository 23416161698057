
.bank-transactions-custom-modal-container{
    width: 500px;
    margin: 2rem auto;
}
.bank-transactions-custom-modal-container .add-modal-inputs{
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.bank-transactions-custom-modal-container .container-submit-cancel-buttons{
    gap: 1rem;
    margin-top: 1rem;
} 
.bank-transactions-custom-modal-container .add-limit-button{
    width: 124px;
    height: 36px;
}
.bank-transactions-custom-modal-container .input-element-textarea{
    padding: 1rem;
}
.bank-transactions-custom-modal-container .content-all-buttons{
    display: flex;
    justify-content: center;
    column-gap: 1rem;
}