.signup-page__container {
  border-radius: 10px;
}

.signup-page__header {
  height: 6.8rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 2px solid var(--standalone-form__header-border-color);
}

.signup-page__header-text {
  font-size: 2rem;
}

.signup-page__footer {
  padding: 0 2.8rem;
  margin: 0;
}

.signup-page__link {
  font-size: 1.2rem;
}
/*ERROR MESSAGE*/
.signup-form__error {
  color: var(--danger__color) !important;
}
/*BUTTON TEXT*/
.ap-button__btn--disabled, .ap-button__btn--disabled:hover, .ap-button__btn--disabled:focus, .ap-button__btn:disabled, .ap-button__btn:disabled:hover {
  color: #4c4c4c !important;
}
/*BUTTON BACKGROUND*/
.standalone-layout__wrapper div.signup-page__container .signup-form__footer .signup-form__btn {
  border: 1.5px solid #F28E2A!important;
  background: white;
  color: #F28E2A;
  font-weight: 500;
}
.standalone-layout__wrapper div.signup-page__container .signup-form__footer .signup-form__btn:disabled {
  color: #F28E2A!important;
  opacity: 0.7;
}
.standalone-layout__wrapper div.signup-page__container .signup-form__footer .signup-form__btn:disabled:hover {
  color: white!important;
}
.standalone-layout__wrapper div.signup-page__container .signup-form__footer .signup-form__btn:hover {
  background: #F28E2A;
  color: white;
}