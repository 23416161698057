.login-form__form {
  margin: 3rem 2.8rem 2.8rem;
}
.login-form__container {
  border-radius: var(--standalone-form__border-radius);
}

.login-form__header {
  height: 6.8rem;
  border-top-left-radius: var(--standalone-form__border-radius);
  border-top-right-radius: var(--standalone-form__border-radius);
  border-bottom: 2px solid var(--standalone-form__header-border-color);
}

.login-form__header-text {
  font-size: 2rem;
}

.login-form__footer {
  padding: 0 2.8rem;
  margin: 0;
}

.login-form__label {
  font-size: 1.4rem;
  letter-spacing: -0.5px;
}

.login-form__input {
  height: 3.6rem;
  border-radius: 5px;
  border: 1px solid var(--border__color);
}

.login-form__input-wrapper {
  padding-bottom: 3.6rem;
  margin: 0;
}

.login-form__btn {
  height: 5rem;
  border-radius: 5px;
  font-size: 1.4rem;
}

.login-form__separator {
  margin: 0.9rem -2.8rem 3rem -2.8rem;
}

.login-form__link {
  font-size: 1.2rem;
}


.standalone-layout__wrapper div.login-form__container:before {
  content: '';
  width: 41.6rem;
  height: 7rem;
  background-image: url(/local/logos/logo-banexcoin.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-bottom: -6rem;
  top: -80px;
  position: relative;
}
/*ERROR MESSAGE*/
.standalone-form__error {
  color: red !important;
}
