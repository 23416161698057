.bank-transactions-data{
    grid-template-rows: max-content max-content auto;
}
.min-width-filter-input-bank-transactions{
    min-width: 242px!important;
}
.bank-buttons-container{
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    flex-wrap: wrap;
}
.bank-buttons-details{
    text-align: center;
    margin-top: 1rem;
}
.bank-buttons-details p{
    font-size: 1.2rem;
}
.bank-buttons-details-amount,
.bank-buttons-details summary{
    font-weight: bold;
    font-size: 1.4rem!important;
}
.bank-transaction-action-button{
    color: var(--orange-color);
    font-weight: bold;
    cursor: pointer;
    font-size: 1.6rem;
}
.totals-buttons-bank-transactions{
    margin-top: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

}
.totals-bank-transactions{
    display: flex;
    gap: 4rem;
}
.totals-bank-transactions p{
    font-size: 1.4rem;
}