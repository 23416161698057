.deposit-limits-details h2{
    font-size: 2rem;
}

.deposit-limits-details .header-section > div{
    justify-content: space-between;
    text-align: right;
}
.deposit-limits-details .header-section > div > p:first-child{
    font-size: 1.4rem;
    margin-bottom: 1rem;
}
.deposit-limits-details .header-section label{
    margin-right: 0;
}
.deposit-limits-details .content-tab-container{
    padding: 1.25rem;
}
.deposit-limits-details h3{
    margin: 2rem 0;
}
.deposit-limits-details .header-section p{
    max-width: 100px;
    border-radius: 5px;
    font-size: 1.6rem;
    text-align: center;
    padding: 4px;
    margin: 0 0 0 auto;
}
.deposit-limits-details .tab-content{
    padding: 2rem;
}