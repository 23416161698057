.filter-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    opacity: 1;
    gap: 20px;
    animation-name: opacity1to0;
    animation-duration: 200ms;
}
.filter-group.styles-with-right-section{
    width: 1400px;
    max-width: 1400px;
}

.filter-group .btn-filters.search {
    border-radius: 18px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    cursor: pointer;
    box-sizing: content-box;
    background-color: #FF8E0A;
    border: 1px #FF8E0A solid;
}

.filter-group .btn-filters.search .search-text {
    display: none;
    font-size: 14px;
    color: white;
    margin-left: 10px;
    font-weight: 400;
}
.filter-group > div:not(:last-child) {
    min-width: auto;
  }

@keyframes opacity1to0 {
    to {
        opacity: 1;
    }

    from {
        opacity: 0;
    }
}

@media (max-width:425px) {
    .filter-group {
        position: sticky;
        left: 0;
        padding: 0 1.2rem;
        row-gap: 15px;
    }

    .filter-group .btn-filters.search {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .filter-group .btn-filters.search .search-text {
        display: block;
    }
}
