.header-section .title-detail {
    font-weight: 600;
}

.container-trade-info {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5rem;
    padding-top: 0;
}

.container-trade-info .option p:first-child {
    color: var(--text-gray);
}

.container-trade-info .separator-info {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - .5rem));
    column-gap: 1rem;

}