.loader-spinner-container {
    width: -webkit-fill-available;
    margin: auto;
    position: absolute;
    text-align: center;
}
.loader-spinner-container .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    text-align: center;
    margin-top: 10%;
}
.loader-spinner-container .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 3px solid #FF8E0A;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #FF8E0A transparent transparent transparent;
}
.loader-spinner-container .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.loader-spinner-container .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.loader-spinner-container .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
.loader-spinner-container > p{
    font-size: 14px;
    margin-top: 15px;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.container-loading-kyc span{
    font-size: 18px;
    font-weight: 500;
}
.container-loading-kyc img{
    width: 100px;
}

.ring {
    --uib-size: 2rem;
    --uib-speed: 2s;
    --uib-color: var(--white-color);
    height: var(--uib-size);
    width: var(--uib-size);
    vertical-align: middle;
    transform-origin: center;
    animation: rotate var(--uib-speed) linear infinite;
  }
.ring.orange {
    --uib-color: var(--orange-color);
    --uib-size: 3rem;
  }
  
  .ring circle {
    fill: none;
    stroke: var(--uib-color);
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: stretch calc(var(--uib-speed) * 0.75) ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes stretch {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dashoffset: -124px;
    }
  }
  