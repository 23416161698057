:root {
  /* PC == palette color */

  /* BASE COLORS */
  --pc__branding: 64, 80, 144;
  --pc__branding-gradient-start: 99, 127, 234;
  --pc__branding-gradient-end: 77, 105, 211;
  --pc__font-primary: 61, 71, 84;
  --pc__font-secondary: 24, 24, 24;

  /* BACKGROUND COLORS (order=light-dark)*/
  --pc__background-primary: 252, 252, 252;
  --pc__background-secondary: 229, 229, 229;
  --pc__background-tertiary: 174, 174, 174;
  --pc__background-quaternary: 200, 200, 200;

  /* ACCENT COLORS */
  --pc__accent-additive: 79, 203, 133;
  --pc__accent-subtractive: 255, 71, 114;
  --pc__accent-warning: 249, 133, 48;
  --pc__accent-success: 79, 203, 133;
  --pc__accent-danger: 255, 71, 71;
  --pc__accent-highlight: 85, 118, 250;

  /* TH = theme  */

  /* GENERAL */
  --th__component-box-shadow-color: 76, 100, 138, 0.17;
  
  /* SETTINGS PAGES*/
  --th-settings__accent-bar-display: block;
  /* SETTINGS -- container */
  --th-settings__body-bg-color: transparent;
  --th-settings__body-max-width: 106rem;
  --th-settings__body-height: auto;
  --th-settings__body-margin: 0;
  --th-settings__body-padding: 3rem;
  /* SETTINGS -- container - header */
  --th-settings__body-header-bg-color: transparent;
  --th-settings__body-header-margin: 2rem;
  --th-settings__body-header-with-border-height: 7rem;
  /* SETTINGS -- container - header title */
  --th-settings__body-header-title-font-size: 2rem;
  --th-settings__body-header-title-font-color: #ffffff;
  --th-settings__body-header-title-letter-spacing: 0.05rem;
  --th-settings__body-header-title-padding-bottom: 2rem;
  --th-settings__body-header-title-padding-left: 2.8rem;
  --th-settings__body-header-title-padding-right: 1.6rem;
  /* SETTINGS -- container - header sub title*/
  --th-settings__body-header-sub-title-font-size: 1.2rem;
  --th-settings__body-header-sub-title-font-color: #ffffff;
  --th-settings__body-header-sub-title-letter-spacing: 0.05rem;
  --th-settings__body-header-sub-title-line-height: 1.5rem;
  /* SETTINGS -- card */
  --th-settings__card-box-shadow: 0 0.5rem 2rem 0 rgba(var(--th__component-box-shadow-color));
  --th-settings__card-border-radius-size: 1rem;
  /* SETTINGS -- card - header*/
  --th-settings__card-header-bg-color: rgb(var(--pc__background-primary));
  --th-settings__card-header-accent-border: 2px solid rgb(var(--pc__accent-highlight));
  --th-settings__card-header-padding: 2rem;
  --th-settings__card-header-title-font-size: 2rem;
  --th-settings__card-header-title-font-color: rgb(var(--pc__font-primary));
  --th-settings__card-header-title-letter-spacing: 0.05rem;
  /* SETTINGS PAGES --- SPECIFIC SETTINGS*/
  /* SETTINGS -- profile & security */
  --th-settings__profile-and-security-card-max-width: calc((var(--th-settings__body-max-width) / 2) - (var(--th-settings__body-padding) * 3));
  --th-settings__profile-and-security-card-margin-r: 3rem;
  --th-settings__profile-and-security-card-margin-b: 4rem;
  --th-settings__profile-and-security-row-width: 100%;
  --th-settings__profile-and-security-card-border-right: none;
  --th-settings__profile-and-security-card-border-bottom: none;
  /* SETTINGS -- kyc */
  --th-settings__kyc-cards-container-margin-l: 0;
  --th-settings__kyc-card-border-radius: var(--th-settings__card-border-radius-size);
  --th-settings__kyc-card-bg-color: rgb(var(--pc__background-secondary));
  --th-settings__kyc-card-container-border-color: transparent;
  --th-settings__kyc-card-container-border-color--selected: transparent;
  --th-settings__kyc-card-container-border-size: 0;
  --th-settings__kyc-card-container-border-size--selected: 0;
  --th-settings__kyc-card-header-bg-color: rgb(var(--pc__background-primary));
  --th-settings__kyc-card-body-top-border-color: transparent;
  --th-settings__kyc-card-body-top-border-size: 0;
  --th-settings__kyc-card-body-bottom-border-color: rgb(var(--pc__background-tertiary));
  --th-settings__kyc-card-body-bottom-border-size: 0.1rem;
  --th-settings__kyc-card-footer-color--verified: rgb(var(--pc__accent-success));
  /* SETTINGS -- kyc - star */
  --th-settings__kyc-card-star-display: block;
  --th-settings__kyc-card-star-bg-color: rgb(var(--pc__background-primary));
  --th-settings__kyc-card-star-fill-color: rgb(var(--pc__accent-additive));
  --th-settings__kyc-card-star-box-shadow: 0 0.5rem 4rem 0 rgba(0, 0, 0, 0.2);
  /* SETTINGS -- trade reports*/
  --th-settings__trade-reports-create-new-header-display: flex;
  --th-settings__trade-reports-create-new-actions-holder-padding: 2rem;
  --th-settings__trade-reports-create-new-actions-holder-flex-direction: row;
  --th-settings__trade-reports-create-new-actions-holder-border: 1px solid rgba(255, 255, 255, 0.5);
  --th-settings__trade-reports-create-new-actions-btn-width: 45%;
  --th-settings__trade-reports-create-new-actions-top-btn-margin-b: 0;
  --th-settings__trade-reports-create-new-actions-btn-font-color: rgb(var(--pc__font-primary));
  --th-settings__trade-reports-create-new-actions-btn-bg-color: #ffffff;
  /* SETTINGS -- API keys*/
  --th-settings__api-keys-create-new-header-display: flex;
  --th-settings__api-keys-create-new-actions-holder-margin: 1.4rem;
  --th-settings__api-keys-create-new-actions-holder-btn-width: 24rem;
  --th-settings__api-keys-create-new-actions-holder-btn-border: 1px solid var(--retail-api-keys-form__create-new-border-color);
  --th-settings__api-keys-create-new-actions-documentation-btn-margin-t: 1.6rem;
  --th-settings__api-keys-create-new-actions-documentation-btn-justify-content: center;
  --th-settings__api-keys-create-new-actions-documentation-btn-font-color: #ffffff;
  --th-settings__api-keys-create-new-actions-btn-font-color: rgb(var(--pc__font-primary));
  --th-settings__api-keys-create-new-actions-btn-bg-color: #ffffff;
  /* SETTINGS -- TrustPay External Credit Card Form */
  --th-settings__trustpay-form-font-color: 0, 0, 0;


  /* NEW BANEXCOIN SETTINGS */
  --retail-dashboard__card-header-title-font-color: rgb(44, 44, 44);
  --component__color-primary: var(--retail-dashboard__card-header-title-font-color);
  --font__color-primary: var(--retail-dashboard__card-header-title-font-color);
  --nav-header__bg-gradient: rgb(255, 255, 255);
  --sub-nav-row__item-color: rgb(41, 41, 41);
  --sub-nav-row__item-color-hover: rgb(0, 0, 0);
  --sub-nav-row__border-color--hover: rgb(247, 108, 28);
  --secondary__bg-color: rgb(255, 255, 255);
  /* para trading view*/
  --table__row-separator-color: rgba(var(--pc__background-tertiary), 0.75);
  --tertiary__bg-color: rgb(97, 97, 97);

  --tv-chart__bg-color: var(--secondary__bg-color);
  --tv-chart__grid-color: var(--table__row-separator-color);
  --tv-chart__grid-vertical-color: var(--table__row-separator-color);
  --tv-chart__grid-horizontal-color: var(--table__row-separator-color);
  --tv-chart__scale-font-color: var(--font__color-primary);
  --tv-chart__scale-line-color: rgb(255,255,255);
  --tv-chart__scale-bg-color: rgb(255,255,255);
  --tv-chart__graph-up-color: var(--exchange-colors__buy);
  --tv-chart__graph-down-color: var(--exchange-colors__sell);
  --tv-chart__graph-border-up-color: var(--exchange-colors__buy);
  --tv-chart__graph-border-down-color: var(--exchange-colors__sell);
  /* para trading view*/

  /* V2 COLORS */
  --bg-scrollbar : #C8C8CB;
  --bg-dark-color:#1A1A2F;
  --twoFa-color:#27273D;
  --orange-color:#FD8E0A;
  --white-color:white;
  --white2-color:#F5F5F9;
  --gray-color:#B8B8B8;
  --red-color:#F22020;
  --green-color:#5DD267;
  --purple-color:#5D3DFF;
  --blue-color:#3095fa;
  --black-color-shadow:#080812;
  --black2-color:#393950;
  --gray2-color:#ccc;
  --black-color:black;
  --input-color:rgb(168, 168, 199, 0.14);
  --text-gray:#a8a8c7;
  --cell-selected:rgba(0,0,0,.075);
  --app-m-bgc : #fafafa;
  --svg-icon-app :rgba(0, 0, 0, 0.54);
  --svg-icon-app-hover :#0055d4;
  --tag-private-cl : #ed7b00;
  --tag-public-cl : #3b8aff;
  --tag-private-bg : #fff7e6;
  --tag-public-bg : #e6f7ff;
  --tag-private-bd : #ffd6ab;
  --tag-public-bd : #abcdff;
  --tag-body-bd: #dbdbdb;
  --tag-body-cl: #7a7a7a;
  --tag-no-body: #4a4a4a;
  --table-bd-app: #eaeaea;
  --btn-submit-hover : #014ec0;
  --inpt-check-focus : #86b7fe;
  --inpt-check-focus-sd : 0 0 0.3em hsl(0deg 0% 48% / 60%);
  --inpt-check-checked : #0d6efd;
  --inpt-check-checked-sd : 0 0 0.3em rgb(0 85 212 / 80%);
  --pagin-pg-link : #363636;
  --pagin-pg-link-hover : #b5b5b5;
  --form-contr-cl : #80bdff;
  --inpt-switch-off : hsla(0,0%,71%,.9);
  --webkit-shadow : 1px 2px .25em rgba(0,0,0,.3);
  --modal-title : #a5a5a5;
  --lottie-title: #e5d8d8;
  --btn-confirm-modal: #333;
}
body, body:before {
  background: #F5F6F8;
}
html, body, #root, .App.container {
  height: 100%;
  position: relative;
  display: grid;
  vertical-align: top;
  width: 100%;
  top: 0;
  grid-template-rows: 1fr 0fr;
  overflow: auto;
}
a {text-decoration: none!important;}
.fiat-deposit-form__frame {
  padding: 1rem 0rem 0 0rem;
}
.verification-required, .verification-required__no-link {
  background: #efefef;
  border-radius: 0.5rem;
}
.verification-required:hover {
  background: #cfcfcf;
}
.page-header__container {
  background: #f28e2b!important;
  margin-bottom: 0px!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  z-index: 4;
}
.menuly-child .page-header-nav__item, .nav-link.dropdown-toggle {
  background: transparent;
  color: rgb(255, 255, 255)!important;
  padding-left: .3rem;
  padding-right: .3rem;
  text-align: center;
}
.menuly-child .page-header-nav__item:hover {
  background: rgba(255, 255, 255, 0.199);
}
.menuly-child .page-header-nav__item.page-header-nav__item--selected {
  background: #faaf61;
}
.menuly-child .page-header-nav__item .dropdown-menu {
  transform: none!important;
  left: auto!important;
  inset: inherit!important;
  top: 57px!important;
}
.menuly-child .page-header-nav__item .dropdown-menu.show {
  position: absolute!important;
  background: #ffffff;
  opacity: 1!important;
  border: 1px solid #d5d5d5;
  box-shadow: 0 0 1px rgb(67 67 67 / 51%);
}
.menuly-child .page-header-nav__item .dropdown-menu.show a {
  color: rgb(27, 27, 27);
  background-color: rgb(234, 234, 234);
}
.menuly-child .page-header-nav__item .dropdown-menu.show a:hover {
  background-color: rgb(215, 215, 215);
}
.menuly-child .page-header-nav__item span.page-header-nav__label {
  text-transform: uppercase;
  font-size: 0.9rem!important;
  padding-left: 0rem;
  padding-right: 0rem;
}
.page-header-nav__item--selected, .page-header-nav__item--selected:hover, .page-header-nav__item:hover {
  color: #ffffff;
}
.select-list .form-check {
  height: 15px;
  display: flex;
  align-items: center;
}
.loading-box-sp {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  background: rgb(255 255 255 / 48%);
  bottom: 0;
  top: 0;
  align-items: center;
  width: 100%;
  z-index: 9;
}
.loading-box-sp.open {
  display: inline-flex;
}
.loading-box-sp > div {
  margin: 0 auto;
}
@keyframes jump {
  0%   {transform: translate3d(0,0,0) scale3d(1,1,1);}
  40%  {transform: translate3d(0,30%,0) scale3d(.7,1,1);}
  100% {transform: translate3d(0,40%,0) scale3d(1,1,1);}
}
.speech-bubble {
  position: absolute;
  background: #e43b11;
  border-radius: .4em;
  right: 0;
  top: -7px;
  padding: .3rem 1rem;
  z-index: 99;
  transform-origin: 50% 50%;
  animation: jump .6s linear alternate infinite;
}

.dashboard-page__notification-row {
  border-radius: 0.5rem;
}
.transfer-request-notification-list__empty {
  background: var(--nav-header__bg-gradient);
  height: 5rem;
}
.retail-price-chart {
  background: transparent;
}
.dashboard-page__market-header {
  padding-left: 0;
}
.market-overview__chart-component-container {
  border: 1.5px solid #F1F1F1;
  padding: 10px;
  height: 61.4rem;
  border-radius: 0;
}
.market-overview__market-overview-container {
  margin-left: 40px;
  width: 30%;
}
.market-overview__instrument-selector {
  background: transparent;
}
.market-overview-instrument {
  background: #F1F1F1;
  border: none;
  border-radius: 0;
}
.market-overview-instrument__row {
  background: transparent;
  border: none;
}
.market-overview__instrument-selector .instrument-selector-popover__popover {
  width: auto;
  border: none;
}
.instrument-positions-summary__row {
  border-bottom: none;
}
.instrument-positions-summary__row:first-of-type > *, .instrument-positions-summary__row-label {
  color: #181818;
}
.instrument-selector__trigger {
  width: auto;
  min-width: 10rem;
}
.instrument-table__row .instrument-table__title {
  color: #353A36;
}
.market-overview__instrument-selector .instrument-selector__trigger {
  background: white;
}
.market-overview-instrument__title:first-child {
  font-style: normal;
  padding-top: 0;
  font-weight: 500;
  font-size: 1.8rem;
}
.balance-item__container {
  background: transparent;
  border: none;
}
.balance-item__container:hover {
  color: var(--component__color-primary);
  background: rgba(0, 0, 0, 0.09);
  border-radius: 0.5rem;
}
.activity {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.activity__header-bar {
  background-color: transparent;
  border-bottom: none;
  padding-left: 1rem;
}
.activity__header-text {
  font-size: 2rem;
  font-weight: 500;
}
.activity__table-header {
  padding: 0 2rem;
}
.dashboard-page__recent-activity-container {
  border: 1.5px solid #F1F1F1;
  padding: 10px;
}
.flex-table {
  background: white;
}
.flex-table__header--background {
  background: white;
  border-top: none;
  border-bottom: none;
}
.flex-table__column {
  color: var(--retail-dashboard__card-header-title-font-color);
  font-weight: 500;
}
.flex-table__column.activity__header-column {
  border: none;
}
.flex-table__row {
  background: white;
  border-bottom: none;
}
.flex-table__row:hover {
  background: rgb(246, 246, 246);
}
.activity__action-text--secondary {
  color: #8f8f8f;
  font-weight: 400;
}
.page-header-nav__item {
  padding-left: 3rem;
  padding-right: 3rem;
  min-height: var(--nav-header__height);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  border-top: none;
  -ms-flex-order: 2;
  order: 2;
}
.page-header-nav__item {
  width: auto;
  height: 5.2rem;
}
/* .page-header-nav__label {
  display: none;
} */
.page-header-nav__item:focus {
  border-right: none;
}
.ap-icon.ap-icon--exchange, .ap-icon.ap-icon--compra-venta, .ap-icon.ap-icon--easybuy  {
  width: 2.5rem;
}
.ap-icon.ap-icon--exchange svg, .ap-icon.ap-icon--compra-venta svg {
  margin: 0 auto;
}
/* #root .container.logged {
  width: calc(100% - 8rem);
  margin-left: 8rem;
} */
#root, .App.container {
  padding: 0;
}
.container.center-content {
  /* max-width: 1121px; */
}

.container .react-joyride {
  display: none;
}

.page-header__container {
  height: 60px;
}

.container.center-content .ap-logo__img {
  width: 40px;
  padding: 1rem 0 0 0;
}

.container.center-content .ap-logo.my-first-step {
  float: left;
}

.container.center-content react-joyride {
  display: none;
}

.container.center-content .page-header__content-wrapper {
  z-index: 1;
  padding-top: 5px;
  padding-left: 10px;
}

.content-container {
  padding: 2.2rem;
}
.sidebar-content {
  width: 40rem;
  padding: 1rem 2rem;
}
.sidebar-content h3 {
  font-weight: 600;
  padding: 1rem 0;
}
.sidebar-content .card-line b {
  font-weight: 500;
  font-size: 1.4rem;
  color: #757575;
  display: block;
  padding: .5rem 0 .5rem;
}
.sidebar-content .card-line .card-line-body {
  display: flex;
  vertical-align: middle;
  background: white;
  padding: 1.5rem 1.5rem 1.5rem;
}
.sidebar-content .card-line .card-line-body .ex-row {
  padding: 0 1.5rem 0 .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-content .card-line .card-line-body .ex-row button {
  display: table-cell;
  vertical-align: middle;
  border-radius: 6rem;
}
.sidebar-content .card-line .card-line-body .ex-row button span {
  padding-left: .5rem;
}
.sidebar-content .card-line .card-line-body p {
  color: #6A6A6A;
  font-size: 1.3rem;
  text-align: justify;
  margin: 0;
}
.sidebar-content .card-line .card-line-body p > b {
  display: inline;
}
.modal-return.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: transparent;
}
.modal-return.modal .modal-dialog {
  position: relative;
  width: auto;
}

.modal {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: transparent !important;
}




.modal-return.modal .modal-dialog {
  background-color: white;
  border-radius: .5rem;
  box-shadow: 0 0 7px 1px rgb(0 0 0 / 42%);
}
.modal-return.modal .modal-dialog .modal-content {
  padding: 1rem 1.5rem;
}
.modal-return.modal .modal-dialog .modal-content .modal-header {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  padding: 0 1rem;
  border-bottom: none;
}
.modal-return.modal .modal-dialog .modal-content .modal-header .close span {
  font-size: 4.5rem;
  font-weight: 500;
}
.modal-return.modal .custom-ui > h1 {
  padding: 1rem 0 0.5rem;
  font-size: 1.7rem;
}
.modal-return.modal .custom-ui > ul li {
  font-weight: 500;
  font-size: 1.3rem;
}
.modal-return.modal .custom-ui .form-group {
  margin: 0;
}
.modal-return.modal .custom-ui .form-group .form-label {
  font-size: 1.3rem;
  line-height: 1.5;
}
.modal-return.modal .custom-ui textarea {
  font-size: 1.3rem;
  font-weight: 500;
}

.modal-return.modal .button-fields-botton {
  display: flex;
  width: 80%;
  margin: 1rem auto 0;
}
.modal-return.modal .button-fields-botton button {
  display: inline-block;
  width: 50%;
  padding: 1.5rem 0;
  background-color: grey;
  color: white;
  font-weight: 500;
  font-size: 1.3rem;
  border-radius: .5rem;
  margin-right: .5rem;
  opacity: 0.9;
}
.modal-return.modal .button-fields-botton button:hover {
  opacity: 0.8;
}
.modal-return.modal .button-fields-botton button:focus {
  opacity: 1;
}
.modal-return.modal .button-fields-botton button:disabled {
  opacity: 0.6;
  outline: none;
  box-shadow: none;
}
.modal-return.modal .button-fields-botton button.confirmation {
  background-color: #20a65c;
}
.react-confirm-alert-overlay {
  background: rgb(59 59 59 / 90%);
}
.Toastify__toast-container {
  z-index: 200000;
}




.custom-ui-alert {
  padding: 2rem 2rem;
  background: rgb(255 255 255);
  height: 100vh;
  width: 80%;
  margin: auto;
  -webkit-box-shadow: 0 0 2px #9c9c9c;
  box-shadow: 0 0 2px #9c9c9c;
  border-radius: .5rem;
  position: relative;
  top: 0;
  bottom: 0;
  overflow: scroll;
}
.custom-ui-alert h1 {
  padding: .5rem 0;
}
.custom-ui-alert .field-row .left {
  display: none;
}
.custom-ui-alert .field-row b {
  font-size: 1.4rem;
  font-weight: 500;
}
.custom-ui-alert .close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 2rem;
  font-size: 3rem;
  font-weight: 400;
}
.sticky-lo {
  position: sticky;
}
.group-form.card .card-body .form-group > img {
  width: 60%;
  display: block;
  margin: 0 auto 1rem;
}
.group-form.card .card-body .form-group .drop_btn {
  padding: 1.3rem;
  font-size: 1.5rem;
  font-weight: 600;
  background: #e6e6e6;
  margin: 1rem 0 0.5rem;
  border-radius: .5rem;
}
.group-form.card .card-body .form-group .drop_btn:hover {
  background: #cccccc;
}
.custom-ui-alert .group-form.col-xl-6.card {
  border: none;
}
.custom-ui-alert .group-form.col-xl-6.card .card-body {
  background-color: rgb(252, 252, 252);
}
.custom-ui-alert .group-form.col-xl-6.card .card-body .row > div {
  padding-right: 0px;
  padding-left: 0px;
}
.custom-ui-alert .group-form.col-xl-6.card .card-body .row > div .form-group {
  margin: 0.8em 2em;
}
.custom-ui-alert .group-form.col-xl-6.card .card-body .row > div .form-label {
  line-height: 1.5;
  margin-bottom: 0.2em;
}
.custom-ui-alert .group-form.col-xl-6.card .card-body .row > div .form-control {
  background-color: transparent;
  border: none;
  color: #0f1731;
  font-size: 1.7rem;
  font-weight: 600;
  padding: 0.8em 0em;
  box-shadow: none;
}
.table-banexcoin {
  border-radius: 2rem;
  background: white;
  padding: 0 1.5rem 2rem 0;
}
.table-banexcoin tbody tr td.positive {
  color: rgb(25, 148, 86);
}
.table-banexcoin tbody tr td.negative {
  color: rgb(197, 43, 51);
}
.table-banexcoin .react-bootstrap-table-pagination {
  background: transparent;
  margin: 16px 0px 0!important;
  padding: 2rem .75rem 1rem;
}
.table-banexcoin .pagination .page-item span {
  cursor: pointer;
}
.table-banexcoin .pagination .page-item.active span {
  cursor: default;
}
.table-banexcoin .table-responsive::-webkit-scrollbar {
  all:unset;
}
.table-banexcoin .react-bootstrap-table-pagination-limit b {
  font-weight: 500;
  font-size: 1.2rem;
  color: rgb(133, 133, 133);
  text-transform: uppercase;
}
.table-banexcoin .react-bootstrap-table-pagination-total {
  font-weight: 500;
  font-size: 1.2rem;
  color: rgb(133, 133, 133);
  text-transform: uppercase;
  line-height: 2.5;
}
.table-banexcoin .react-bootstrap-table-pagination select {
  padding: .5rem .5rem .5rem 1rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: #a4a3a3;
  border: 1px solid #d1d1d1;
  border-radius: .5rem;
  margin-left: 1rem;
  box-shadow: 0 0 3px 0px #bbbbbb;
}
.table-banexcoin .react-bootstrap-table-pagination select option {
  background: white;
  box-shadow: none;
}
.table-banexcoin table {
  margin: 0 0rem 1.5rem 0rem;
  table-layout: auto!important;
  white-space: nowrap;
}
.table-banexcoin .kyc_status {
  width: 15rem;
}
.table-banexcoin .created_at {
  width: 15rem;
  display: table-cell;
}
.table-banexcoin td .fastatus {
  border-radius: .3rem;
  padding: .5rem 1rem;
  color: white;
}
.table-banexcoin td .fastatus.REJECTED {
  background: rgb(228, 50, 65);
}
.table-banexcoin td .fastatus.SENT {
  background: rgb(35, 202, 91);
}
.table-banexcoin td .fastatus.CANCELED {
  background: rgb(250, 148, 32);
}
.table-banexcoin table thead th {
  border: none;
  color: rgb(87, 87, 87);
  font-size: 1.2rem;
  font-weight: 500;
  padding: 2.4rem .75rem;
  vertical-align: middle;
  text-transform: uppercase;
}
.table-banexcoin table thead th select {
  padding: 1rem 0.5rem;
  width: 100%;
  min-width: 10rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 4px rgb(59 59 59 / 20%);
  border: 1px solid #E9E9E9;
}
.table-banexcoin table thead {
  background: #F8F8F8;
  padding: 0 0rem;
}
.table-banexcoin table .first-thead th {
  padding: 1rem .75rem;
}
.table-banexcoin table .second-thead {
  background: #f0f0f0;
}
.table-banexcoin table .second-thead th {
  padding: 0.5rem .75rem;
}
.table-banexcoin table .second-thead th input {
  background: #fefefe;
  border-radius: .5rem;
  border: none;
}
.table-banexcoin table thead th input {
  background: transparent;
  padding: 1rem .5rem;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 0 4px rgb(59 59 59 / 20%);
  border: 1px solid #E9E9E9;
  width: 100%;
  min-width: 10rem;
}
.table-banexcoin table thead th input:hover {
  border: 1px solid #a5a5a5;
  box-shadow: 0 0 4px rgba(49, 49, 49, 0.2);
}
.table-banexcoin table thead th input[name="banexcoinUserAccountId"] {
  width: 8rem;
}
.table-banexcoin table thead th input[name="banexcoinUsername"] {
  width: 12rem;
}
.table-banexcoin table thead th input[name="lastName"], .table-banexcoin table thead th input[name="secondLastName"] {
  width: 13rem;
}
.table-banexcoin table thead th select option {
  background: white;
}
.table-banexcoin table thead th select option:checked {
  color: white;
  background: #0095ff;
}
.table-banexcoin table tbody tr {
  border-radius: .5rem;
  overflow: hidden;
}
.table-banexcoin table tbody tr td {
  vertical-align: middle;
  border: none!important;
  width: auto!important;
}
.table-banexcoin table tbody tr td i {
  opacity: 0.7;
}
.table-banexcoin table tbody tr td b.positive {
  color: rgb(35, 158, 97);
}
.table-banexcoin table tbody tr td b.negative {
  color: rgb(209, 58, 58);
}
.table-banexcoin table tbody tr.emptydata {
  background-color: #bbbbbb;
  text-align: center;
  font-size: 1.7rem;
  color: #2b2b2b;
}
.table-banexcoin table tbody tr.rowstatusNone {
  border-color: #dfdfdf;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  color: #f2f2f2;
  text-shadow: -1px 1px 1px #7b7b7b;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6rem;
  background: #d7d7d7;
}
.table-banexcoin table tbody tr.rowstatus1 {
  border-color: #b7d0f7;
  border-style: solid;
  border-width: 2px 2px 2px 1rem;
  color: #1a2c43;
}
.table-banexcoin table tbody tr.rowstatus1 tr {
  color: #1a2c43;
}
.table-banexcoin table tbody tr.rowstatus2 {
  border-color: #ffda73;
  border-style: solid;
  border-width: 2px 2px 2px 1rem;
  color: #914f2d;
}
.table-banexcoin table tbody tr.rowstatus2 td {
  color: #914f2d;
}
.table-banexcoin table tbody tr.rowstatus5 {
  border-color: #ffda73;
  border-style: solid;
  border-width: 2px 2px 2px 1rem;
  color: #914f2d;
}
.table-banexcoin table tbody tr.rowstatus5 td {
  background-color: #fff4d5;
  color: #914f2d;
}
.table-banexcoin table tbody tr.rowstatus7 {
  border-color: #93eeaf;
  border-style: solid;
  border-width: 2px 2px 2px 1rem;
  color: #1a4328;
}
.table-banexcoin table tbody tr.rowstatus7 td {
  background-color: #f7fff9;
  color: #1a4328;
}
.form-sidebar-beta.corporate.card {
  padding: 1rem 0;
  width: 70vw;
}
@media only screen and (max-width: 900px) {
  .form-sidebar-beta.corporate.card {
    width: 90vw;
  }
}
@media only screen and (max-width: 400px) {
  .form-sidebar-beta.corporate.card {
    width: 95vw;
  }
}
.form-sidebar-beta form.corporate {
  background: #ffffff;
  padding: .5rem 0rem;
}
.form-sidebar-beta form.corporate .btn.btn-info.download {
  margin-left: 1rem;
}
.form-sidebar-beta form.corporate .react-tel-input {
  display: flex;
}
.form-sidebar-beta form.corporate .react-tel-input .flag-dropdown {
  position: relative;
  order: 0;
  border-right: none;
}
.form-sidebar-beta form.corporate .react-tel-input .form-control {
  order: 1;
  width: 100%;
  border-radius: 0 5px 5px 0;
}
.form-control.is-valid, .was-validated .form-control:valid {
  border-color: #28a745!important;
}
.data-readonly.card-body > .col-xl-12 {
  padding-right: 0px;
  padding-left: 0px;
}
.data-readonly.card-body .card .card-body {
  padding: 1rem 1rem;
}
.data-readonly.card-body .card .card-body .form-group {
  margin: 1rem 0.5rem;
}
.data-readonly.card-body .card .card-body .form-group .form-label  {
  margin-bottom: .5em;
  text-transform: uppercase;
  font-weight: 500;
}
.data-readonly.card-body .card .card-body .form-group .form-control {
  background-color: white;
  cursor: default;
  border: none;
  color: #0f1731;
  font-size: 1.7rem;
  font-weight: 600;
  padding: 0.3em 0em;
  outline: none;
  box-shadow: none;
}
.data-readonly.card-body .card .card-body .form-group a.form-control.btn-success {
  background-color: #48d167;
  border-color: #48d167;
  color: #fff;
  cursor: pointer;
}
.data-readonly.card-body .card .card-body .form-group a.form-control.btn-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
}
.data-readonly.card-body .card .card-body .form-group a.form-control.btn-success:focus {
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72,180,97,.5);
}
.data-readonly.card-body .card .card-body .form-group .form-control.no-aplica {
  background-color: black;
}
.container-check-input {
  display: flex;
}
.container-check-input .left-check {
  display: block;
}
.container-check-input .left-check input {
  position: relative!important;
  margin-top: 1rem;
  margin-right: 1rem;
}
.container-check-input .left-check input[type=checkbox]:disabled {
  background: #949494;
  background-color: #949494;
  opacity: 1;
  color: #949494;
}
.card.group-form {
  margin-bottom: 1.5rem;
}
.card.group-form .card-header {
  padding: 1.5rem 1.25rem;
  font-size: 1.7rem;
  font-weight: 600;
}
.form-sidebar-beta form.corporate .alert {
  font-size: 1.1rem;
}
.changelog thead th {
  vertical-align: middle;
  text-transform: uppercase;
}
.changelog thead th.created_at {
  width: 15rem;
}
.changelog tbody td {
  vertical-align: middle;
}
#popover-basic {
  background: white;
}
#popover-basic .popover-header svg.fa-check-circle {
  color: rgb(46, 173, 46);
  font-size: 1.9rem;
}
#popover-basic .popover-header svg.fa-exclamation-triangle {
  color: rgb(214, 73, 68);
  font-size: 1.9rem;
}
#popover-basic .popover-header > b {
  vertical-align: text-top;
}
#popover-basic .popover-header {
  font-size: 1.3rem;
  text-transform: uppercase;
}
#popover-basic .popover-body {
  padding: 1rem .75rem;
}
#popover-basic .popover-body b {
  color: rgb(59, 59, 59);
}
#popover-basic .popover-body b, #popover-basic .popover-body span {
  font-size: 1.3rem;
}
#popover-basic .popover-body hr {
  margin-top: .8rem;
  margin-bottom: .5rem;
}
.body-limits {
  margin: 0 0 6rem 0;
}
#form-admin-users .row > div > div {
  margin: 0em 0 0.5em;
}
.admin-users-page.card {
  border-radius: 2rem;
  border: none;
}
.admin-users-page.card .card-footer {
  border: 2px solid rgb(217 217 217);
  border-radius: 0 0 1.8rem 1.8rem;
  border-top: none;
  background: rgb(234 232 232);
}
.admin-users-page table.table tbody tr td {
  vertical-align: middle;
}
.admin-users-page .detail-row {
  margin-top: 1rem;
}
.admin-users-page .detail-row b {
  color: #9e9e9e;
  font-weight: 500;
  font-size: 1.3rem;
  display: block;
  padding-bottom: 0.5rem;
}
.admin-users-page .detail-row .value-detail {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
}
.admin-users-page .detail-row .subrow.row {
  background-color: #f8e5d1;
  margin: 0;
  padding: 1rem 0;
  border-radius: 1rem;
}
.admin-users-page .detail-row .subrow.row.negative {
  background-color: #f8e5d1;
}
.admin-users-page .detail-row .subrow.row.affirmative {
  background-color: #d1f8dd;
}
.admin-users-page .detail-row .subrow.row.negative b {
  color: #d96228;
}
.admin-users-page .detail-row .subrow.row.affirmative b {
  color: #549c7e;
}
.footer-card.card-footer {
  padding: 1.55rem 1.25rem;
}
.footer-card.card-footer > .btn {
  padding: 1.075rem 1.75rem;
  font-size: 1.2rem;
}
.topbar-actions {
  background: white;
  padding: 1.2rem 2rem;
  border-bottom: 1px solid #c7c7c7;
  color: #878B98;
  position: sticky;
  top: -22px;
  z-index: 3;
}
.topbar-actions .btn {
  border-radius: 2rem;
}
.topbar-actions .btn span.levelmax {
  margin: 0.3rem auto;
}
.topbar-actions .sidebar-button-call {
  background: white;
  border: none;
  color: #6c6c6c;
}
.topbar-actions .sidebar-button-call.btn svg {
  font-size: 2.5rem;
}
.topbar-actions .subinfo > div, .topbar-actions .direct-actions > div {
  border-right: 2px solid #E7E8EA;
}
.topbar-actions .subinfo > div:last-child, .topbar-actions .direct-actions > div:last-child {
  border-right: none;
}
.topbar-actions .subinfo b {
  margin-right: 1rem;
  color: #575C6F;
  font-weight: 600;
  font-size: 1.2rem;
}
.topbar-actions .subinfo p {
  display: inline-block;
  margin: 0;
  font-weight: 500;
}
.topbar-actions .container-bar {
  height: .5rem;
  width: 100%;
  border-radius: .2rem;
  background: #E9EAEC;
  display: inline-table;
  vertical-align: middle;
  margin: auto 0;
}
.topbar-actions .container-bar .progress-bar {
  background: #f28e2b;
  height: .5rem;
  border-radius: .2rem;
  box-shadow: -1px 0px 3px 0px #f28e2b;
  width: 0%;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}
.topbar-actions .container-bar .progress-bar:hover {
  box-shadow: -1px 0px 6px 0px #f28e2b;
}
.topbar-actions .container-bar .progress-bar.level1 {
  width: 25%;
}
.topbar-actions .container-bar .progress-bar.level2 {
  width: 50%;
}
.topbar-actions .container-bar .progress-bar.level3 {
  width: 75%;
}
.topbar-actions .container-bar .progress-bar.level4 {
  width: 100%;
}
.topbar-actions .vertical-middle {
  vertical-align: middle;
  position: relative;
}
.topbar-actions .vertical-middle .row > .col-xl-1 {
  padding: 0;
}
.topbar-actions .direct-actions .vertical-middle > .row > div {
  padding-right: 2px;
  padding-left: 2px;
}
.topbar-actions .direct-actions .vertical-middle > .row > div .btn {
  width: 100%;
  margin: 0;
}
.topbar-actions .direct-actions .vertical-middle > .row > div .btn svg[data-icon="user-edit"] {
  margin: 0 .5rem 0 auto;
}
.topbar-actions .direct-actions .vertical-middle > .row > div .btn svg {
  font-size: 1.5rem;
  margin: 0 auto;
}
.topbar-actions .direct-actions .vertical-middle > .row > div .btn svg span {
  font-size: 1.5rem;
  margin: 0 auto;
}
.topbar-actions .vertical-middle > .row {
  margin: 1.5rem 0;
}
.topbar-actions .vertical-middle .level-blob {
  text-align: center;
  border-radius: 50%;
  padding: .5rem 1rem;
  margin: 0 0px 0 0;
  background: #f0f0f0;
  color: #898989;
  font-weight: 700;
  cursor: default;
}
.topbar-actions .vertical-middle .level-blob:hover {
  background: #acacac;
  color: white;
}
.topbar-actions .direct-actions .subinfo div .row > div {
  position: relative;
  display: block;
  vertical-align: middle;
  padding: 0 15px;
}
.topbar-actions .direct-actions .subinfo div .row > div.col-xl-1 {
  padding: 0;
}
.topbar-actions .direct-actions .subinfo div .row > div .line-row {
  vertical-align: middle;
  display: inline-table;
  margin: auto 0;
}
.topbar-actions .direct-actions .subinfo div .row > div .line-row.one {
  display: block;
}
.topbar-actions .direct-actions .subinfo div .row > div .line-row.one > b, .topbar-actions .direct-actions .subinfo div .row > div .line-row.one > p {
  display: block;
  width: 100%;
}
.form-sidebar-beta {
  padding: 1rem 1rem;
}
.form-sidebar-beta .title-beta-box h3 {
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 3rem;
}
.form-sidebar-beta .title-beta-box h3 b {
  font-weight: 500;
}
.form-sidebar-beta form {
  background: #f8f8f8;
  padding: .5rem 2rem;
  border-radius: .5rem;
}
.form-sidebar-beta form .row {
  margin-top: 1rem;
}
.form-sidebar-beta form .row > div {
  padding-right: 10px;
  padding-left: 0;
}
.display-noflex {
  width: 100%;
}
.form-sidebar-beta form .row > div.group-container {
  padding: 1.5rem;
  margin: 1rem 1.5rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0 2px 1px rgb(92 92 92 / 33%);
}
.form-sidebar-beta form .row > div.group-container h2 {
  font-size: 1.7rem;
  font-weight: 600;
  padding-top: 0.5rem;
}
.form-sidebar-beta form .form-group {
  margin: 0;
  padding: 1.5rem 1.5rem 1rem;
}
.form-sidebar-beta form .form-group input[type=number], .form-sidebar-beta form .form-group input[type=text], .form-sidebar-beta form .form-group input[type=email], .form-sidebar-beta form .form-group input[type=tel], .form-sidebar-beta form .form-group input[name=birthday] {
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 0 0 #d1d1d1;
}
.form-sidebar-beta form .form-group input:focus, .form-sidebar-beta form .form-group select:focus, .form-sidebar-beta form .react-tel-input .form-group input:focus {
  border: 1px solid #ffac56!important;
  box-shadow: 0px 1px 0 0 #ffac56!important;
}
.form-sidebar-beta form .form-group .form-label {
  font-weight: 500;
  font-size: 1.4rem;
  color: #4e4e4e;
  line-height: 1.3;
}
.form-sidebar-beta form .form-group select, .form-sidebar-beta form .form-group input[type=tel] {
  padding: 0.8em 1em;
  height: 4.1rem;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 0 0 #d1d1d1;
}
.form-sidebar-beta form .submitbeta {
  margin: 2rem 0;
  font-size: 1.5rem;
}
.form-sidebar-beta form .submitbeta span {
  padding-left: 1rem;
}

.form-sidebar-beta form .link-document {
  margin: 0rem 0;
  font-size: 1em;
  width: 100%;
}
.form-sidebar-beta form .link-document span {
  padding-left: 1rem;
}

.form-container-file-input {
  text-align: left;
  padding: 1em;
}

.form-container-file-input input {
  margin-bottom: 1em;
}


.LevelBlock {
  display: flex;
  position: relative;
  margin: 0 -1.5rem 2.5rem;
}
.LevelBlock .slick-track {
  display: flex;
}
.LevelBlock .slick-initialized .slick-slide {
  margin: 0 1rem;
  display: inline-flex;
}
.LevelBlock .LevelForSingle {
  width: 25%;
  height: 20rem;
  padding: 20px 20px 20px 30px;
  margin: 0 .5rem;
  border-radius: 1rem;
  -webkit-box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.17);
  box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.17);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 85%;
  -webkit-transition: box-shadow 1s;
  transition: box-shadow .5s;
}
.LevelBlock .LevelForSingle:hover {
  -webkit-box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.20);
  box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.20);
}
.LevelBlock .LevelForSingle .levelHeader {
  display: flex;
}
.LevelBlock .LevelForSingle .levelHeader .verifiedMed {
  width: 3rem;
  height: 3rem;
  background-image: url(/local/landing-page/images/levels-bars/correct.svg);
  background-repeat: no-repeat;
  margin-top: -.5rem;
}

.LevelBlock .LevelForSingle .levelHeader .levelTitle {
  font-weight: 600;
  font-size: 1.9rem;
  width: 100%;
}
.LevelBlock .LevelForSingle .levelContent {
  font-size: 1.2rem;
  padding: .5rem 0 1.5rem;
  width: 63%;
  height: 11rem;
  line-height: 1.4;
}
.LevelBlock .LevelForSingle .VerifiedStatus, .settings-page__content .otherlevels .VerifiedStatus {
  border: 1.5px solid #7BC13D;
  padding: .5rem 2rem;
  border-radius: .5rem;
  color: #7BC13D;
  font-size: 1.2rem;
  font-weight: 500;
}
.LevelBlock .LevelForSingle .MustVerifiedStatus, .settings-page__content .otherlevels .MustVerifiedStatus {
  border: 1.5px solid rgb(173, 173, 173);
  padding: .5rem 2rem;
  border-radius: .5rem;
  color: rgb(173, 173, 173);
  font-size: 1.2rem;
  font-weight: 500;
}
.LevelBlock .LevelForSingle .PendingVerifiedStatus, .settings-page__content .otherlevels .PendingVerifiedStatus {
  border: 1.5px solid #7BC13D;
  background-color: #7BC13D;
  padding: .5rem 2rem;
  border-radius: .5rem;
  color: #FFFFFF;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
}
.LevelBlock .LevelForSingle .PendingVerifiedStatus:hover {
  opacity: 0.8;
}


.TourInfoBox p {
  font-size: 1.5rem;
}


/* header user */
.popover {
  z-index: 99!important;
  background: transparent;
}
.page-header__right-panel .popover {
  border: none;
}
.user-summary__popover-menu-trigger:hover {
  background: rgba(250, 250, 250, 0.151);
}
.user-summary__popover-menu-trigger:focus {
  background: rgba(250, 250, 250, 0.151);
}
.sub-patrimonio {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-right: 2rem;
  align-content: center;
}
.sub-patrimonio .money-crypt {
  margin-top: 0rem;
}
.sub-patrimonio .mon {
  display: block;
  font-weight: 600;
  font-size: 1.2rem;
  border-left: 2px solid #FF8F15;
  padding-left: 0.5rem;
}
.sub-patrimonio .mon .money-amount {
  font-weight: 500;
}

.toggle-bar {
  border-left: 2px solid rgb(76, 154, 255);
  opacity: 0;
  height: calc(100% + 7.2rem);
  right: -10px;
  top: -7.2rem;
  position: absolute;
  width: 10px;
  z-index: 9;
  -webkit-transition: opacity 200ms ease 0s;
  -o-transition: opacity 200ms ease 0s;
  transition: opacity 200ms ease 0s;
}
.toggle-bar:hover {
  opacity: 1;
  cursor: ew-resize;
}
.instrument-selector__trigger {
  border: none;
}
.instrument-selector__trigger:hover, .instrument-selector__trigger:focus {
  background: #ebebeb;
}
.instrument-row .popover {
  border: none;
}
.instrument-selector__trigger {
  color: #FE9015;
}
.trading-layout__body {
  border-top: none!important;
}
.order-entry__container {
  border-left: none;
}
.trading-layout__chart-container {
  padding: 10px;
  margin: 10px 5px 10px 10px;
  border: 2px solid #F1F1F1;
  overflow-y: auto;
}
.instrument-selector__symbol {
  margin-right: 5px;
}
.flex-table__title, .orderbook__spread-row {
  background: transparent;
  border-bottom: none;
  border-top: none;
  font-size: 1.6rem;
  font-weight: 500;
}
.bulk-cancel-buttons {
  background: transparent;
  border-top: 2px solid #ededed;
}
.bulk-cancel-buttons__label {color: rgb(92, 92, 92);}
.trade-component__section {
  border-top: none;
}
.order-book__container {
  border-left: none;
}
.orderbook__order-book-body {
  background: white;
}
.ap-inline-btn__btn {
  color: rgb(59, 59, 59);
  background: transparent;
}
.flex-table__body {
  /* border-left: 2px solid #F1F1F1; */
  margin: 0 2%;
  width: 96%;
}
.ap-inline-btn__btn:hover {
  background: #e5e5e5;
}
.recent-trade__container {
  border-left: none;
  width: 100%;
}
.dashboard-page__accent-bar, .eotc-buy-sell-layout__accent-bar, .wallets-page__accent-bar {
  height: 21.5rem;
  margin-bottom: -21.5rem;
  background: transparent!important;
  width: 100%;
  flex-shrink: 0;
}
.order-entry__buy-sell-radio-tab-wrapper .ap-radio-tab__label--additive {
  background: rgb(63, 221, 126);
  color: white;
  font-weight: bold;
  border-radius: 0.5rem!important;
  padding: 11px 34px;
  height: 35px;
  margin: 5px 2%!important;
  border: none!important;
  width: 40%;
}

.order-entry__buy-sell-radio-tab-wrapper .ap-radio-tab__label--subtractive {
  background: rgb(234, 0, 112);
  color: white;
  font-weight: bold;
  border-radius: 0.5rem!important;
  padding: 11px 34px;
  height: 35px;
  margin: 5px 2%!important;
  border: none!important;
  width: 40%;
}

.ap-tab__menu-item.trading-layout__menu-item {
  border-radius: 0.5rem 0.5rem 0 0;
  background: #f3f3f3;
  margin-right: 0.5rem;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
}
.trade-component {
  background: transparent;
}
.order-entry__buy-sell-wrapper {
  border-bottom: none;
}
.ap--label {
  color: rgb(24, 24, 24)!important;
}

.container-all {
  overflow: auto;
  width: 35%;
  margin: 10px 5px;
  border: 2px solid #F1F1F1;
}


@media (min-width: 1024px) {
  div.recent-trade__container {
    height: 33%;
  }
  div.order-book__container {
    height: 67%;
  }
}
@media (min-width: 1024px) {
  .order-book__container {
    width: 100%;
  }
}

.login-form__input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.signup-form__container .form-group:first-child .signup-form__error {
  /* height: 13px; */
  height: auto;
}
.react-datepicker-component .react-datepicker-input {
  height: 44px;
}
.react-datepicker-component .react-datepicker-input, .react-datepicker-component .react-datepicker-input:hover,
.react-datepicker-component .react-datepicker-input:focus {
  border: none!important;
  box-shadow: none;
}
.manual-deposit-workflow-component .form-control:focus {
  box-shadow: none;
}
.react-datepicker-component .react-datepicker-input input, .manual-deposit-workflow-component .form-control[type=text] {
  border-bottom: 2px solid #eaeaea;
  background: white;
  font-size: 1.2rem;
  border-radius: 0;
}
.react-datepicker-component .react-datepicker-input input:hover, .react-datepicker-component .react-datepicker-input input:focus, .manual-deposit-workflow-component .form-control[type=text]:focus, .manual-deposit-workflow-component .form-control[type=text]:hover {
  border-bottom: 2px solid #FF9013;
}
.ap-padded-decimal {
  text-align: left;
}
.instrument-table {
  flex-basis: 100%;
}
.order-entry__input-wrapper .ap-input__input-box .label-in-input {
  background: white!important;
  font-weight: 600;
  color: #4b4b4b!important;
  margin: 0;
  height: 3.8rem;
  line-height: 4;
}
.order-entry__input-wrapper .ap-input__input-box .form-control.ap-input__with-label {
  border-width: 0px 0px 2px 0;
  border-color: #eaeaea;
  padding-left: 4.25rem;
}
.order-entry__input-wrapper .ap-input__input-box .form-control.ap-input__with-label:hover, .order-entry__input-wrapper .ap-input__input-box .form-control.ap-input__with-label:focus {
  background: transparent;
  border-color: #FF9013;
  outline: none;
  box-shadow: none;
}
.order-entry__sb-container, .order-entry__input-wrapper, .order-entry__select-wrapper {
  padding: 0.5rem 0;
}
.ap-select__select.order-entry__select {
  width: 96%;
  padding: 0rem 1rem;
  height: 3rem;
}
.ap-select__select.order-entry__select:hover {
  background: #f8f8f8;
  border: 1px solid orange;
}
.ap-select__select.order-entry__select option {
  background: white;
}
.order-entry__select-wrapper .ap-select__triangles-container {
  bottom: 1rem;
}
.trade-component__section .ap-button__btn {
  background: #ffffff!important;
  border: 1.5px solid #FF9013!important;
  color: #FF9013;
  font-weight: 600;
}
.trade-component__section .ap-button__btn:disabled {
  opacity: 0.5;
}
.order-history__tab {
  background: transparent;
  border-top: none;
}
.ap-tab__menu-item {
  color: var(--tab__font-color--selected);
  font-weight: 500;
  border-right: none;
}
.order-history-table__header-column {
  border-left: none;
}
.slide-pane__header, .advanced-order-sidepane__tab-container {
  background: white;
}
.slide-pane__header .slide-pane__title {
  font-size: 2.5rem;
}
.advanced-order-sidepane__tab {
  color: var(--component__color-primary);
}
.advanced-order-sidepane__tab:hover {
  color: rgb(255, 136, 0);
}
.advanced-order-form__limit-price-block {
  background: #eaeaea;
}
.advanced-order-form__limit-price-block-label {
  color: black;
}
.ap-input__input-box .advanced-order-form__input {
  border-bottom: 2px solid rgb(227, 227, 227);
}
.ap-input__input-box .label-in-input {
  background: transparent;
  color: black;
}
.ap-input__input-box .advanced-order-form__input:hover {
  border-bottom: 2px solid #FF9013;
  background: transparent;
}
.ap-inline-btn__btn:disabled, .ap-inline-btn__btn:disabled:hover {
  color: rgba(39, 39, 39, 0.82);
}
.order-entry__body {
  height: 100%;
}
.order-entry__body .trade-component__section .order-entry__item-button {
  color: #FF9013;
}
.ap-segmented-button {
  background: white;
}
.ap-segmented-button__label {
  color: #535353;
}
.order-entry__order-type-wrapper .ap--label {
  font-weight: 600;
}
.ap-segmented-button__label:hover, .ap-segmented-button__label--checked-additive {
  background: white;
  border-bottom: 2px solid #FF9013;
}

.standalone-modal__wrapper {
  background: white url(/local/landing-page/images/manya/landing/blank_dots.png);
  background-color: white;
  background-position: bottom right;
  background-repeat: no-repeat;
}
.standalone-modal__container {
  width: 70rem;
  border-radius: 1rem;
  overflow: hidden;
}
.standalone-modal__body {
  color: #F28E2A;
  background: white;
}
.standalone-modal__body .standalone-modal__body-text {
  color: #F28E2A;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
}
.standalone-modal__container .standalone-modal__footer {
  border-top: none;
  text-align: center;
  background: white;
  margin: 0;
}
.standalone-modal__container .standalone-modal__footer .standalone-modal__btn {
  margin: 0 auto;
  border-radius: 0.7rem;
  font-size: 1.7rem;
  padding: 0.5rem 1rem;
}
.standalone-form__form .login-form__btn--additive {
  border: 1.5px solid #F28E2A!important;
  background: white;
  color: #F28E2A;
  font-weight: 500;
}
.standalone-form__form .login-form__btn--additive:hover {
  background: #F28E2A;
  color: white;
}
.wallet-card__icon-label-row {
  position: relative;
  padding-bottom: 2rem;
}
.wallet-card__top-border {
  top: 0;
  width: 65%;
  display: block;
  position: absolute;
}
.wallet-card__symbol-label {
  font-size: 1rem;
  font-weight: 600;
  margin-left: 1rem;
  line-height: 2;
  font-size: 1.2rem;
}
.wallet-card {
  background: white;
  -webkit-box-shadow: 2px 2px 4px 1px rgba(89, 89, 89, 0.11);
  box-shadow: 2px 2px 4px 1px rgba(89, 89, 89, 0.11);
}
.wallet-card__activity-link-row {
  background: white;
}
.wallet-card__label {
  color: black;
  font-weight: 500;
}
.wallet-card__amount-text {
  font-weight: 600;
}
.wallet-card__balance {
  border-right: solid 1px #a5a1a1;
}
.wallet-card__activity-link {
  color: #FF9013;
}
.wallet-card__symbol-label-single {
  float: right;
  padding: 0.1rem 1rem;
  font-weight: 500;
  font-size: 1.5rem;
}
.wallet-card__icon {
  margin-left: 1.2rem!important;
}
.wallet-card__icon-nav-buttons .wallet-nav-item {
  background: white;
  border: none;
}
.wallet-card {
  width: 26.2rem;
  height: 19.8rem;
  border-radius: 0.4rem;
  margin: 0 1rem 4rem 1rem;
}

.wallet-card__icon-nav-buttons .icon-button__icon {
  color: #555555;
}

.wallets-page__wallet-list-container-header {
  padding: 3rem 0rem 3.6rem;
  font-weight: 500;
}
.wallet-row {
  background: white;
  border-bottom: none;
}
.wallet-row:hover {
  background: rgb(240, 240, 240);
}
.wallet-row__activity-link {
  border-left: none;
}
.wallet-row-list {
  border-radius: 0.4rem;
  overflow: visible;
  max-height: 100%;
}
.wallet-row__icon-nav-buttons > .wallet-nav-item {
  background: transparent;
  height: 2.5rem;
  border: none;
}
.wallet-row__icon-nav-buttons > .wallet-nav-item .icon-button__icon {
  color: #555555;
}
a.wallet-row__activity-link {
  color: #FF9013;
}
a.wallet-row__activity-link:hover {
  opacity: 0.7;
}
.ap-popover__content::after {
  border-bottom: 0.8rem solid #e8e8e8;
}
.ap-popover__content > div {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.popover-menu__item {
  border-bottom: none;
  background: #626262;
}
.popover-menu__item:hover, .popover-menu__item:active, .popover-menu__item:focus {
  background: rgb(241, 241, 241);
}
.wallet-row-list .wallet-row {
  min-height: 5rem;
}

.wallets-page__wallet-list-container {
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: visible;
  border: 2px solid #efefef;
  border-radius: 0;
}

.page-footer {
  background-color: white;
  margin-bottom: 0rem;
  border-top: none!important;
  z-index: 0;
  position: relative;
  min-height: 6.3rem;
  padding: 1.4rem 4.4rem 0 4.4rem;
  color: rgb(58, 58, 58);
}
.page-footer__footer-left-content {
  width: 50%;
  font-size: 1.2rem;
  background-image: url(/local/landing-page/images/isotipo.png);
  background-size: 23px;
  background-repeat: no-repeat;
  padding: 4px 0 0 28px;
}
.page-footer__footer-right-content {
  text-align: right;
}
.page-footer__footer-nav {
  width: 100%;
  align-items: flex-end;
}
.footer-nav-single-link {
  width: 100%;
  margin-left: auto;
  border: 0px solid #d1d0d0;
  padding: 5px 10px;
  text-transform: uppercase;
  border-radius: 0.5rem;
  text-align: center;
  color: #666666;
  font-weight: 500;
  cursor: pointer;
}
.footer-nav-single-link:hover {
  color: #fc911a;
  background: rgb(238, 237, 237);
}
.footer-nav-container {
  width: 8rem;
  display: inline-flex;
}
.footer-nav-container:hover .footer-nav-cloud {
  display: block;
}
.footer-nav-single-link .triangle-more {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid;
  display: inline-block;
  margin-left: 1rem;
  vertical-align: middle;
}
.footer-nav-cloud {
  display: none;
  position: absolute;
  right: 4.5rem;
  bottom: 4.8rem;
  width: 14.9rem;
  padding-bottom: 1rem;
}
.footer-nav-cloud-children {
  padding: 1rem 0;
  box-shadow: 0 0 2px 0 rgba(79, 79, 79, 0.54);
  border: 0px solid #cacaca;
  border-radius: 0.5rem;
  background: white;
}
.footer-nav-cloud ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-nav-cloud ul > li > a {
  display: block;
  text-align: left;
  color: #787878;
  padding: 0.5rem 0.8rem;
  font-weight: 600;
}
.footer-nav-cloud ul > li > a:hover {
  background: rgb(245, 244, 244);
}
.page-footer {
  margin-top: 6rem;
}

.settings-page__container {
  display: block;
  -ms-flex: none;
  flex: none;
  height: auto;
  padding-bottom: 8rem;
  width: 95%;
  max-width: 1200px;
  margin: 4rem auto 0;
}
.settings-page__content {
  display: block;
  height: 100%;
}
.settings-page__menu {
  display: block;
  border: none;
}
.nav-menu-vertical {
  margin: var(--settings__body-margin);
  padding: 0 50px;
}
.nav-menu-vertical__list {
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}
.nav-menu-vertical__list li, .nav-menu-vertical__list li:last-child {
  border-bottom: none;
}
.nav-menu-vertical__item {
  border-left: none;
  border-bottom: 2px solid transparent;
  color: rgb(61, 61, 61);
}
.nav-menu-vertical__item.active, .nav-menu-vertical__item.active:hover, .nav-menu-vertical__item:hover {
  background: white;
  border-left: none;
  border-bottom: 2px solid #FF9013;
  color: black;
}
.user-settings-page__page-header:before, .kyc-settings__header:before {
  background-image: none;
}
.user-settings-page__title, .user-settings-page__sub-title, .kyc-settings__header-text, .kyc-settings__description {
  color: black;
}
.user-settings-page__page-header h1, .kyc-settings__header-text {
  font-weight: 600;
}
.profile-and-security-widget__layout-template {
  box-shadow: none;
  -webkit-box-shadow: none;
  margin-bottom: 1rem;
  margin-right: 1rem;
}
.profile-and-security-widget__layout-template:first-of-type {
  margin-right: 1rem;
}
.profile-and-security-widget__body--sm {
  height: 15rem;
}
.profile-and-security-widget__header {
  border-bottom: none;
}
.profile-and-security-widget__header .profile-and-security-widget__header-text {
  font-weight: 600;
}
.reset-password-form__text-info, .settings-enable-two-factor-auth__info {
  color: black;
}
.ap-switch__switch {
  border: none;
  background: #EBEBEB;
  height: 26px;
  width: 80px;
}
.settings-enable-two-factor-auth {
  padding: 0.4rem 0 0 1.4rem;
}
.settings-enable-two-factor-auth .ap-switch__text {
  color: black;
  font-weight: 500;
  font-size: 1.3rem;
}
.settings-enable-two-factor-auth .ap-switch__toggle {
  background-color: #9d9d9d;
  margin: 0 2px 0 0;
}
.settings-enable-two-factor-auth .ap-switch__toggle--on {
  background-color: #F6961F;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0 0 10px 2px #F6961F;
  box-shadow: 0 0 10px 2px #F6961F;
}
.settings-enable-two-factor-auth .ap-switch__toggle--on::before {
  content: '';
  width: 80%;
  height: 80%;
  background-color: #ffaf4b;
  position: absolute;
  border-radius: 4rem;
  top: -2px;
  left: -2px;
}
.settings-enable-two-factor-auth__btn {
  border: 2px solid #F6961F!important;
  color: #F6961F;
  height: 3.4rem;
}
.settings-enable-two-factor-auth__btn:hover {
  background: #F6961F;
  color: white;
}

.user-settings-page__container .ap-button__btn {
  background: white;
  border: 1.5px solid #F6961F!important;
  color: #F6961F;
  font-weight: 500;
  border-radius: 0.5rem;
}
.user-settings-page__container .ap-button__btn:hover {
  background: #F6961F;
  color: white;
}
.user-settings-page__container .ap-label-with-text__text {
  border-bottom: 2px solid #ededed;
}
.user-profile-form-static__lwt-label, .region-settings__lwt-label, .theme-settings__lwt-label {
  font-size: 1.2rem;
  padding: 0rem 0;
}
.profile-and-security-widget__header {
  font-weight: 600;
}
.enable-2fa-modal__text-item, .disable-2fa-modal__text-item, .ap-auto-tab-input__label {
  color: black;
}
.enable-2fa-modal .ap-auto-tab-input {
  border: 1px solid rgba(0, 0, 0, 0.33);
}
.forgot-password-form {
  color: black;
}
.ap-auto-tab-input {
 background: white;
 border: 1px solid rgb(197, 197, 197);
}
.ap-radio__label {
  width: auto;
  height: auto;
}
.buy-sell__amount-row .ap-radio .ap-radio__label {
  width: 22px;
  height: 22px;
}
.standalone-form__separator {
  border-top: none;
}
.ap-modal {
  border-radius: 1rem;
}
.ap-modal__header, .ap-modal__content {
  background: white;
}
.ap-modal__header {
  font-weight: 600;
  font-size: 1.7rem;
}
.ap-modal .ap-button__btn, .forgot-password-form__btn, .custom__btn.orange_custom {
  background: white;
  border: 2px solid #F6961F!important;
  color: #F6961F;
  font-weight: 500;
  border-radius: 0.5rem;
}
.forgot-password-form__btn:disabled, .custom__btn.orange_custom:disabled {
  opacity: 0.5;
  color: #F6961F;
}
.ap-modal .ap-button__btn:hover, .forgot-password-form__btn:hover, .custom__btn.orange_custom:hover, .custom__btn.orange_custom:focus, .custom__btn.orange_custom:not(.disabled):active {
  background: #F6961F;
  color: white;
}
.standalone-form__footer .login-form__link {
  color: #F6961F;
}
.ap-select__select option {
  background: white;
}
.ap-modal__close-icon, .ap-icon--close {
  color: black;
}
.ap-modal__close-icon {
  padding: 2.75rem 1.75rem;
}
.ap-input__input:hover, .ap-select__select:hover, .ap-datepicker__input:hover, .react-phone-number-input__input.ap-phone-input__input:hover {
  background: rgb(238, 238, 238);
}
.ap-label-with-text, .user-profile-form-static__lwt-text, .region-settings__lwt-text, .theme-settings__lwt-text, .reset-password-form__text-info, .settings-enable-two-factor-auth__info {
  font-size: 1.2rem;
}
.ap-radio input[type='radio']:checked ~ label > .ap-radio__check {
  background: #ED8300!important;
}
.kyc-level:first-child {
  display: none;
}
.kyc-level .ap-icon.ap-icon--star {
  display: none;
}
.kyc-level, .kyc-level--selected {
  height: min-content!important;
}
.settings-page__content .kyc-level {
  border-left: 5px solid #00A79D;
  background-image: url(/local/landing-page/images/levels-bars/basico.svg);
  background-color: white;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 85%;
}
.kyc-level__header-title {
  background: transparent;
  color: #00A79D;
  font-weight: 600;
  font-size: 1.9rem;
}
.kyc-level__body {
  background: transparent;
  border: none;
}
.kyc-level__sub-title {
  display: none;
}
.kyc-level__list {
  list-style: none;
  padding: 0;
}
.kyc-level__item-description, .kyc-level__list-item {
  color: black;
}
.kyc-level__item-description {
  white-space: pre-line;
  background: white;
  padding: 0px 22px;
  border-radius: 0 0 0.5rem 0.5rem;
  border: 0 solid #d1d1d1;
  border-top: none;
  color: #727272;
  overflow-y: auto;
  -webkit-transition: height 0s, opacity 0.5s linear;
  -o-transition: height 0s, opacity 0.5s linear;
  transition: height 0s, opacity 0.5s linear;
  height: 0;
}
.kyc-level-banexcoin-old.active .kyc-level__item-description, .kyc-level.otherlevels.active .kyc-level__item-description {
  padding: 10px 22px;
  height: auto;
}
.kyc-level__footer--verified {
  background: transparent;
  color: #494949!important;
  border-radius: 0 0 4px 4px!important;
  padding: 2rem;
}
.kyc-settings.kyc-settings--star .kyc-settings__header {
  display: none;
}
.kyc-settings.kyc-settings--star {
  padding: 0 0rem 0 3rem;
}
.kyc-settings__header.newheader-block {
  width: 100%;
}

.kyc-level .kyc-level__change__btn {
  border: 1.5px solid #7BC13D!important;
  background-color: #7BC13D;
  padding: 0rem 2rem;
  border-radius: .5rem;
  color: #FFFFFF;
  width: fit-content;
  height: auto;
  display: block;
  margin-top: 11px;
  font-size: 1rem;
  font-weight: 500;
}
.kyc-level .kyc-level__change__btn:hover {
  opacity: 0.8;
}
.kyc-level, .kyc-level--selected {
  margin-bottom: 0rem;
}
/* Para nivel inicial */
.initialLevel {
  border-left: 5px solid #00A79D;
  background-image: url('/local/landing-page/images/levels-bars/basico.svg');
}
.initialLevel .levelHeader .levelTitle {
  color: #00A79D;
}

/* Para nivel intermedio */
.intermediateLevel, .kyc-level.intermediateLevel {
  border-left: 5px solid #B997D4;
  background-image: url('/local/landing-page/images/levels-bars/intermedio.svg');
}
.intermediateLevel .levelHeader .levelTitle, .kyc-level.intermediateLevel .kyc-level__header-title {
  color: #B997D4;
}

/* Para nivel maestro */
.masterLevel, .kyc-level.masterLevel {
  border-left: 5px solid #FF8F15;
  background-image: url('/local/landing-page/images/levels-bars/maestro.svg');
}
.masterLevel .levelHeader .levelTitle, .kyc-level.masterLevel .kyc-level__header-title {
  color: #FF8F15;
}

/* Para nivel corporate */
.corporateLevel, .kyc-level.corporateLevel {
  border-left: 5px solid #353A36;
  background-image: url('/local/landing-page/images/levels-bars/corporativo.svg');
}
.corporateLevel .levelHeader .levelTitle, .kyc-level.corporateLevel .kyc-level__header-title {
  color: #353A36;
}

button.more-btn {
  width: stretch;
  margin: 0 5rem;
  border: 2px solid #e9e9e9;
  padding: 1rem .5rem;
  border-radius: .5rem;
  color: #999999;
  font-weight: 600;
  margin-top: -4rem;
}
button.more-btn:hover {
  border: 2px solid #FF8F15;
  color: #FF8F15;
  opacity: 0.5;
}
.more-btn.normal {
  position: absolute;
  bottom: -5.5rem;
}
.error-accessing-message {
  text-align: center;
}
.error-accessing-message img {
  width: 10rem;
}
.error-accessing-message .text {
  font-size: 2.5rem;
  font-weight: 500;
  color: rgb(58, 58, 58);
}
/* FORMS */
.intermediatelevel-page .form-group label.form-label, .easybuy-page .form-group label.form-label {
  font-weight: 600;
}
.intermediatelevel-page .btn.btn-primary, .easybuy-page .btn.btn-primary, .account-form .btn.btn-primary {
  background: white;
  border: 1.5px solid #F6961F!important;
  color: #F6961F;
  font-weight: 500;
  border-radius: 0.5rem;
  min-width: 20rem;
  font-size: 1.5rem;
}
.intermediatelevel-page .btn.btn-primary:hover, .easybuy-page .btn.btn-primary:hover, .account-form .btn.btn-primary:hover {
  background: #F6961F;
  color: white;
}
.intermediatelevel-page input.form-control, .easybuy-page input.form-control {
  border-width: 0 0 2px 0;
  border-color: rgb(227, 227, 227);
  border-radius: 0;
}
.intermediatelevel-page input.form-control:hover, .intermediatelevel-page input.form-control:focus,
.easybuy-page input.form-control:hover, .easybuy-page input.form-control:focus {
  border-color: #FF9013;
}
.intermediatelevel-page input:focus, .easybuy-page input:focus {
  outline: none;
  box-shadow: none;
}
.intermediatelevel-page .react-tel-input .flag-dropdown, .easybuy-page .react-tel-input .flag-dropdown {
  background: transparent;
  border: none;
}
.intermediatelevel-page .react-tel-input .flag-dropdown:hover, .easybuy-page .react-tel-input .flag-dropdown:hover {
  background: rgba(170, 170, 170, 0.18);
}
.react-tel-input .flag-dropdown:hover .selected-flag, .react-tel-input .flag-dropdown:focus .selected-flag {
  background: transparent;
}
.form-group input[type=password] {
  padding: 2em;
}
.easybuy-page .form-check-inline {
  vertical-align: middle;
}
.label-for {
  margin-right: 0.75rem;
}
.currency-block-check {
  display: block;
}
.currency-formicon {
  margin-right: 0.5rem;
}
.easybuy-page .card {
  border: none;
}
.form-control option {
  background: white;
}

.product-details-page__container {
  margin: auto;
  width: 95%;
  max-width: 1200px;
}
.product-details-page__menu {
  border-right: none;
}
.product-details-page__menu .balances-menu, .trade-component.menu-component.balances-menu {
  background: white;
}
.product-details-page__menu .menu-component__item {
  height: 5rem;
  color: #FF9013;
  border-bottom: solid 1px #cdcdcd;
}
.product-details-page__menu .balances-menu__item-symbol-name {
  color: #323232;
  font-weight: 600;
}
.product-details-page__menu .menu-component__item:hover, .product-details-page__menu .menu-component__item:active, .product-details-page__menu .menu-component__item--selected {
  background: rgb(208, 208, 208);
}

.wallet-details {
  background-color: white;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.12);
}
.wallet-details .wallet-details__product-actions-row {
  border-bottom: 3px solid #ff8d3f;
}
.wallet-details .wallet-details__position-row .wallet-details__detail {
  border-right: 2px solid #e9e9e9;
  padding: 15px 30px;
}
.wallet-details .wallet-details__position-row .wallet-details__detail .wallet-details__label {
  color: black;
}
.wallet-details__wallet-nav-items .wallet-nav-item {
  background: transparent;
  border: none;
  color: black;
}
.wallet-details .wallet-details__product-icon {
  padding: 15px 30px;
}
.wallet-details__wallet-nav-items .wallet-nav-item:hover, .wallet-details__wallet-nav-items .wallet-nav-item:hover .icon-button__icon {
  background: whitesmoke;
  color: #ff8d3f;
}
.wallet-details__wallet-nav-items .wallet-nav-item .icon-button__icon {
  color: #484848;
}
.product-details-page__transfers-header, .activity .activity__header-bar {
  border-top-right-radius: .5rem;
  border-top-left-radius: .5rem;
  background: #ffffff;
  border-bottom: 3px solid #ff8d3f;
  padding: 0rem 1.5rem 0rem 3.5rem;
}
.product-details-page__transfers, .activity {
  -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
}
.transfers-filters, .activity__select-buttons {
  background: #fafafa;
}
.transfers-filters .transfers-filters__sb-container {
  border: none;
}
.transfers-filters .transfers-filters__sb-container .ap-segmented-button__label {
  padding: 0.5rem .5rem;
}
.transfers-filters .ap-inline-btn__btn {
  border: 2px solid rgb(229, 229, 229);
  height: 3rem;
}
.transfers__header-column {
  border-right: 1px solid #e3dcdc;
}
.transfers__wrapper {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.transfers__wrapper .transfers {
  border-radius: 0 0 .5rem .5rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.activity__from__dpk-label {
  margin: 0 0.5rem 0 1rem;
  letter-spacing: 0px;
}
.activity__from__dpk-input, .activity__to__dpk-input {
  background: rgb(237, 237, 237);
  max-width: 100px;
  border: 1px solid #b3b3b3;
}
.activity__select {
  background: rgb(237, 237, 237);
  border: 1px solid #b3b3b3;
  padding: .375rem 1.75rem .375rem .75rem;
  width: 14rem;
}
.activity__triangles-container {
  left: 24rem;
}


/* sidepane */
.fiat-sidepane-header {
  margin-top: 0.6rem;
  margin-bottom: 0.4rem;
}
.fiat-sidepane-header .fiat-sidepane-header__logo {
  width: 30px;
}
.fiat-sidepane-header .fiat-sidepane-header__title {
  padding-top: 0.5rem;
}
.slide-pane__content {
  background: white;
}
.fiat-sidepane {
  border-top: none;
}
.fiat-sidepane .fiat-sidepane__main {
  padding: 2.2rem;
}
.fiat-sidepane__main-form {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}
.fiat-tabs {
  min-height: 3.2rem;
  height: 3.2rem;
  margin-bottom: 1rem;
}
.fiat-tabs__tab {
  height: 3rem;
  line-height: 2.8rem;
  border-bottom: transparent;
}
.fiat-tabs__tab .fiat-tabs__tab-icon {
  display: none;
}
.fiat-tabs__tab.fiat-tabs__tab--selected, .fiat-tabs__tab:hover {
  border-bottom: 2px solid #ff8d3f;
  color: black;
}
.fiat-tabs__tab:first-child {
  border-right: none;
}

/* alert bar */
.snackbar--warning {
  background-color: #ee3f50;
}

.snackbar--warning .ap-icon--close {
  color: white;
}

.page-header-nav__icon--menu-toggle {
  color: white;
}
.trading-layout__container .instrument-row {
  min-height: 5rem;
}
.instrument-row .instrument-table {
  background: #f9f9f9;
  padding: 2rem 0 3rem;
}
.sidepane-details__divider {
  background: transparent;
}
.sidepane-details__information-text {
  color: black;
}
.sidepane-details__lwt-container {
  border-top: none;
}
.kyc-settings.kyc-settings--star {
  padding: 0 0rem 0 0rem;
  height: auto;
  margin-bottom: 3rem;
}
.kyc-level-banexcoin-old {
  min-height: 35rem;
}
.kyc-level-banexcoin-old, .kyc-level.otherlevels {
  margin-bottom: 2rem;
  width: calc(22% - 1rem);
  margin-right: 1rem;
  position: relative;
}
.kyc-level-banexcoin-old .kyc-settings__levels {
  width: 100%;
}
.kyc-level-banexcoin-old .kyc-level .kyc-level__body,
.kyc-level-banexcoin-old .kyc-level .kyc-level__header-title,
.kyc-level-banexcoin-old .kyc-level .kyc-level__footer {
  width: calc(100% - 3rem);
}
.kyc-level-banexcoin-old .kyc-level {
  max-width:100%!important;
  min-width:100%!important;
}
.kyc-form__section {
  margin-top: 0rem;
  padding-top: 0rem;
  border-top: none;
}
.kyc-form__section .ap-input__input-box .form-control, .ap-datepicker__input-wrapper .form-control, .react-phone-number-input__input.ap-phone-input__input {
  background-color: transparent;
  border-width: 0 0 2px 0;
  border-radius: 0;
}
.kyc-form__section .ap-input__input-box .form-control:hover, .ap-datepicker__input-wrapper .form-control:hover, .react-phone-number-input__input.ap-phone-input__input:hover {
  background-color: transparent;
  border-width: 0 0 2px 0;
  border-color: #FF9013;
}
.ap-datepicker__input-wrapper .form-control:focus {
  box-shadow: none;
  outline: none;
}
.kyc-form__error:not(.ap-upload__error) {
  top: 6.1rem;
  margin-left: 0;
}
.kyc-form__section .kyc-form__input-box .react-phone-number-input__row, .react-phone-number-input__input.ap-phone-input__input  {
  background: transparent;
}
.kyc-form__section select option, .pika-single .pika-select.pika-select-year option, .pika-single .pika-select.pika-select-month option {
  background: white;
}
.kyc-form__input-box .react-phone-number-input__row {
  border: none;
}
.kyc-form__section .ap-upload__wrapper {
  background: transparent;
}
.kyc-form__section .ap-upload__wrapper .ap-upload__btn {
  border: 1.5px solid #7BC13D!important;
  background-color: #7BC13D;
  color: white;
}
.kyc-form__btn:not(.ap-upload__btn) {
  width: auto;
}
.kyc-confirm-level__section-field {
  background: rgb(233, 233, 233);
  border-bottom: none;
}
.kyc-confirm-level__section-field-label {
  color: #292929;
  font-weight: 500;
}
.kyc-confirm-level__sections-wrapper, .ap-modal__footer.kyc-confirm-level__footer {
  border-top: none;
}
.kyc-form__section button.ap-button__btn.kyc-form__btn--general {
  color: white;
  background: #ff7d00;
  border-radius: 0.5rem;
  border: 1.5px solid #ff7d00!important;
}
.kyc-form__section button.ap-button__btn.kyc-form__btn--general:hover {
  color: #ff7d00!important;
  background: white;
}
.kyc-form__section button.ap-button__btn.kyc-form__btn--general:disabled {
  color: white!important;
  opacity: 0.5;
}
.ap-datepicker__error {
  display: none;
}
.kyc-form__section .kyc-form__label {
  color: black;
}
.send-receive-header {
  margin-bottom: 2rem;
}
.send-receive__main {
  padding: 1.5rem 2.2rem;
}
.send-receive__main-form {
  border: solid 1px rgba(255, 255, 255, 0.24);
  background-color: rgb(255, 255, 255);
  color: black;
  box-shadow: none;
}
.send-receive-tabs__tab-icon {
  display: none;
}
.send-receive-tabs__tab-text, .receive-address__description, .receive-address__copy-address-text {
  color: black;
}
.receive__source-item {
  margin: 0rem;
  background: rgb(251, 251, 251);
  color: #F28E2A;
  font-weight: 500;
  border-radius: .5rem;
  border: 1.5px solid #F28E2A;
}
.send-receive .receive {
  padding: 1.7rem 1rem;
}
.send-receive-tabs__tab--selected {
  border-bottom: 2px solid #F28E2A;
}
.receive-address__copy-icon--copy {
  color: #F28E2A;
}
.receive__source {
  border: none;
}
.receive__source-item-with-border {
  border-right: none;
  margin-right: 1rem;
  width: calc(100% - 1rem);
}
.receive__source-item--active, .receive__source-item:hover {
  background: #F28E2A;
  color: white;
}
.receive-address__address {
  max-width: 100%;
}
.snackbar--info {
}
.incongito_joyride {
  position: fixed;
  left: 2.7rem;
  bottom: 25px;
  background: #636363;
  color: white;
  z-index: 999;
  padding: 4px;
  width: 25px;
  height: 25px;
  display: block;
  border-radius: 05rem;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1;
}
.incongito_joyride:hover {
  opacity: 0.8;
}
.login-form__input-wrapper .ap--label, .signup-form__additional__label, .signup-form__label {
  color: rgb(141, 141, 141)!important;
  font-family: "Montserrat", Sans-serif;
  font-weight: 500;
}
.standalone-form__container.login-form__container .form-control, .standalone-form__container.signup-page__container .form-control, .standalone-form__container.problem-logging-in-page__container .form-control {
  background-color: white!important;
  border: 1.5px solid #CBCBCB!important;
  color: #242424!important;
  border-radius: 10px;
}
.standalone-form__container.login-form__container .form-control:focus, .standalone-form__container.signup-page__container .form-control:focus, .standalone-form__container.problem-logging-in-page__container .form-control:focus {
  background-color: white!important;
  border: 1.5px solid #FFC591!important;
  color: #242424!important;
  border-radius: 10px;
  box-shadow: none;
}
.react-bootstrap-table .table {
  background: white;
  border: 1px solid #F1F2F4;
}

.react-bootstrap-table .table thead tr th {
  border: none;
  color: rgb(121, 123, 130);
  font-weight: 500;
  font-size: 1.3rem;
  cursor: default;
  padding: 1.5rem .75rem;
  text-transform: uppercase;
  vertical-align: middle;
}
.react-bootstrap-table .table tbody tr td {
  border: none;
  padding: 1.2rem .75rem;
}
.react-bootstrap-table .table tbody tr {
  background: white;
  color: #3a3535;
  font-size: 1.3rem;
}
.react-bootstrap-table .table tbody tr:nth-child(2n - 1) {
  background: #f8f8f8;
}
.react-bootstrap-table-pagination {
  background: #f1f1f1;
  margin: -11px 0px 0!important;
  padding: 0.75rem 0.75rem 0.75rem;
  border-radius: 0 0 .75rem 0.75rem;
  vertical-align: middle;
}
.react-bootstrap-table-pagination > div > span {
  vertical-align: -webkit-baseline-middle;
}


.header-biodata {
  background: white;
  padding: 3rem 3rem;
  border-radius: .75rem .75rem 0 0;
  border: 1px solid #F1F2F4;
}

.header-biodata .btn {
  width: auto;
  margin: 0 !important;
}

.header-biodata .left, .header-biodata .right {
  display: inline-block;
}
.header-biodata .left svg {
  font-size: 4rem;
  color: #6c6c6c;
  margin-right: 1rem;
}
.header-biodata h2 {
  text-transform: none;
  font-size: 1.8rem;
  font-weight: 500;
  color: #595959;
}
.header-biodata p {
  color: #b6b6b6;
  font-size: 1.2rem;
  font-weight: 500;
}
.yes_success {
  color: rgb(47 180 98);
  font-size: 1.4rem;
}
img.selfie_img {
  width: 50px;
}
.spacer-row {
  padding: 2rem 0;
  height: 2rem;
}
table.shareholders tr {
  background-color: rgb(233, 233, 233)!important;
}
table.shareholders tr:nth-child(4n - 1),
table.shareholders tr:nth-child(4n - 2),
table.shareholders tr:nth-child(4n - 3){
  background: #ffffff!important;
} 
.person_body th {
  vertical-align: middle;
  position: relative;
}
.body-validation {
  display: flex;
}
.body-validation .error-color {
  color: rgb(245, 29, 29);
  font-size: 1.5rem;
  vertical-align: middle;
  padding: 0 1rem;
}
.viewbiodata.fixedcenter .popup-box {
  position: fixed;
  top: 5vh;
  bottom: 0;
  height: 90vh;
  margin: 0 auto;
}
.viewbiodata .card {
  border: none;
  margin-bottom: 0rem;
  background: transparent;
  border-radius: 0.5rem 0.5rem 0 0;
}
.viewbiodata .card .card-header {
  background: #ffffff!important;
  margin: 0px;
  border-left: 1px solid #F1F2F4;
  border-right: 1px solid #F1F2F4;
  border-top: none;
  border-bottom: none;
}

.viewbiodata .card .card-header h3 {
  font-size: 2.3rem;
}
.viewbiodata .card .card-body {
  padding: 2rem 2rem;
  border: 1px solid #F1F2F4;
  border-top: none;
  border-bottom: none;
  background: white;
}
.viewbiodata .card .card-body .field-row {
  display: flex;
}
.viewbiodata .card .card-body .field-row .left {
  padding: 0 1rem 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}
.viewbiodata .card .card-body .field-row > div:last-child {
  width: 100%;
}
.viewbiodata .card .row .col-xl-4 > b, .viewbiodata .card .row .field-row > div > b, .viewbiodata .card .row > div > b, .viewbiodata .card .row .col-xl-12 > b {
  color: rgb(57, 57, 57);
  font-size: 1.4rem;
  font-weight: 500;
}
.viewbiodata .card .row .col-xl-4 > p, .viewbiodata .card .row .field-row > div > p, .viewbiodata .card .row > div > p, .viewbiodata .card .row .col-xl-12 > p {
  color: #0F1731;
  font-size: 1.7rem;
  font-weight: 600;
  text-transform: uppercase;
}
.viewbiodata .card .row .col-xl-12 > p > a {
  font-size: 1.7rem;
  font-weight: 600;
  color: #f59235;
}
.viewbiodata .card .row .col-xl-12 > p > a:hover {
  opacity: 0.8;
}
.viewbiodata .card .row .field-row > div > div > input[type="checkbox"]:disabled {
  opacity: 0;
}
.viewbiodata .card .row .col-xl-4 > p,
.viewbiodata .card .row p.text-body {
  font-size: 1.2rem!important;
  font-weight: 500!important;
  color: rgb(119, 119, 119)!important;
}
.admin-levels-page.corporate-full {
  width: 98%;
  max-width: 98%;
}
.admin-levels-page.actions-cloud {
  border-top: 7px dashed #F5F6F8;
  border-bottom: 7px dashed #F5F6F8;
  border-right: none;
  border-left: none;
  margin: 2rem auto 0;
}
.admin-levels-page .card.status1 .card-body, .admin-levels-page .card.statusNEW_RECORD .card-body, .admin-levels-page .card.statusINPROCCESS .card-body, .admin-levels-page .card.statusREVIEW_REQUESTED .card-body {
  background: #79a8d1;
}
.admin-levels-page .card.status7 .card-body, .admin-levels-page .card.statusACCEPTED .card-body {
  background: #4eb690;
}
.admin-levels-page .card.status4 .card-body, .admin-levels-page .card.statusREJECTED .card-body {
  background: #f27763;
}
.admin-levels-page .card.status5 .card-body, .admin-levels-page .card.statusINCORRECT_INFORMATION .card-body {
  background: #f2cd63;
}
.admin-levels-page .card.statusNEW_RECORD .card-body .row,
.admin-levels-page .card.statusINPROCCESS .card-body .row,
.admin-levels-page .card.statusREVIEW_REQUESTED .card-body .row,
.admin-levels-page .card.statusACCEPTED .card-body .row,
.admin-levels-page .card.statusREJECTED .card-body .row,
.admin-levels-page .card.statusINCORRECT_INFORMATION .card-body .row {
  color: white;
  align-items: center;
}
.admin-levels-page .card.statusNEW_RECORD .card-body .row h2,
.admin-levels-page .card.statusINPROCCESS .card-body .row h2,
.admin-levels-page .card.statusREVIEW_REQUESTED .card-body .row h2,
.admin-levels-page .card.statusACCEPTED .card-body .row h2,
.admin-levels-page .card.statusREJECTED .card-body .row h2,
.admin-levels-page .card.statusINCORRECT_INFORMATION .card-body .row h2 {
  text-align: center;
  font-weight: 400;
  margin: 0 auto;
}
.admin-levels-page .card.status1 .card-body .row, .admin-levels-page .card.status7 .card-body .row, .admin-levels-page .card.status4 .card-body .row, .admin-levels-page .card.status5 .card-body .row,  .admin-levels-page .card.statusACCEPTED .card-body .row {
  color: white;
  align-items: center;
}
.admin-levels-page .card.status1 .card-body .row h2, .admin-levels-page .card.status7 .card-body .row h2, .admin-levels-page .card.status4 .card-body .row h2, .admin-levels-page .card.status5 .card-body .row h2,  .admin-levels-page .card.statusACCEPTED .card-body .row h2 {
  text-align: center;
  font-weight: 400;
  margin: 0 auto;
}
.left_block.col-xl-12 {
  margin-left: 2rem;
}
.field-row.left_block {
  margin-left: 1.3rem;
}
.space_Worker_type h3 {
  font-weight: 600;
}
.col_Worker_type.col-xl-6{
  margin-top: 4rem;
}
.space_Worker_type {
  margin-bottom: 2rem;
}
.col .svg-inline--fa.fa-info-circle.fa-w-16 {
  color: #e33539;
}
.svg-inline--fa.fa-info-circle.fa-w-16 {
  margin-right: 1rem;
  font-size: 2rem;
  color: cornflowerblue;
  position: relative;
  top: 0.3rem;  

}
.horizontal_line
{border-bottom: 1px dotted black;
  width: 85%;
  margin: 0 auto 2rem;
}
.botton_line
{border-bottom: 1px dotted black;
  width: 85%;
  margin: 0 auto 2rem;
}
.vertical_line {
  border-left: 1px dotted black;
  height: calc(100% + 8rem);
  margin-left: 21.5rem;
  position: absolute;
  margin-top: 4rem;
}


.bnx-datepicker {
  border-bottom: 2px solid #eaeaea;
  background: white;
  font-size: 1.2rem;
  border-radius: 0;
  height: 44px;
  padding: 1rem 2rem 1rem 1rem;
  font-size: 1.6rem;
}
.bnx-datepicker:hover, .bnx-datepicker:focus {
  border-bottom: 2px solid #FF9013;
}
.react-datepicker-component .form-check label {
  font-size: 1.3rem;
}
.bnx-calendar.react-datepicker .react-datepicker__month-container {
  background-color: white;
  border-left: 1px solid #aeaeae;
  border-right: 1px solid #aeaeae;
  border-bottom: 1px solid #aeaeae;
  margin-left: -2px;
  margin-right: -2px;
}

.bnx-calendar.react-datepicker .react-datepicker__navigation {
  width: 3rem;
}
.bnx-calendar.react-datepicker .react-datepicker__navigation--previous {
  left: 5px;
}
.bnx-calendar.react-datepicker .react-datepicker__month-container .react-datepicker__day {
  padding: 0.6rem;
  width: 2.2rem;
  margin: 0.066rem;
}
.bnx-table-all caption {
  caption-side: inherit
}
.bnx-table-all .alert {
  font-size: 1.8rem;
  text-align: center;
}
.bnx-pagination .react-bs-table-sizePerPage-dropdown {
  position: relative;
}
.bnx-pagination .react-bs-table-sizePerPage-dropdown .dropdown-menu {
  width: 325px;
  transform: inherit!important;
}
.bnx-pagination .btn {
  font-size: 1.5rem;
}
.bnx-pagination .dropdown-menu.show {
  background-color: #ffffff;
}
.bnx-pagination .dropdown .dropdown-item {
  padding: .25rem 0rem;
}
.bnx-pagination .dropdown .dropdown-item:hover {
  background-color: #4c4c4c;
}
.bnx-pagination .dropdown .dropdown-item a {
  color: #212529;
}
.bnx-pagination .dropdown .dropdown-item:hover a {
  color: white;
  background: transparent;
}
.bnx-pagination .dropdown-menu.show {
  position: absolute!important;
  top: inherit;
}
.bnx-pagination .react-bootstrap-table-page-btns-ul .page-item .page-link {
  padding: 1rem 1.15rem;
  font-size: 1.2rem;
  color: rgb(32, 32, 32);
}
.bnx-pagination .react-bootstrap-table-page-btns-ul .page-item.active .page-link {
  background-color: #f28e2b;
  border-color: #f28e2b;
}

.react-datepicker-component .react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container .bnx-datepicker {
  width: 100%;
}
.banex-form-filter-content .col-xl-2 {
  padding-right: 0px;
}
.btn.bnx-button {
  font-size: 1.4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.bg-red {
  background-color: rgb(253, 205, 186);
}
.bg-red-he {
  background-color: rgb(209, 95, 61);
}
.bg-green {
  background-color: rgb(186, 226, 214);
}
.bg-green-he {
  background-color: rgb(14, 155, 84);
}
.bg-blue {
  background-color: rgb(201, 201, 251);
}
.bg-blue-he {
  background-color: rgb(63, 63, 141);
}
.bg-purple {
  background-color: rgb(245, 201, 251);
}
.bg-purple-he {
  background-color: rgb(141, 63, 137);
}
.bg-red-he, .bg-green-he, .bg-blue-he, .bg-purple-he {
  color: white!important;
}

/* bootstrap btns */
.btn-danger {
  background-color: #e33539;
  border-color: #e33539;
}
.btn-success {
  background-color: #48d167;
  border-color: #48d167;
}
.btn-info {
  background-color: #36b091;
  border-color: #36b091;
}
.direct-actions .btn {
  margin-right: 1rem;
  vertical-align: middle;
  line-height: 1;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 500;
  font-size: .9rem;
  text-transform: uppercase;
}
.direct-actions .right_btn {
  text-align: right;
}
.direct-actions .col-xl-5 .row .col-xl-4 b {
  color: rgb(81, 81, 81);
  text-transform: uppercase;
  font-weight: 600;
}
.direct-actions .btn svg {
  font-size: 2rem;
}
.direct-actions .btn svg.spc {
  margin-right: 1rem;
}
.direct-actions .btn span {
  margin-top: 0.3rem;
  display: inline-block;
  margin-right: auto;
}
.standalone-form__auto-tab-input .form-group {
  margin: 0;
}
.table-banexcoin .header-title-table th {
  padding: 1rem 1rem;
  text-align: center;
  background: #ff8d18;
  border-radius: .5rem .5rem 0 0;
  color: white;
  font-size: 1.3rem;
  border-width: 2px 2px 2px 1rem;
  border-color: #ff8d18;
  border-style: solid;
}
.table-banexcoin .header-titles-table, .table-banexcoin .header-titles-table th {
  background: #c7c7c7;
  border-width: 2px 2px 2px 1rem;
  border-color: #c7c7c7;
  border-style: solid;
}
.table-banexcoin .header-titles-table th {
  padding: 1rem 1rem;
  color: #2f2f2f;
  font-size: 1rem;
  font-weight: 600;
}
.table-banexcoin .button-box {
  display: flex;
}
.table-banexcoin .button-box button {
  margin-right: 3px;
}
.table-banexcoin .button-box button:last-child {
  margin-right: 0;
}

.input-search.input-group {
  position: relative;
  height: 70px;
  background: white;
  padding: 15px;
  border-radius: 0.7rem;
  box-shadow: 0 0 3px 2px rgb(139 139 139 / 20%);
}
.input-search.input-group .form-control {
  height: 100%;
}
.input-search.input-group .input-group-append, .input-search.input-group .input-group-prepend {
  vertical-align: top;
}
.input-search.input-group .input-group-append .btn, .input-search.input-group .input-group-prepend .btn {
  margin-top: 0;
  height: 100%;
  width: 100%;
}
.input-search.input-group .input-group-prepend {
  width: auto;
}
.input-search.input-group .btn.btn-primary {
  background-color: #ff7d00;
  border-color: #ff7d00;
}
.box-ticket.card {
  border-radius: 1rem;
  box-shadow: 0 0 3px 2px rgb(139 139 139 / 20%);
}
.box-ticket.card .card-header {
  background-color: white;
  padding: 2.5rem 1.25rem;
  font-size: 1.9rem;
  font-weight: 600;
  border-radius: 1rem 1rem 0 0;
}
.box-ticket.card .card-header:first-child {
  border-radius: 1rem 1rem 0 0;
}
.box-ticket.card .form-group {
  margin: 0;
}
.box-ticket.card .card-title, .box-ticket.card .form-group .form-label {
  font-size: 1.8rem;
  font-weight: 500;
}
.box-ticket.card .card-text {
  font-size: 1.5rem;
}
.box-ticket.card .form-group input.form-control {
  border: 1px solid #c9c9c9;
  border-radius: 0.5rem;
}

.box-ticket.card .btn.btn-primary, .banex-form-filter-content .btn.btn-primary.full-btn {
  background-color: #ff7d00;
  border-color: #ff7d00;
  font-size: 1.3rem;
}

.banex-form-filter-content .full-btn {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  line-height: 4.5;
}
.banex-form-filter-content .btn.btn-primary.full-btn:hover {
  opacity: 0.8;
}

.bnx-container-blacklist {
  background-color: transparent;
  padding: 1rem 1.5rem;
}
.bnx-container-blacklist .welcome {
  padding: 2rem 0;
}
.bnx-container-blacklist .welcome h3 {
  font-size: 2.5rem;
  vertical-align: middle;
}
.bnx-container-blacklist .welcome h3.display-flex .btn {
  margin: 0 0.5rem;
  vertical-align: middle;
}
.bnx-container-blacklist .welcome h3.display-flex .btn .symbol-icons {
  font-weight: 700;
  font-size: 1.5rem;
}
.bnx-container-blacklist .welcome p {
  font-size: 2rem;
}
.bnx-container-blacklist .welcome .badge {
  font-size: 1rem;
  margin-left: 0.5rem;
}
.bnx-container-blacklist .nav-tabs {
  padding-left: 2rem;
}
.bnx-container-blacklist .nav-tabs > a {
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  color: #949494;
}
.bnx-container-blacklist .nav-tabs > a.active {
  color: #202020;
}
.bnx-container-blacklist .tab-content .tab-pane {
  background-color: white;
  padding: 0 2.5rem;
}
.bnx-container-blacklist .dash-card {
  padding: 1.7rem 2rem 0;
  border-radius: 1.7rem;
  border: none;
  box-shadow: 0 0 13px 0px rgb(0 0 0 / 10%);
  margin-bottom: 2rem;
}
.bnx-container-blacklist .dash-card:hover {
  box-shadow: 0 0 13px 0px rgb(0 0 0 / 20%);
}
.bnx-container-blacklist .dash-card h3 {
  text-transform: uppercase;
  font-weight: 500;
  color: #777777;
}
.bnx-container-blacklist .dash-card h2 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2.2rem;
  color: #222222;
}
.bnx-container-blacklist .dash-card .card-footer {
  background-color: transparent;
  border: none;
  padding: 0 0 1.7rem 0;
  text-align: right;
}
.bnx-container-blacklist .dash-card .card-footer .btn {
  padding: 1.175rem 1.55rem;
  font-size: 1.3rem;
  border-radius: 0.7rem;
}
.bnx-container-blacklist .dash-card .card-footer .btn:first-child {
  margin-right: 0.5rem;
}
.bnx-container-blacklist .dash-card .card-footer .btn.btn-primary {
  background-color: #f28e2b;
  border-color: #f28e2b;
}
.bnx-container-blacklist .dash-card .card-footer .btn.btn-primary:hover {
  background-color: #faa44d;
  border-color: #faa44d;
}
.bnx-container-blacklist table.light-bnx {
  background-color: white;
}
.bnx-container-blacklist table.light-bnx .actions span > button:first-child {
  margin-right: 0.5rem;
}
.bnx-container-blacklist table.light-bnx .actions span > button.btn {
  font-weight: 500;
}
.bnx-container-blacklist table.light-bnx .actions span > button.btn:hover {
  opacity: 0.9;
}
.bnx-container-blacklist table.light-bnx .actions span > button.btn.btn-warning {
  background-color: #ffd65b;
  border-color: #ffd65b;
  color: #2d2d2d;
}
.bnx-container-blacklist table.light-bnx .actions span > button.btn.btn-danger {
  background-color:#f54f52;
  border-color: #f54f52;
  color: #fff;
}
.bnx-container-blacklist table.light-bnx thead tr th {
  padding: 1.5rem .75rem;
  vertical-align: middle;
}
.bnx-container-blacklist table.light-bnx thead tr.header-search th {
  padding: 0.7rem 0.7rem;
}
.bnx-container-blacklist table.light-bnx thead tr.header-search th input {
  padding: 0.7rem 1.2rem;
  width: 100%;
  border: 1px solid rgb(158, 158, 158);
  border-radius: 0.5rem;
}
.bnx-container-blacklist table.light-bnx thead tr.header-search th input:hover {
  border: 1px solid rgb(121, 121, 121);
}
.bnx-container-blacklist table.light-bnx tbody tr {
  padding: 0!important;
}
.bnx-container-blacklist table.light-bnx tbody tr td {
  vertical-align: middle;
  padding: 0rem 0.7rem!important;
}
.bnx-container-blacklist table.light-bnx tbody tr td > span {
  padding: .75rem;
  display: block;
  border: 1px solid transparent;
}
.bnx-container-blacklist table.light-bnx tbody tr td > input {
  padding: .75rem;
  display: block;
  width: 100%;
  border: 1px solid #faa472;
  border-radius: 0.5rem;
  color: rgb(36, 36, 36);
}
.bnx-container-blacklist table.light-bnx tr td.actions span {
  display: flex;
  padding: 0;
}
.bnx-container-blacklist table.light-bnx tr th.actions.search span > .btn {
  width: 100%;
  padding: 0.7rem 1.2rem;
}
.bnx-container-blacklist .paginator-bottom-bnx {
  padding: 1rem 0;
}
.bnx-container-blacklist .title-box-details .btn {
  margin-left: 0.5rem;
}
.bnx-container-blacklist {
  padding-bottom: 2rem;
}
.bnx-container-blacklist input[type="file"] {
  padding: 1.5rem;
}
.bnx-container-blacklist .blacklist-nodata {
  padding: 1rem 1.5rem;
}
.bnx-container-blacklist .blacklist-nodata > p {
  font-size: 1.3rem;
}
.bnx-container-blacklist .blacklist-nodata > b {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  color: #373737;
}
.bnx-container-blacklist .blacklist-nodata > .btn {
  margin: 1rem 0 0;
  font-size: 1.2rem;
}
.bnx-container-blacklist .box-status-type {
  text-align: center;
}
.bnx-container-blacklist .box-status-type .badge {
  padding: .8em 1.5em;
  margin: 0 auto;
}
.bnx-container-blacklist .box-status-type.done .badge {
  background-color:rgb(60, 180, 114);
}
.bnx-container-blacklist .box-status-type.working .badge {
  background-color:rgb(60, 116, 180);
}
@media only screen and (max-width: 1024px) {
  .page-header-nav__container--closed .menuly {
    display: none;
  }
}


@media only screen and (max-width: 968px) {
  .instrument-row .instrument-table .instrument-table__row {
    border-bottom: none;
  }
}
@media only screen and (max-width: 1024px) {
  #root .container.logged {
    width: 100%!important;
    max-width: 100%!important;
    margin: 0rem!important;
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  #root .container.logged {
    overflow: hidden;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1121px;
  }
}
@media only screen and (max-width: 1024px) {
  .page-header-nav__menu-toggle {
    position: relative;
    width: var(--nav-header__height);
    -ms-flex-pack: center;
    justify-content: center;
    right: 0;
    display: -ms-flexbox;
    display: flex;
  }
  /* .page-header-nav__container {
    -ms-flex-order: 1;
    order: 1;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: var(--z-index__navigation);
    position: absolute;
    height: var(--nav-header__height);
    -ms-flex-positive: unset;
    flex-grow: unset;
    -ms-flex-pack: unset;
    justify-content: unset;
  } */
  .page-header-nav__item:not(.page-header-nav__item--selected) {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: white;
    padding: 1.8rem 0.5rem!important;
    height: 0rem!important;
  }
  .page-header-nav__menu-toggle {
    border-right: none;
  }
  .page-header-nav__menu-toggle:hover, .page-header-nav__menu-toggle:focus {
    color: white;
  }
  .page-header-nav__icon--menu-toggle svg {
    margin-top: 0.6rem;
    display: block;
  }
  .page-header__right-panel {
    z-index: 500;
    height: 6.2rem;
  }
  .user-summary__popover-menu-trigger, .page-header__right-panel .popover {
    background: transparent;
  }
  .page-header__right-panel .popover svg {
    fill: #575757;
    color: #575757;
  }
  .alert-top-bar {
    text-align: left;
  }
  .alert-top-bar > p {
    display: flex;
  }
  .alert-top-bar > p > a {
    height: 4.5rem;
    text-align: center;
    margin: -1.5rem 0rem 0 6rem;
  }
}

@media only screen and (max-width: 1024px) {
  .page-header-nav__menu-toggle {
    display: inline;
  }
  .page-header-nav__container {
    order: -1;
    width: 100%;
  }
  .page-header__header-item--logo {
    order: 0;
  }
  .incongito_joyride {
    left: 17rem;
    top: 18px;
  }
}
/* @media screen and (max-width:1024px) and (min-width:721px) {
  .menuly {
    width: 100%;
    margin-top: 6rem;
    margin-left: -5.2rem;
  }
} */
@media screen and (max-width:1320px) and (min-width:200px) {
  .LevelBlock .LevelForSingle {
    padding: 20px 10px 20px 20px;
  }
}
@media screen and (max-width:1199px) and (min-width:530px) {
  .LevelBlock {
    display: block;
  }
  .LevelBlock .LevelForSingle {
    display: inline-block;
    width: 48%;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width:529px) and (min-width:200px) {
  .LevelBlock .LevelForSingle {
    padding: 10px 5px 5px 10px;
    height: 17rem
  }
  .LevelBlock {
    display: block;
  }
  .LevelBlock .LevelForSingle {
    display: inline-block;
    width: 46%;
    margin: 0 .5rem 2rem;
  }
  .LevelBlock .LevelForSingle .levelHeader .levelTitle {
    font-size: 1.5rem;
  }
  .LevelBlock .LevelForSingle .levelContent {
    font-size: 1rem;
    padding: .5rem 0 1.5rem;
    height: 9.5rem;
  }
  .LevelBlock .LevelForSingle .PendingVerifiedStatus {
    font-size: 1.1rem;
    padding: .5rem .5rem;
    width: 100%;
    white-space: normal;
    line-height: 1;
    text-align: center;
  }
  .LevelBlock .LevelForSingle .MustVerifiedStatus {
    font-size: 1.1rem;
    padding: .5rem 0.3rem;
    width: 100%;
    white-space: normal;
    line-height: 1;
  }

  .transfer-request-notification-list__empty {
    padding: .5rem 1.5rem;
    height: 6rem;
  }
}

@media only screen and (max-width: 990px) {
  .banexcoin_marketview .market-data-cards__card-banex {
    width: 47%;
  }
  .banexcoin_marketview, .dashboard-page__market-overview-container-left {
    margin-right: 10px;
  }
  .marketview_container_banex {
    height: 90%;
  }
  .banexcoin_marketview {
    overflow-y: scroll;
  }
  .dashboard-page__market-overview-container-right {
    width: 31.2rem;
  }
  .right_info_marketview {
    width: 35rem;
  }
  .LevelBlock {
    margin: 0 0rem 2.5rem;
  }
}
@media only screen and (max-width: 720px) {
  .dashboard-page__market-overview-container-right {
    width: 100%;
  }
  .eotc-buy-sell-layout__make-transaction-col-1, .eotc-buy-sell-layout__make-transaction-col-2 {
    width: 100%;
  }
  .page-header-nav__item--selected {
    background: rgb(233, 233, 233);
  }
  .page-header-nav__item--selected .page-header-nav__label {
    color: #2b2b2b;
    font-weight: 600;
  }
  .user-settings-page__container {
    padding: 10px;
  }
  .nav-menu-vertical {
    padding: 0 10px;
  }
  
}
@media only screen and (max-width: 920px) {
  .settings-page__content .container {
    width: 100%;
    max-width: 100%;
  }
  .nav-menu-vertical__item {
    padding: 0 1rem 0 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .settings-menu__menu-toggle {
    background: #ececec;
    color: black;
  }
}
@media only screen and (max-width: 720px) {
  .settings-page__menu {
    margin-bottom: 6rem;
  }
  .nav-menu-vertical {
    padding: 0 0px;
}
}
@media only screen and (max-width: 480px) {
  .nav-menu-vertical__list {
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.37);
  }
}
@media only screen and (max-width: 575px) {
  .alert-top-bar > p > a {
    margin: -1.5rem 0rem 0 1rem;
  }
}

.App.fluid.container.logged {
  display: -webkit-inline-box;
}
.old-admin >*:last-child{
  margin: 0 !important;
  width: 100% !important;
  transition: none !important;
  overflow: auto;
  height: calc(100vh - 60px);
  margin-top: 60px !important;
}
.old-admin >*:last-child::-webkit-scrollbar, .table-responsive::-webkit-scrollbar{
  width:var(--scrollbar__size);
  height:var(--scrollbar__size);
  background: rgba(173, 172, 172, 0.46);
  border-radius: 11px;
}
.old-admin >*:last-child::-webkit-scrollbar-thumb, .table-responsive::-webkit-scrollbar{
  border-radius: 4px;
  background: rgba(101, 101, 101, 0.59);
}
.App.container.open div[class^='content-'], .App.container div[class*=' content-']  {
  margin-left: 239px;
  width: calc(100% - 239px);
  transition:width 0.5s ;
}
.App.container div[class^='content-'], .App.container div[class*=' content-'] {
  margin-left: 71px;
  margin-top: 63px;
  width: calc(100% - 71px);
  transition:width 0.5s ;
}
.App.container.open .content-sendgrid {
  width: 75%!important;
  margin-left: 20%!important;
  margin-top: 10rem!important;
  transition:width 0.5s ;
}

.App.container .content-sendgrid {
  margin-left: 90px;
  margin-top: 10rem!important;
  width: calc(98% - 100px);
  transition:width 0.5s ;
}
.App.container.open .container.bnx-container-blacklist{
  margin-left: 239px;
  margin-top: 4rem;
  width: calc(100% - 239px);
  transition:width 0.5s ;

}
.App.container .container.bnx-container-blacklist{
  margin-left: 71px;
  margin-top: 4rem;
  width: calc(100% - 71px);
  transition:width 0.5s ;

}

.modal-return.rejectReason .form-control {
  font-size: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  height: 10.5rem;
  padding: 0.5em 0.8em;
}
.modal-return.rejectReason .form-control:hover {
  background-color: rgb(247, 247, 247);
}
.modal-return.rejectReason .form-group {
  margin: 0;
}
.modal-return.rejectReason .modal-footer .btn {
  padding: 12px;
  font-size: 1.5rem;
}
.modal-return.rejectReason .text-error {
  padding: 1rem 2rem;
  color: rgb(176 52 52);
  background-color: #ffdcd0;
  font-size: 1.4rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(249 108 108);
}
.modal-return.rejectReason .text-error > img {
  width: 3rem;
}


.admin-v2 .modal-custom-validation .modal-body section{
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-v2 .modal-custom-validation .loader-spinner-container{
  width: auto;
}

.admin-v2 .modal-custom-validation .modal-header {
  display: flex ;
  justify-content: flex-end;
}

.admin-v2 .modal-custom-validation .modal-body img{
  margin: auto;
}

.admin-v2 .modal-custom-validation .modal-body > div{
 display: flex;
 justify-content: flex-end;
}

.admin-v2 .modal-custom-validation .modal-body .content-data-modal{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-v2 .modal-custom-validation .modal-body .content-data-modal .content-data-modal-info{
  max-height: 500px;
  overflow: auto;
  width: 90%;
}
.admin-v2 .modal-custom-validation .modal-body .content-data-modal .itemmodal{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.admin-v2 .modal-custom-validation .modal-body .content-data-modal .nameitem,.admin-v2 .modal-custom-validation .modal-body .content-data-modal .span{
  font-size: 14px;
  margin-right: 10px;
}
.react-confirm-alert-button-group{
  flex-wrap: wrap;
  gap: 10px;
}
.react-confirm-alert-button-group button{
  margin-right: 0;
}
.form-group label.form-label{
  line-height: 1.1!important;
}

.admin-v2 .App.container.open div[class^='content-'], .admin-v2 .App.container div[class*='content-']{
  margin: 0 !important;
  width: 100% !important;
  transition: none !important;
}
select.form-control.form-control{
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
};
  .table-tools{
  width: auto !important;
}
.table-tools tr{
  font-size: 13px;
}
.table-tools thead tr{
  background: #000;
  color: #fff;
}
.title-tool{
  font-weight: 700;
  font-size: 17px;
}
.button-tool{
  margin: 0;
  padding: .375rem .75rem;
  width: auto;
  border-radius: 0.25rem;
}
.old-container-table{
  display: grid;
  overflow: auto;
  width: 100%;
}
.old-content{
  overflow: auto;
}
.old-content .table-responsive::-webkit-scrollbar {
  width: var(--scrollbar__size);
  height: var(--scrollbar__size);
}
.min-width-table {
  min-width: 120px !important;
}
.old-table-v2{
  width: auto;
  min-width: 100%;
}
.table {
  width: auto !important;
  min-width: 100% !important;
}
.table th, .table td {
  min-width: 120px !important;
}
.table th.table-title-v2 {
    white-space: pre-line !important;
    min-width: 180px !important;
  
}
.text-ellipsis-description {
  max-width: 300px !important;
  min-width: 150px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: auto;
}

.modal{
  width: 100%!important;
  max-width: 100vw!important
}
.modal.fade.show {
  z-index: 9999;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  max-width: 100vw;
}

.right-resizable .comments .dropdown-menu{
  transform: translate(-10px, 25px)!important;
}
.add-comment-button{
  width: 15rem;
  height: 3.6rem;
}
.edit-comment-button{
  width: 8rem;
  height: 3.6rem;
}
.delete-comment-button{
  width: 5rem;
  height: 3.6rem;
}

.reports-page .iframe-container{
  height: 800px;
  width: 100%;
  margin: auto;
  max-width: 80vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4rem;
}
.reports-page .iframe-container h2{
  font-size: 3rem;
}
.select-container{
  max-width: 400px;
  margin: 2rem 0;
}
.reports-page .loader-spinner-container{
  max-width: 500px;
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.all-container-data {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: max-content auto;
  background-color: var(--white-color);
  row-gap: 2rem;
}

@media (max-width:425px) {
  .all-container-data {
      row-gap: 1rem;
  }
}


.resizable-details-container{
  width: 100%;
  display: grid;
  grid-template-rows: max-content auto;
  height: 100%;
  overflow: auto;
}


.resizable-details-container .header-section {
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 1.25rem;
}

.resizable-details-container .header-section h2 {
  font-size: 1.8rem;
  font-weight: 400;
}


.header-section h2{
  font-size: 2rem!important;
  font-weight: 400;
  text-align: left;
}
.header-section .info{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 20px;
}
.header-section .option p:first-child{
  color: var(--text-gray);
  margin-right: 5px;
}
.header-section .option{
  display: flex;
  align-items: center;
  justify-items: flex-start;
  font-size: 1.6rem;
  margin-top: 3px;
}

.header-section .text-right .option p:first-child,.validation-phone p:first-child{
  color: var(--text-gray);
  margin-right: 5px;
}
.text-green{
  color: var(--green-color);
  font-weight: bold;
}
.text-red{
  color: var(--red-color);
  font-weight: bold;
}
.css-g1d714-ValueContainer > div{
  min-width: 150px!important;
}
.css-1rhbuit-multiValue{
  justify-content: space-between;
}

.reports-page .iframe-container{
  height: 800px;
  width: 100%;
  margin: auto;
  max-width: 80vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4rem;
}
.reports-page .iframe-container h2{
  font-size: 3rem;
}
.select-container{
  max-width: 400px;
  margin: 2rem 0;
}
.reports-page .loader-spinner-container{
  max-width: 500px;
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.buttons-comment-container{
  margin: 1rem;
  gap: 1rem;
}
.section-loader {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding: 3rem 0;
}
.section-loader >p{
  font-size: 1.5rem;
}
.sub-title {
  font-size: 2rem;
  color: var(--black-color);
  font-weight: 500;
}
.device-section {
  display: flex;
  column-gap: 4rem;
  max-width: 700px;
  width: 100%;
  margin-top: 2rem;
}
.device-section >div{
  width: 50%;
}
.device-section >div .content-level-grid{
  grid-template-columns: auto;
}
.queues-file-url-cell,
.queues-file-url-cell:hover{
  color: var(--orange-color);
  font-size: 1.4rem;
}
.max-w-250{
  max-width: 250px;
}
.delete-image-modal-form{
  cursor: pointer;
  font-size: 1.6rem;
  color: var(--red-color);
  margin-top: 1rem;
  text-align: center;
}

.error-message{
  color: var(--red-color);
  font-size: 1.6rem;
  padding: 0 1rem;
}
.justify-between{
  justify-content: space-between;
}
.button-search{
  background-color: var(--orange-color);
  padding: 1rem;
  border-radius: 15px;
}
.button-search:disabled{
  opacity: .5;
  cursor: default;
}
.body-form{
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.table_banking_transactions{
  overflow: auto;
  display: grid;
  grid-template-rows: max-content max-content auto;
  row-gap: 2rem;
  height: calc(100vh - 70px);
}
.table_banking_transactions .over_table{
  overflow: auto;
}
.card-report {
  background: var(--input-color);
  width: 330px;
  padding: 2rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.card-report .card-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.card-report .card-section .card-items{
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
.item-text{
  font-size: 1.6rem;
}
.empty-table td p{
  margin-top: 10%;
}
.no_data_table{
  width: -webkit-fill-available;
  margin: auto;
  position: absolute;
  text-align: center;
}
.no_data_table p{
  margin-top: 10%;
}

.ReactCrop__image{
  width: 100% !important;
  max-width: 500px !important;
}
.azure_container{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.azure_container .azure_label{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.8rem;
  color: var(--text-gray);
}
.azure_container .azure_label input{
  font-size: 1.4rem;
}
.azure_container >div:nth-child(2) {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.azure_container >div:nth-child(2) .kyc_container_image{
  display: flex;
  gap: 2rem;
}
.azure_container >div:nth-child(2) .kyc_container_image img{
  width: 170px;
  height: 230px;
  object-fit: cover;
}
.azure_container >div:last-child{
  display: flex;
  justify-content: center;
}
.azure_container .azure_message{
  font-size: 2.4rem;
}
.azure_container .azure_message span{
  font-weight: 600;
  font-size: 3rem;
  padding-left: 1rem;
}
.reactEasyCrop_Container{
  top: 50px !important;
}
.form-control.textarea-editor{
  height: 100px;
}
.btn-close-right{
  text-align: right;
  padding: 1rem;
}
.uppercase {
  text-transform: uppercase ;
}
.rdw-inline-wrapper > div:last-child,
.rdw-inline-wrapper > div:nth-child(6),
.rdw-inline-wrapper > div:nth-child(5){
  display: none;
}
.danger_text{
  text-align: center;
  display: block;
  padding-bottom: 1rem;
  color: var(--red-color);
  font-weight: 500;
}
.validation-phone.biometric{
  display: block;
}
.validation-phone.biometric ul{
  list-style: square;
}
.validation-phone.biometric ul li{
  font-size: 1.6rem;
  margin-bottom: 5px;
}