.withdraw-details .header-section p {
    color: var(--text-gray);
    font-size: 14px;
    margin-top: 3px;
}

.withdraw-details .header-section h2 {
    font-weight: 400;
    font-size: 18px;
}

.withdraw-details .header-section h2:first-child {
    font-weight: 600;
}

.withdraw-details .header-section strong {
    color: var(--black-color);
}

.withdraw-details .slot-details {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.withdraw-details .slot-details .option {
    width: 48%;
}

.withdraw-details .slot-details .option>p {
    font-size: 14px;
    color: var(--text-gray);
}

.withdraw-details .slot-details .option>p>b {
    font-weight: 400;
}

.withdraw-details .slot-details .option p:nth-child(2) {
    font-size: 16px;
    color: var(--black-color);
}

.withdraw-details .comments h2,
.withdraw-details .ipr-content h2,
.withdraw-details .box-limits-container h2 {
    font-size:18px;
    font-weight: 500;
    padding: 10px 0;
    width: 100%;
    text-align: left;
}

.withdraw-details .comments ul li {
    list-style: none;
    margin-top: 10px;
    font-size: 14px;
    overflow-wrap: break-word;
}

.withdraw-details .comments ul li strong {
    font-size: 12px;
}

.withdraw-details .comments button {
    color: var(--white-color);
    font-size: 14px;
    border: 1px solid var(--purple-color);
    background-color: var(--purple-color);
    text-align: right;
    padding: 10px 15px;
}

.withdraw-details .comments .form-group {
    margin: 0;
}


.withdraw-details .comments button:hover {
    text-decoration: none;
}

.withdraw-details .form-group .input-color {
    border: 1px solid transparent;
    border-radius: 15px;
    background: var(--input-color);
    padding-left: 10px;
}

.withdraw-details .form-container h2 {
    font-size: 20px;
    font-weight: 500;
    padding: 10px 0;
    width: 100%;
    text-align: left;
}

.withdraw-details .form-container .border-error {
    border: 1px solid var(--red-color) !important;
}

.withdraw-details .invalid-feedback {
    color: var(--red-color) !important;
}

.withdraw-details .ipr-content {
    padding: 1.25rem!important;
}

.withdraw-details .ipr-content .ipr-content-inputs {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    gap: 2rem;
}

.withdraw-details .ipr-content .form-group {
    margin: 0 5px;
}

.withdraw-details .comments>div:first-child {
    padding: 0.75rem 1.25rem;
    word-break: break-word;
}

.withdraw-details .align-right {
    justify-content: flex-end;
}

.withdraw-details  .container-tbl-dep {
    overflow-x: auto;
    margin: 1.25rem;
    position: relative;
}

.withdraw-details .container-tbl-dep td{
    font-size: 14px;
}

.withdraw-details .send-pr-btn{
    width:95px;
}

.input-file-preview-container.form-group {
    display: flex;
    justify-content: flex-end;
}

.ipr-content-inputs .withdraw-voucher {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.withdraw-voucher .logo-1 {
    text-align: center;
}

input[type="accountLogo"] {
    background: red;
  }
.form-group .label-reject{
    margin-bottom: 0;
    padding-bottom: 0;
    font-weight: 400;
}
  .form-group .notes-reject {
    font-size: 1.2rem;
    color: red;
    margin-bottom: 0;
    margin-top: 4px;
}
.right-resizable .alert{
    margin: 1rem;
    border-radius: 0.5rem;
    width: auto;
}
.right-resizable .text-wng-ipr{
    font-size: 1.4rem;
}
.content-tab-container.withdraw-details-content .slot-details .option{
    width: 46%;
}
.reject-reason{
    display: flex!important;
}
.reject-reason .modal-dialog{
    background-color: transparent!important;
    border-radius: none!important;
    box-shadow: none!important;
    width: 48rem!important;
}
.reject-reason  .modal-content .modal-footer{
    border-top: none!important;
}
.reject-reason .modal-dialog .modal-content .modal-header .close {
    padding: 1.5rem 1.5rem!important;
}
.reject-reason .modal-dialog .modal-content .modal-header .close span{
    font-size: 2.5rem!important;
}

.reject-reason .modal-footer .btn {
    outline: none;
    background: var(--btn-confirm-modal);
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #eee;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
}
.reject-reason .modal-content .form-group{
    margin-bottom:0!important;
}
.ipr-content-inputs .input-file-preview-container{
    display: flex;
    align-items: baseline;
}
.ipr-content-inputs .input-file-preview-container.form-group.withdraw-voucher.ipr-img-label {
    margin-top: 2rem;
}
.ipr-content-inputs .input-element.second{
    margin-top: 3.6rem;
    height: 51.5px;
}
@media (max-width:1200px) {
    .withdraw-details .slot-details {
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
}
@media (max-width:800px) {
    .withdraw-details .slot-details {
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width:500px) {
    .withdraw-details .ipr-content .ipr-content-inputs {
        display: grid;
        grid-template-columns: 1fr;
    }
    .content-tab-container.withdraw-details-content .slot-details .option{
        width: 100%;
        word-break: break-all;
    }
}
@media (max-width:350px) {
    .content-tab-container.withdraw-details-content .slot-details{
        display: grid;
        grid-template-columns: 1fr;
    }
}