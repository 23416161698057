.tabs-container {
    background-color: #F5F5F9;
    border: none;
}
.tabs-container > a{
    padding: 8px 16px;
    height: 36px;
    font-size: 16px;
    color: var(--text-gray);
    opacity: 0.6;
}
.tabs-container > a:hover{
    border-color: transparent!important;
}

.tabs-container > a.active{
    color: #1A1A2F!important;
    border-radius: 18px 18px 0 0;
    border: none;
    font-weight: 500;
    opacity: 1;
}

.container-tabs .cont-tab{
    height: 100%;
}

@media screen and (max-width: 425px) {
    .tabs-container > a{
        font-size: 14px;
    }
}