
.custom-modal-container.add-rates{
    width: 400px;
    margin: 2rem auto;
}
.custom-modal-container.add-rates .add-rate-alert-message{
    padding: 1rem;
    font-size: 1.4rem;
}
.select-row-modal-item label{
    display: flex;
    font-size: 1.6rem;
    align-items: center;
    gap: 1rem;
}