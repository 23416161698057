.login-page__container {
  border-radius: 10px;
}

.login-page__header {
  height: 6.8rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 2px solid var(--standalone-form__header-border-color);
}

.login-page__header-text {
  font-size: 2rem;
}

.login-page__footer {
  padding: 0 2.8rem;
  margin: 0;
}

.login-page__link {
  font-size: 1.2rem;
}

.v2container-box {
  display: flex;
  position: relative;
  height: 100vh;
  flex-direction: row;
  background: white;
}
.v2container-box .container-left, .v2container-box .container-right {
  display: inline-flex;
}
.v2container-box .container-left {
  width: 60%;
  background-size: cover;
  position: relative;
}
.v2container-box .container-right {
  width: 40%;
}
.v2container-box .container-left::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #adadad;
  opacity: 0.4;
}
.v2container-box .container-right .login-form__container {
  box-shadow: none;
  width: 82%;
}
.v2container-box .container-right .login-form__container::before {
  display: none;
}
.v2container-box .container-right .login-form__container .login-form__header-text {
  font-weight: 500!important;
}
.v2container-box .container-right .login-form__container .form-group label {
  font-size: 1.5rem!important;
  font-weight: 600!important;
  color: rgb(36, 36, 36)!important;
}
.v2container-box .container-right .login-form__container .form-group .form-control,
.v2container-box .container-right .login-form__container .form-group .form-control:-webkit-autofill {
  border-radius: 1rem!important;
  padding: 1.8em 1em;
  height: 4.7rem;
  border: 2px solid transparent!important;
  background-color: #f6f6f6!important;
  font-size: 1.3rem;
  -webkit-text-fill-color: #242424;
  -webkit-box-shadow: 0 0 0px 1000px #f6f6f6 inset;
}
.v2container-box .container-right .login-form__container .form-group .form-control:hover,
.v2container-box .container-right .login-form__container .form-group .form-control:-webkit-autofill:hover:hover {
  border: 2px solid #353535!important;
  background-color: #eeeeee!important;
  -webkit-text-fill-color: #242424;
  -webkit-box-shadow: 0 0 0px 1000px #eeeeee inset;
}
.v2container-box .container-right .login-form__container .standalone-form__form .login-form__btn--additive {
  background: #F28E2A;
  color: white;
  font-weight: 600;
}

@media only screen and (max-width: 992px) {
  .v2container-box .container-left {
    display: none;
  }
  .v2container-box .container-right {
    width: 100%;
  }
  .container, .container-md, .container-sm {
    max-width: 100%;
  }
  .v2container-box .container-right .login-form__container {
    width: 100%;
  }
}
