
.blacklist-add-modal{
    width: 1000px;
    margin: 2rem auto;
}
.blacklist-add-modal .modal-content{
    max-width: 100%;
    min-height: 200px;
}
.container-submit-cancel-buttons{
    gap: 1rem;
    margin-top: 4rem;
}
.blacklist-add-modal .tabs-container{
    background-color: var(--white-color);
    border-bottom:3px solid var(--white2-color);
}
.blacklist-add-modal .nav-item.nav-link.active{
    border-bottom:3px solid var(--white2-color);
    background-color: var(--white2-color);
}
.blacklist-add-modal .preview-container{
    max-height: 300px;
    overflow: auto;
    margin-bottom: 1rem;
}
.blacklist-add-modal .preview-container .table-container-v2{
    min-height: 600px;
}
.blacklist-add-modal .preview-container .table-container-v2 .table-v2{
    min-width: 80vw;
}
.blacklist-add-modal .preview-container .table-container-v2 .table-wrapper{
    overflow: hidden;
}
.blacklist-add-modal .data-source-cell{
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: normal;
    padding-top: 1.5rem;
    max-width: 300px;
}
.blacklist-add-modal .add-file-form-description *{
    font-size: 1.4rem;
}

.blacklist-add-modal .add-file-form-description ul{
    list-style:inside
}
.blacklist-add-modal .add-file-form-description a{
    color: var(--orange-color);
    font-weight: bold;
}
.blacklist-add-modal .add-file-form-description a:hover{
    text-decoration: underline!important;
}
.blacklist-add-modal .add-input-file{
    border: 1px solid var(--orange-color);
    border-radius: 8px;
    padding: 1rem 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    margin-top: 1rem;
    color: var(--orange-color);
    font-size: 1.4rem;
    cursor: pointer;
    transition: 300ms all;
}
.blacklist-add-modal .add-input-file:hover{
    opacity: .5;
}
.blacklist-add-modal .preview-title-container{
    gap: 2rem;
}
.blacklist-add-modal .preview-title-container img{
    width: 20px;
    cursor: pointer;
}
.blacklist-add-modal .preview-title{
    margin: 2rem 0;
}
.blacklist-add-modal .addblacklist-add-rows-button{
    width: 138px;
    height: 36px;
}
.blacklist-add-modal .add-form-blacklist-container{
    padding: 1rem;
}
.blacklist-add-modal .loader-spinner-container{
    text-align: center;
}
.blacklist-add-modal .loader-spinner-container .lds-ring{
    margin-top: 0;
}
.add-manually-row{
    gap: 1rem;
    margin: 1rem 0;
}
.add-manually-row img{
    width: 16px;
}
.blacklist-add-modal .error-color {
    color: var(--red-color);
    font-size: 1.5rem;
    padding: 1rem;
  }