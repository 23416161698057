.bank-transactions-details {
    height: 100%;
    display: grid;
    grid-template-rows: max-content auto;
    overflow: auto;
  }
  .onboarding-buttons-approve-reject {
    width: 180px;
    height: 36px;
  }
  
  .bank-transactions-details textarea {
    padding: 1rem;
  }
  .bank-transactions-actions-content {
    display: flex;
    margin: 1.25rem;
    flex-direction: column;
    gap: 1rem;
  }
  .bank-transactions-details button {
    margin: 0;
  }
  .bank-transactions-actions-button {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
    padding: 1.25rem;
  }
  .bank-transactions-actions-content .subtitle {
    font-size: 2rem;
    margin: 0;
  }
  .input-element-aml-alert {
    width: 100%;
    max-width: 300px;
    margin-bottom: 1rem;
  }
  .bank-transactions-details .option {
    width: 47%;
    margin-bottom: 1.5rem;
  }
  .bank-transactions-details .option p{
    word-break: break-all;
  }
  .bank-transactions-details .option p:nth-child(2) {
    font-size: 1.6rem;
  }
  .bank-transactions-details .option p:first-child {
    color: var(--text-gray);
    font-size: 1.4rem;
    font-weight: 400;
  }
  .bank-transactions-details ul li{
    font-size: 1.6rem;
  }
  
  .bank-transactions-details .header-section{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .bank-transactions-details .header-section div:first-child p{
    font-size: 1.4rem;
  }
  .bank-transactions-details .badge-container-status p{
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 1.6rem;
  }