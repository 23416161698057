.ticket-detail-main {
    height: 100%;
    display: grid;
    grid-template-rows: auto max-content;
    overflow: auto;
}
.ticket-detail-main  .ticket-body-container{
    overflow-y: auto;
}
.ticket-body-container{
    padding: 2rem; 
}
.ticket-detail-main .body-section h2{
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    margin-top: 2rem;
}
.ticket-detail-main .header-section {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 1.25rem;
}

.ticket-detail-main .header-section h2 {
    font-size: 18px;
    font-weight: 400;
}

.ticket-detail-main .header-section h2:first-child {
    font-weight: 600;
}

.ticket-detail-main .header-section p {
    color: var(--text-gray);
    font-size: 14px;
    margin-top: 3px;
}
.ticket-detail-main  .ticket-footer-container {
    background-color: var(--bg-dark-color);
    padding: 0.7rem 0;
    height: 60px;
}

.ticket-body-container h3{
    margin-top: 2rem;
    padding-left: 1.25rem;
}
.option-ticket-tx-hash p{
    overflow-wrap: break-word;
}
.ticket-body-container h2{
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 2rem;
}
.tx-id-w-100{
    width: 100%;
    margin-top: 2rem;
}
.ticket-detail-main .button-tx-hash{
    width: 157px;
    height: 36px;
}